import { useState } from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useQueryClient } from "react-query";
import { UserType } from "@potato/components";

export const useDeleteBasket = () => {
  const [deleteing, setDeleteing] = useState<boolean>(false)
  const api = useApi()
  const queryClient = useQueryClient()

  const deleteBasket = async (data: { id: string, user: UserType }) => {
    setDeleteing(true)
    await queryClient.invalidateQueries(data.id, { refetchActive:false })
    await api.delete({
      url: api_routes.actions.delete_basket,
      data: { id: data.id }
    })()
    if (data.user.slug) { queryClient.invalidateQueries(data.user.slug) }
    queryClient.invalidateQueries('me')
    setDeleteing(false)
  }

  return { deleteBasket, deleteing };
}