import { ButtonRadioGroup, ButtonVariant, HoldingsList, BasketItemDetail, useToggle, Typography, TradesHistoryTable } from "@potato/components";
import WatchHoldingsUpdates from '../../../hoc/watch-holdings-updates'
import clsx from "clsx";

export interface HoldingsTradesSectionProps {
  item: BasketItemDetail,
  showEditHoldings: () => void
};

const HoldingsTradesSection = ({
  item,
  showEditHoldings
}: HoldingsTradesSectionProps) => {
  const editable = item.is_mine
  const { selected: holdingsView, setSelected: setHoldingsView } = useToggle(false)

  return (
    (
      <>
      <div className="flex items-center space-x-1 mt-12">
        <div className="flex-1">
          <Typography variant="h3" element="h2" className="">Long Recommendations</Typography>
        </div>
      </div>
        <HoldingsList clickable={true} itemWrapper={WatchHoldingsUpdates as any} items={item.holdings} className="mt-4" />

        <div className="flex items-center space-x-1 mt-12">
        <div className="flex-1">
          <Typography variant="h3" element="h2" className="">Short Recommendations</Typography>
        </div>
      </div>
        <HoldingsList hideProperties={["allocation"]} clickable={true} itemWrapper={WatchHoldingsUpdates as any} items={item.holdings_adverse} className="mt-4" />
        </>
  ));
}

export default HoldingsTradesSection