import { getRandomInt } from "@potato/components";
import { PriceUpdate } from "../types";


export const priceUpdateMocker = (ids: string[]) => (): PriceUpdate => {
  const id = ids[Math.floor(Math.random() * ids.length)];
  const price = getRandomInt(0, 6000)
  const price_change_range = price * 0.5
  const price_7d = price - getRandomInt(price - price_change_range, price + price_change_range)
  const price_1y = price - getRandomInt(price - price_change_range, price + price_change_range)
  const price_24h = price - getRandomInt(price - price_change_range, price + price_change_range)
  const movement_7d = (price_7d / price) * 100
  const movement_24h = (price_24h / price) * 100
  const movement_1y = (price_1y / price) * 100

  const price_time_24h = new Date();
  price_time_24h.setFullYear(price_time_24h.getDay() - 1);
  const price_time_1y = new Date();
  price_time_1y.setFullYear(price_time_1y.getFullYear() - 1);
  const price_time_7d = new Date();
  price_time_7d.setFullYear(price_time_7d.getDay() - 7);
  return {
    id,
    price,
    movement_7d,
    movement_24h,
    movement_1y,
    price_1y,
    price_24h,
    price_7d,
    price_time_7d: price_time_7d.getMilliseconds(),
    price_time_1y: price_time_1y.getMilliseconds(),
    price_time_24h: price_time_24h.getMilliseconds(),
    price_time: Date.now()
  }
}

export const manyPriceUpdatesMocker = (ids: string[]) => (): PriceUpdate[] => {
  const length = ids.length === 1 ? 1 : Math.floor(Math.random() * ids.length)
  const priceUpdates: PriceUpdate[] = []
  for (let i = 0; i < length; i++) {
    const update = priceUpdateMocker(ids)()
    priceUpdates.push(update)
  }
  return priceUpdates
}