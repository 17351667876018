import React from "react";
import { NewsItemType, NewsList, Typography } from "@potato/components";
import { NewsListProps } from '@potato/components/lib/components/NewsList/NewsList'

export interface NewsProps extends Omit<NewsListProps, 'items'> {
  news?: NewsItemType[] | null
  title?:string
};

const News = ({
  news,
  title = "In the news",
  ...props
}: NewsProps) => {

  return (
    <>
      {news && !!news.length && <>
        <Typography variant="h3" className="mt-24 mb-4">
         {title}
        </Typography>
        <NewsList {...props} items={news} />
      </>}
    </>
  );
};

export default News;