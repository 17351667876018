import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ButtonVariant,
  Container,
  GlobalUISizesEnum,
  PotatoPlus,
  PricingCard,
  Typography
} from "@potato/components";
import { useQuery } from "react-query";
import PageMeta from "../../components/page-meta/PageMeta";
import { api_routes } from "../../_api/api-routes";
import { PricingPageResponse } from "../../_api/pages/pricing";
import useApi from "../../_contexts/api/useApi";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import { useHistory, useLocation } from "react-router-dom";

export interface PricingProps {}

const Pricing = ({}: PricingProps) => {
  const api = useApi();
  const { user } = usePotatoUser();
  const location = useLocation();
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  const getPricing = api.get({ url: api_routes.pages.pricing });
  const _data = useQuery("pricing", getPricing, { enabled: !!user });
  const data = _data.data as PricingPageResponse | undefined;


  const upgradeOnClick = () => {
    if (!isAuthenticated) {
      localStorage.setItem("returnTo", location.pathname);
      return history.replace('/login');
    }
  };
  return (
    <>
      <PageMeta
        data={data && data.meta_tags}
        defaultData={{ title: "Pricing | Potato" }}
      />
       <Container className="z-10 md:pt-16 relative">
      <div className="md:max-w-4xl mx-auto flex flex-col min-view-height-62-341">
        <Typography variant="h1" className="mt-12">
          Build better ETFs<br className="md:hidden block"/> with AI
        </Typography>
        <Typography
          className="mb-6 mt-4 text-secondary text-xl"
        >
          Upgrade to get access to more AI and help your
          ideas come to life.
        </Typography>
        <div className="md:max-w-4xl md:mt-8 mt-4 mx-auto md:flex items-stretch md:space-x-6 ">
          <PricingCard
            className="flex-1 mb-6 bg-white md:shadow-none shadow-card rounded-xl border border-divider md:border-none p-4 md:p-0"
            item={{
              title: "Free Plan",
              emoji: <div className="text-3xl">🧐</div>,
              description:
                "For the lurkers. Look around, discover baskets and generate upto 3 AI baskets",
              button:
                user && user.is_potato_plus ? (
                  <></>
                ) : (
                  <ButtonVariant.Cta
                    size={GlobalUISizesEnum.LARGE}
                    label="Your Current Plan"
                    disabled={true}
                  />
                ),
              features: [
                "Publish 3x AI generated baskets",
                "Unlimited human created baskets"
              ],
            }}
          />
          <div className="border-r border-divider flex-0"></div>
          <PricingCard
            className="flex-1 mb-6 bg-white md:shadow-none shadow-card rounded-xl border border-divider md:border-none p-4 md:p-0"
            item={{
              title: "Potato Plus",
              emoji:<PotatoPlus size={GlobalUISizesEnum.LARGE}/>,
              description:
                "For keen publishers. Try out more investment ideas with 30 AI baskets per month",
              price: "$49",
              button: (
                <ButtonVariant.Cta
                  classes={{
                    default: "text-white bg-purple whitespace-nowrap",
                    active: "text-white bg-purple",
                    disabled: "opacity-60 cursor-default",
                    hover: "hover:bg-purple-dark",
                  }}
                  size={GlobalUISizesEnum.LARGE}
                  onClick={!isAuthenticated ? upgradeOnClick : undefined}
                  externalLink={user ? true : false}
                  target={
                    !!user && !!user.is_potato_plus ? "_blank" : undefined
                  }
                  href={
                    isAuthenticated && !!data
                      ? user && user.is_potato_plus
                        ? user.billing_portal_link
                        : `${data.subscribe_link}`
                      : undefined
                  }
                  label={
                    !!(user && user.is_potato_plus)
                      ? "Manage subscription"
                      : "Upgrade plan"
                  }
                />
              ),
              features: [
                "Publish 30x AI generated baskets per month",
                "Unlimited human created baskets"
              ],
            }}
          />
        </div>
      </div>
      </Container>
    </>
  );
};

export default Pricing;
