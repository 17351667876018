import {
  Container,
  Typography,
  Breadcrumbs,
  TagList,
  BasketCardList,
  useTruncate,
  UserType,
  PageTop,
  PageMain,
  PageMainLeft,
  PageMainRight,
  Markdown
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import React from "react"
import { ValueOf } from '../../_types/value-of'
import EmptyPageState from '../../components/empty-page-state'
import {
  useQuery,
} from 'react-query'
import { useLocation } from "react-router-dom"
import {
  LineIcon,
  TagType, GlobalUISizesEnum, UserAvatarList
} from "@potato/components";
import { BreadcrumbsProps } from "@potato/components/lib/components/Breadcrumbs/Breadcrumbs"
import WatchBasketPriceUpdate from '../../hoc/watch-basket-updates'
import { TagPageResponse } from "../../_api/pages/tag";
import { api_routes } from "../../_api/api-routes"
import { useEmojiFavicon } from "../../_utils/use-emoji-favicon"
import PageMeta from "../../components/page-meta";
import { useWatch } from "../../_utils/use-watch"

const Tag: React.FC = () => {
  const { apiLoading, ...api } = useApi()

  const location = useLocation()
  const uriParam = encodeURI(location.pathname)

  const { watchFn } = useWatch()

  const getTag = api.get({ url: api_routes.pages.tag + uriParam, redirect404: true })
  const _tagResponse = useQuery<TagPageResponse>(location.pathname, getTag, {
    enabled: !apiLoading
  })
  const tag = (_tagResponse.data && _tagResponse.data)
  useEmojiFavicon(tag && tag.emoji)
  const [truncateRelatedTagsButton, truncatedRelatedTagsItems] = useTruncate((tag && tag.related_tags) || [], 8)
  const [truncateBasketsButton, truncatedBasketsItems] = useTruncate((tag && tag.baskets) || [], 12)
  const [truncateTopPublishersButton, truncatedTopPublishersItems] = useTruncate((tag && tag.top_publishers) || [], 6)

  const isLoading = _tagResponse.isLoading
  if (isLoading) {
    return <div />
  }

  if (!tag) {
    return <div />
  };

  (window as any).prerenderReady = true;

  const items: ValueOf<Pick<BreadcrumbsProps, 'items'>> = [{
    label: 'Browse'
  }, {
    prefix: <LineIcon icon="library" />,
    label: 'Categories',
    value: '/categories'
  }]
  if (tag.category_id) {
    items.push({
      label: tag.category_title,
      value: tag.category_slug
    })
  }

  const hasBaskets = !!tag.baskets && !!tag.baskets.length

  return (
    <Container>
      <PageMeta data={tag.meta_tags} defaultData={{ title: tag.title + ' | Tag' }} />

      <PageTop>
        <Breadcrumbs items={items} />
      </PageTop>
      <PageMain>
        <PageMainLeft>
          <Typography variant="h2" element="h1" className="mb-6">{tag.emoji} {tag.title} </Typography>
          {!!tag.description && (
            <Markdown>{tag.description}</Markdown>
          )}
          {hasBaskets && (
            <>
              <div className="flex space-x-8 mt-10 mb-6">
                <div className="flex-1">

                  <Typography  variant="h3">
                    Related Baskets
                  </Typography>
                </div>
              </div>
              <BasketCardList itemClassName="sm:w-1/2 w-full" onWatch={watchFn} itemWrapper={WatchBasketPriceUpdate} items={truncatedBasketsItems as any[]} />
              {truncateBasketsButton}
            </>
          )}
        </PageMainLeft>
        {hasBaskets && (
          <PageMainRight>
            {!!truncatedRelatedTagsItems && !!(truncatedRelatedTagsItems as TagType[]).length && (
              <>
                <Typography  variant="h3"  className="mb-6 lg:mt-0 mt-12">
                  Related Tags
                </Typography>

                <TagList center={false} size={GlobalUISizesEnum.SMALL} items={truncatedRelatedTagsItems as TagType[]} className="mt-5" />
                {truncateRelatedTagsButton}
              </>
            )}
            {!!tag.top_publishers && !!tag.top_publishers.length && <>
            <Typography variant="h3" className="mt-8 mb-4">
              Top Publishers
            </Typography>
            <UserAvatarList items={truncatedTopPublishersItems as UserType[]} />
            {truncateTopPublishersButton}
            </> }
          </PageMainRight>
        ) }
      </PageMain>
      { !hasBaskets && <EmptyPageState emoji={tag.emoji} picture={tag.picture} className="mt-8" /> }

    </Container>
  )

}

export default Tag