import { EditableComponent, useForm } from '@potato/components'
import React from 'react'
import { MarkRequired } from 'ts-essentials'
import RichTextInput, { RichTextInputProps } from './RichTextInput'
type Props = RichTextInputProps & MarkRequired<EditableComponent, 'editable'>
const FormContextRichTextInput = ({editable, ...props}:Props) => {
  const { data, onFieldChange, readonly, forceUpdate: basketId } = useForm()
  if (!data) {
    throw Error('Component must be wrapped in FormProvider')
  }
  const value = data[editable]
  if (value == undefined) {
    return <></>
  }
  return <RichTextInput {...props} initialValue={value} readOnly={readonly} basketId={basketId!} onChange={(value) => {
    onFieldChange(editable, value)
  }} />
}
export default FormContextRichTextInput