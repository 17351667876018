import React, { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import clsx from "clsx";
import renderRoutes from "./routes/RenderRoutes";
import {
  GlobalStyles,
  useAppClass,
  BreakpointProvider,
  NotifyProvider,
  ThemeProvider,
  useThemeContext,
} from "@potato/components";
import { createBrowserHistory } from "history";
import { default as app_routes, launch_routes } from "./routes/Routes";
import { apiConfig, auth0Config } from "./config";
import ErrorBoundary from "./hoc/error-boundary/ErrorBoundary";
import "./app.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ApiProvider } from "./_contexts/api/ApiContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { PriceUpdateStoreProvider } from "./_contexts/price-update-store/PriceUpdateStoreContext";
import { BasketUpdateStoreProvider } from "./_contexts/basket-patch-store/BasketPatchStoreContext";
import { PotatoUserProvider } from "./_contexts/potato-user-store/PotatoUserContext";
import MockInitialData from "./_contexts/price-update-store/MockInitialData";
import PageWrapper from "./hoc/page-wrapper";
import BasketEmbed from "./views/basket-embed";
import { Helmet } from "react-helmet";
import Launch from "./views/launch/Launch";
const { install } = require("ga-gtag");

const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 120000,
    },
  },
});
const AppBarColor = () => {
  const {
    colors: { bgColor },
  } = useThemeContext();
  return (
    <Helmet>
      <meta name="theme-color" content={bgColor} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    </Helmet>
  );
};
function App() {
  useEffect(() => {
    install("G-SYVV916E0M", { send_page_view: false });
  }, []);
  const { className } = useAppClass();
  const routes = app_routes;
  return (
    <ThemeProvider>
      <AppBarColor />
      <div className="form-styles">
        <BreakpointProvider>
          <ErrorBoundary>
            <div className={clsx("App bg-lifted-highlight", className)}>
              <Auth0Provider
                clientId={auth0Config.client_id}
                audience={auth0Config.audience}
                domain={auth0Config.domain}
                redirectUri={`${window.location.origin}/redirect/`}
                cacheLocation="localstorage"
              >
                <QueryClientProvider client={queryClient}>
                  <GlobalStyles>
                    <NotifyProvider>
                      <PriceUpdateStoreProvider>
                        <BasketUpdateStoreProvider>
                          <MockInitialData showButton={false} />
                          <Router history={history}>
                            <ApiProvider apiUrl={apiConfig.url!}>
                              <PotatoUserProvider>
                                <Switch>
                                  <Route
                                    path={"/embed/basket/:basket"}
                                    render={(props: any) => (
                                      <BasketEmbed {...props} />
                                    )}
                                  />
                                 
                                <PageWrapper>
                                    <>
                                    {renderRoutes(routes)}
                                    </>
                                  </PageWrapper>
                                </Switch>
                              </PotatoUserProvider>
                            </ApiProvider>
                          </Router>
                        </BasketUpdateStoreProvider>
                      </PriceUpdateStoreProvider>
                    </NotifyProvider>
                  </GlobalStyles>
                </QueryClientProvider>
              </Auth0Provider>
            </div>
          </ErrorBoundary>
        </BreakpointProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
