import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
const { gtag } = require("ga-gtag");

const Auth0Callback = () => {
  const history = useHistory()
  const { isLoading } = useAuth0()
  useEffect(() => {
    gtag('event', 'login', { 'method': 'Google' });
  }, [])
  useEffect(() => {
    if (!isLoading) {
      const returnTo = localStorage.getItem('returnTo')
      if (returnTo) {
        localStorage.removeItem('returnTo')
        history.replace(returnTo)
      } else {
        history.replace('/')
      }
    }
  }, [isLoading])
  return (
    <div></div>
  );
};

export default Auth0Callback;