import { useThemeContext } from "@potato/components";
import clsx from "clsx";
import React, { RefObject } from "react";
export interface SocialButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  label: string;
  connection?: 'google' | 'apple'
};

const SocialButton = React.forwardRef(({
  label,
  connection = 'google',
  ...props
}: SocialButtonProps, ref) => {
  const { isDark } = useThemeContext()
  const iconLeft = {
    google: <svg viewBox="0 0 24 24" width="24" height="24" style={{ margin: 'auto' }} xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
        <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
        <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
        <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
        <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
      </g>
    </svg>, apple: <svg style={{ margin: 'auto' }} fill={isDark ? "#fff" : "#000000"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      <path d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z" />
    </svg>,
  }
  return (
    <button
      ref={ref as RefObject<HTMLButtonElement>}
      className={
        clsx(
          `mt-6
          cursor-pointer 
          py-4 px-4
          m-auto
          block 
          text-base
          font-normal
          rounded-full 
          focus:outline-none`,
          'w-full text-left',
          'hover:bg-lifted-highlight outline-none border border-black'
        )
      }
      {...props}
    >
      <div className={`flex space-x-3 items-center`}>

        <div className={clsx("flex-initial w-8")}>
          {iconLeft[connection]}
        </div>
        <div className="flex-1">
          {label}
        </div>
      </div>
    </button>
  );
});

export default SocialButton;