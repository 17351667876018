import React, { useCallback, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useEffect } from "react";
import { debounce } from 'lodash'
import usePriceUpdateStore from "../../_contexts/price-update-store/usePriceUpdateStore";
import { WSEventEnum, WSStreamResponseMessage, BasketPriceUpdateMessage, TickerPriceUpdateMessage, WSActionEnum } from "../../_api/websocket";
const xid = require('xid-js');

const Websocket = () => {
  console.log('WEBSOCKET!')
  const socketUrl = localStorage.getItem('potato_socket_url') || 'wss://ws.potato.trade/ws'
  const [clientId, setClientId] = useState<string|null>(null)
  
  const {
    sendJsonMessage,
    lastMessage,
    readyState,
    getWebSocket
  } = useWebSocket(socketUrl);
  const [count, setcount] = useState(0);
  const { basketIds, tickers, addBasketPriceUpdates, addTickerPriceUpdates, clearPriceUpdateStore } = usePriceUpdateStore()

  
  const sendPing = useCallback(() => {
    console.log('Send ping!', readyState === ReadyState.OPEN, clientId)
    if (readyState === ReadyState.OPEN && clientId) {
      sendJsonMessage({ "action": WSActionEnum.CLIENT_PING, "client_id": clientId })
    }
  }, [readyState, sendJsonMessage, clientId])

  useEffect(() => {
    if(count) {
      sendPing()
    }
 }, [count])
  const subscribePriceUpdates = useCallback(
    debounce((_basketIds: string[], _tickers: string[], _clientId:string) => {
      sendJsonMessage({
        "action": WSActionEnum.CLIENT_CONNECT,
        "client_id": _clientId
      })
    
    if(_basketIds.length) {
      sendJsonMessage({
        "action": WSActionEnum.SUBSCRIBE_PORTFOLIOS,
        "client_id":_clientId,
        "params": _basketIds
      })
    }
    if(_tickers.length) {
      sendJsonMessage({
        "action": WSActionEnum.SUBSCRIBE_TICKERS,
        "client_id":_clientId,
        "params": _tickers
      })
    }
    }, 1000),
    []
  );
  type PriceAcc = {
    basketPriceUpdates: BasketPriceUpdateMessage[],
    tickerPriceUpdates: TickerPriceUpdateMessage[]
  }
  const processMessages = (messages: WSStreamResponseMessage[]) => {
    const { basketPriceUpdates, tickerPriceUpdates } = messages.reduce((acc: PriceAcc, message) => {
      switch (message.event) {
        case WSEventEnum.PORTFOLIO_PRICE_UPDATE:
          acc.basketPriceUpdates.push(message);
          break;
        case WSEventEnum.TICKER_PRICE_UPDATE:
          acc.tickerPriceUpdates.push(message)
          break;
        case WSEventEnum.PONG:
          console.log(JSON.stringify(message, null, 2))
          break;
      }
      return acc
    }, {
      basketPriceUpdates: [],
      tickerPriceUpdates: []
    })
    addBasketPriceUpdates(basketPriceUpdates)
    addTickerPriceUpdates(tickerPriceUpdates)
  }

  useEffect(() => {
    if (clientId && readyState === ReadyState.OPEN && (tickers.length || basketIds.length)) {
      subscribePriceUpdates(basketIds, tickers, clientId)
    }
  }, [basketIds, tickers, readyState, clientId])


  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      if(typeof lastMessage.data === "string")
      try {
      const messages = JSON.parse(lastMessage.data)
      console.log(messages)
      processMessages(Array.isArray(messages) ? messages : [messages])
      } catch(err) {
        console.error(err.message)
      }
    }
  }, [lastMessage])

  useEffect(() => {
    if(!clientId) {
      setClientId(xid.next())
    }
    const interval = setInterval(() => {
      setcount(count => count + 1);
    }, 60000);
    return () => {
      clearInterval(interval);
      clearPriceUpdateStore();
      const websocket = getWebSocket()
      if (websocket && clientId) {
        sendJsonMessage({
          action: WSActionEnum.CLIENT_DISCONNECT,
          client_id: clientId
        })
        websocket.close()
        setClientId(null)
      }
    }
  }, [])


  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }
  // return <Container className="py-3 text-center">
  //   <Typography>Websocket is currently: {connectionStatus[readyState]}</Typography>
  //   <Typography>Ping counter: {count}</Typography>
  // </Container>
  return <></>
};

export default Websocket;

