import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";

export const usePaymentLink = () => {
  const api = useApi()

  const getPaymentLink = (data: { fantasy_id: string}) => async() => {
    const result = await api.post({
      url: api_routes.actions.payment_link,
      data
    })()
    return result
  }

  return { getPaymentLink }

}