import React, {
  createContext, useCallback, useEffect, useState
} from 'react';
import type { FC, ReactNode } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
export type PotatoApiArgs = { url: string, waitForAuth?: boolean, redirect404?:boolean }
export interface ApiContextValue {
  auth0ApiReady: boolean
  apiLoading: boolean
  error: AxiosError | null
  clearError: () => void
  get: (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => () => Promise<any>
  post: (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => () => Promise<any>
  patch: (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => () => Promise<any>
  delete: (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => () => Promise<any>
}
// what passed to provider
interface ApiProviderProps {
  apiUrl: string,
  children?: ReactNode
}
// an initial value for the consumer 
const ApiContext = createContext<ApiContextValue>({
  auth0ApiReady: false,
  apiLoading: true,
  error: null,
  clearError: () => { },
  get: () => () => new Promise((res) => res([])),
  post: () => () => new Promise((res) => res([])),
  patch: () => () => new Promise((res) => res([])),
  delete: () => () => new Promise((res) => res([])),
});

export const ApiProvider: FC<ApiProviderProps> = ({
  children,
  apiUrl
}) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const history = useHistory()
  const [apiLoading, setApiLoading] = useState<boolean>(true)
  const [token, setToken] = useState<string | null>(null)
  const [error, setError] = useState<AxiosError | null>(null)

  const initToken = useCallback(async () => {
    if (isAuthenticated && !isLoading) {
      const _token = await getAccessTokenSilently()
      if (_token) {
        setToken(_token)
      }
    } else {
      setToken(null)
    }
    setApiLoading(isLoading)
  }, [isAuthenticated, isLoading])
  useEffect(() => {
    initToken()
  }, [isLoading])

  const authorization = isAuthenticated && !isLoading && token ? {
    authorization: `Bearer ${token}`
  } : {}

  const errorHandler = (error: any) => {
    if ((error as AxiosError).response && error.response) {
      setError(error)
      return error.response
    }
    throw error
  }

  const callApi = async (method: 'GET' | 'POST' | 'PATCH' | 'DELETE', { waitForAuth = true, headers, redirect404 = false, ...args }: Partial<AxiosRequestConfig> & PotatoApiArgs) => {
    if (apiLoading && waitForAuth) {
      return
    }
    const options: AxiosRequestConfig = {
      method: method,
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        ...headers,
        ...(isAuthenticated && !isLoading && token ? authorization : {})
      },
      ...args
    }
    try {
      const data = await axios(options)
      if(data && data.data && data.data.status === 'failed') {
        if(data.data.status_type === 'login_required') {
          throw({ response: { status: 401 }})
        }
      }
      if (data) {
        return data.data
      }
    } catch (error: any) {
      if(redirect404) {
       const err = error as AxiosError
        if(err.response && err.response.status === 404) {
          history.replace('/404')
        }
      }
      errorHandler(error)
    }
  }

  const post = (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => async () => {
    return await callApi('POST', args)
  }
  const _delete = (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => async () => {
    return await callApi('DELETE', args)
  }
  const patch = (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => async () => {
    return await callApi('PATCH', args)
  }
  const get = (args: Partial<AxiosRequestConfig> & PotatoApiArgs) => async () => {
    return await callApi('GET', args)
  }

  return (
    <ApiContext.Provider
      value={{
        error,
        clearError: () => { setError(null) }, 
        apiLoading,
        auth0ApiReady: !!(isAuthenticated && !isLoading && token),
        get,
        post,
        patch,
        delete: _delete
      }}
    >
      {children}
    </ApiContext.Provider >
  );
};

export const ApiConsumer = ApiContext.Consumer;
export default ApiContext;
