import React, { useCallback, useEffect, useRef } from "react";
import { FantasyDetailItemType, Loading, useConfetti } from "@potato/components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PageMeta from "../../components/page-meta";
import { api_routes } from "../../_api/api-routes";
import useApi from "../../_contexts/api/useApi";
import FantasyLayout from "./FantasyLayout";
import {
  useQuery, useQueryClient,
} from 'react-query'
import { useEmojiFavicon } from "../../_utils/use-emoji-favicon";
import { FantasyPageResponse } from "../../_api/pages/fantasy";
import { PageModeProvider } from "../../_contexts/page-mode/PageModeContext";
import { useUpdateHoldings } from "../../_utils/use-update-holdings";
import { UpdateHoldingsArgs } from "../../components/edit-holdings/EditHoldings";
import { ShakeProvider } from "../../_contexts/shaker/ShakeContext";
import { useQueryParams } from "../../_utils/use-search-params";
const { gtag } = require("ga-gtag");

export interface FantasyProps {
};

const Fantasy = ({

}: FantasyProps) => {
  let queryParams = useQueryParams();
  const { apiLoading, ...api } = useApi()
  const location = useLocation()
  const history = useHistory()
  const { updateHoldings } = useUpdateHoldings()
  const queryClient = useQueryClient()
  const { element, fire } = useConfetti()
  const params = useParams<{ fantasy: string }>()
  const roundParam = queryParams.get("round") || undefined
  const newbieParam = queryParams.get("newbie") || undefined
  let slug = roundParam ? location.pathname.split("?")[0] : location.pathname
  const uriParam = encodeURI(slug)

  const getFantasy = (round?: string) => api.get({ url: api_routes.pages.fantasy + uriParam + (round ? `&round=${round}` : ''), redirect404: true })
  const fantasyParam = params.fantasy ? params.fantasy.split('-') : ''
  const fantasyParamId = fantasyParam[fantasyParam.length - 1]
  const _fantasyResponse = useQuery(`${fantasyParamId}`, getFantasy(roundParam), {
    enabled: !apiLoading,
  })
  let fantasy = _fantasyResponse.data as FantasyPageResponse
  const fantasy_round_number = roundParam || !!fantasy && fantasy.selected_round.round_number
  const getRound = (round?: string) => api.get({ url: api_routes.pages.fantasy + uriParam + (round ? `&round=${round}` : ''), redirect404: true })
  const _roundResponse = useQuery(`${fantasyParamId}${fantasy_round_number}`, getRound(roundParam), {
    enabled: !!fantasy_round_number,
    refetchInterval: newbieParam ? 1000 : 15000
  })
  let round = _roundResponse.data as FantasyPageResponse

  useEffect(() => {
    return () => {
      // updates are now seen, refetch when returning to fantasy index
      queryClient.invalidateQueries(`fantasy_index`)
    }
  }, [])

  let timeout: any = useRef(null)
  useEffect(() => {
    if (fantasy && newbieParam) {
      fire(['#D170FF', '#FFF1D6'])
    }
  }, [fantasy, newbieParam])

  useEffect(() => {
    if(fantasy && fantasy.is_member && newbieParam) {
      gtag('event', 'purchased', { 'method': 'Google' });
       if (!timeout.current) {
        queryParams.delete("newbie")
        const queryString = queryParams.toString()
        timeout.current = setTimeout(() => { history.replace(`${slug}${queryString ? '?' + queryString : ''}`) }, 2000)
      }
    }
  }, [fantasy])

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  const _updateHoldings = useCallback(async (args: UpdateHoldingsArgs) => {
    await updateHoldings(args);
    await queryClient.invalidateQueries(`${fantasyParamId}${roundParam ? roundParam : ''}`)
  }, [queryClient, roundParam, updateHoldings, fantasyParamId])

  useEmojiFavicon(fantasy && fantasy.emoji)

  const item: FantasyDetailItemType = {
    ...(fantasy && removeEmpty(fantasy) || {}),
    ...round,
    ...(JSON.parse(localStorage.getItem('fantasy') as string | undefined || "{}"))
  }

  if (_fantasyResponse.isLoading || !fantasy) {
    return <Loading className="mt-32" />
  }

  return (
    <PageModeProvider initialMode="main">
      <ShakeProvider>
        {newbieParam && element}
        <PageMeta data={fantasy ? fantasy.meta_tags : {}} />
        {item && <FantasyLayout inviteRequired={_fantasyResponse.data.status_type === "invite_link_required"} item={item} updateHoldings={_updateHoldings} roundLoading={_roundResponse.isLoading}/>}
      </ShakeProvider>
    </PageModeProvider>
  );
};

export default Fantasy;

const removeEmpty = <T extends { [x: string]: any }>(obj: T): T => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {} as T);
}