import {
  LineIcon,
  BasketItemDetail,
  ButtonVariant as Button,
  Watches,
  ShareButton,
  useCurrentBreakpointName,
  GlobalUISizesEnum,
} from "@potato/components";
import clsx from "clsx";
import React from "react";
import { useWatch } from "../../../_utils/use-watch";
// import usePublish from "./use-publish";
import usePageMode from "../../../_contexts/page-mode/page-mode-hook";
import useViewHistory from "./use-view-history";

const Actions: React.FC<{
  item: BasketItemDetail;
  className?: string;
  showEditHoldings: () => void;
}> = ({ item, className, showEditHoldings }) => {
  const { onWatchToggleClick } = useWatch();
  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);
  const { menuItem: historyMenuItem } = useViewHistory();
  const { setPageMode } = usePageMode();
  // const {
  //   dialog: publishDialog,
  //   buttonProps: publishButtonProps,
  //   confettiCanvasElement,
  // } = usePublish({ item });

  if (!item.holdings.length) {
    return <></>;
  }
  return (
    <>
      <div
        className={clsx(
          "flex md:flex-nowrap flex-wrap items-center",
          className
        )}
      >
        {!!item.is_published && (
          <>
            <ShareButton
              className={"mr-1 mb-1 md:block hidden"}
              size={
                GlobalUISizesEnum.MEDIUM 
              }
            />
            <Watches
              className="mr-1 mb-1 md:block hidden"
              size={
                GlobalUISizesEnum.MEDIUM 
              }
              onWatch={onWatchToggleClick(item.id, !!item.is_watched)}
              variant="outline"
              watched={item.is_watched}
              count={item.watch_count}
            />
        
          </>
        )}
       
          <Button.Outline
            className=" mr-1 mb-1"
            size={GlobalUISizesEnum.MEDIUM }
            onClick={historyMenuItem.onClick}
            label={historyMenuItem.label}
            iconLeft={
              <LineIcon icon={historyMenuItem.icon!} className="block" />
            }
          />
      
        {/* {!!item.is_published && (
          <Button.Outline
            className="block md:hidden mr-1 mb-1"
            size={GlobalUISizesEnum.SMALL}
            onClick={() => {
              setPageMode("chat");
            }}
            label={"Chat"}
            iconLeft={<LineIcon icon="bubble" className="block" />}
          />
        )} */}
        {item.is_mine && !item.is_ai_generated && (
          <>
            {/* {!item.is_published && (
              <>
                {publishDialog}
                {confettiCanvasElement}
                <Button.Outline
                  {...publishButtonProps}
                  className="mr-1 mb-1"
                  size={
                    GlobalUISizesEnum.MEDIUM 
                  }
                />
              </>
            )} */}
              <Button.Outline
                size={
                  GlobalUISizesEnum.MEDIUM 
                }
                className="mr-1 mb-1"
                label="Edit Holdings"
                onClick={showEditHoldings}
              />
          </>
        )}
      </div>
    </>
  );
};

export default Actions;
