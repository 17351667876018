import React, { useEffect } from "react";

export const useEmojiFavicon = (emoji?:string) => {

  useEffect(() => {
    var iconElem = document.querySelector(`head > link[rel='icon']`);
    var favicon = iconElem && iconElem.getAttribute('href')
    if(emoji) {
        iconElem && iconElem.setAttribute('href', 'data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>'+emoji+'</text></svg>'); 
    }
    return () => {
      iconElem && favicon && iconElem.setAttribute('href', favicon); 
    }
  }, [emoji])

}