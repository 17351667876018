import React from 'react'
import { LineIcon, Typography } from '@potato/components'
import clsx from 'clsx'

export const Disclaimer = ({className}: {className?: string}) => {
    return <div className={clsx(className, 'bg-yellow bg-opacity-10 border border-yellow rounded-md p-3 px-4 md:flex md:items-center')}>
        <LineIcon icon="bubble-alert" className="inline text-base md:text-xl mr-2 md:mr-4 md:block text-yellow"/>
        <Typography className='inline md:block'>
        The information on this page is AI generated and is for general information only. This information is not financial advice and is not intended to be interpreted as a recommendation to invest in, or purchase any stock, security, cryptocurrency, or other financial product. The terminology 'Long Recommendations' and 'Short Recommendations' are not recommendations to buy or sell stocks, these are AI generated stocks that have been filtered from the thesis. The information on this page is not intended to be a substitute for professional investment advice.
        </Typography>

    </div>
}