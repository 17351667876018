import React from "react";
import {
  Container,
  EmojiTitle,
  useCurrentBreakpointName,
  PageMain,
  PageMainLeft,
  Typography,
  PageMainRight,
  useToggle,
  ButtonRadioGroup,
  ButtonVariant,
  LineIcon,
  GlobalUISizesEnum,
  FantasyDetailItemType,
  TradesActivityTable,
  RankingsTable,
  StackOptionsEnum,
  FilterTypeEnum,
  DialogVariant,
  Breakpoint,
  Loading,
  Avatar,
  MovementChip,
} from "@potato/components";
import Meta, { MetaSummary } from "./Meta";
import Actions from "./actions/Actions";
import PastWinners from "./PastWinners";
import Comments from "../../components/comments";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import PageMode from "../basket/page-mode/PageMode";
import { NoFooter } from "../../components/no-footer/NoFooter";
import EditHoldings, {
  UpdateHoldingsArgs,
} from "../../components/edit-holdings/EditHoldings";
import "./styles.scss";

import ChartSection from "./chart/ChartSection";
import DropdownMenu from "./actions/DropdownMenu";
import {
  is_member,
  MemberGuard,
  NonMemberGuard,
  PublicOrMemberGuard,
} from "./PaymentGuard";
import { EnterCta, EnterCtaButton } from "./CTA";
import { NextStepCta } from "./NextStepCTA";
import { FixedBottomBar } from "./FixedBottomBar";
import { CommentsDialog } from "./Comments/CommentsDialog.mobile";
import { DateTime } from "luxon";

export interface FantasyLayoutProps {
  item: FantasyDetailItemType
  roundLoading: boolean
  inviteRequired: boolean
  updateHoldings: (args: UpdateHoldingsArgs) => void;
}

const FantasyLayout = ({
  item,
  roundLoading,
  inviteRequired,
  updateHoldings,
}: FantasyLayoutProps) => {
  const { selected: rankingsView, setSelected } = useToggle(true);
  const { setPageMode } = usePageMode();
  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);
  const rankings = item.rankings
    ? item.rankings.sort((a, b) => a.ranking - b.ranking)
    : [];
  const myRanking = item.rankings && item.rankings.find((r) => r.is_mine);
  const enable_filter_types = getFilterOptions(item.stack);
  const MainWrapper = isLarge ? React.Fragment : NoFooter;
  const ranked_users = rankings.filter((r) => !r.is_unranked);
  const un_ranked_users = rankings.filter((r) => r.is_unranked);
  const now = DateTime.now()
  const start = DateTime.fromISO(item.selected_round.start_at)
  const end = DateTime.fromISO(item.selected_round.end_at).plus({ seconds: 30 })
  const is_current = now > start && now <= end || now < start 

  const inviteRequiredMessage = <Typography variant="p">This is a private league which requires an invite link to access</Typography> 
  return (
    <>
      <PageMode pageModes={["edit"]}>
        <Container>
          <NoFooter>
            <EditHoldings
              fixed_filters={
                item.market_cap && item.market_cap !== "Any"
                  ? [{ type: "market_cap", value: item.market_cap }]
                  : undefined
              }
              enable_filter_types={enable_filter_types}
              onClose={() => setPageMode("main")}
              id={item.basket_id}
              holdings={
                myRanking && myRanking.holdings ? myRanking.holdings : []
              }
              title={item.title}
              emoji={item.emoji}
              updateHoldings={updateHoldings}
            />
          </NoFooter>
        </Container>
      </PageMode>

      {/* Only for mobile */}
      <PageMode pageModes={["details"]}>
        <Container>
          <NoFooter>
            <DialogVariant.Boxed open={true} closeButton={false} spaced={false}>
              <div className={"px-4 pt-4 pb-2"}>
                <div className="flex flex-1 items-center justify-end mb-2">
                  <div className="flex-1">
                    <EmojiTitle
                      variant={"h2"}
                      title={item.title}
                      emoji={item.emoji}
                    />
                  </div>
                  {/* <div className="flex-0">
                    <NonMemberGuard item={item}>
                      <div className="ml-4">
                        <EnterCtaButton item={item} size={GlobalUISizesEnum.SMALL} />
                      </div>
                    </NonMemberGuard>
                  </div> */}
                  <div className="flex-0">
                    <ButtonVariant.Icon
                      onClick={() => {
                        setPageMode("main");
                      }}
                      label={<LineIcon icon="cross" className="text-lg" />}
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full">
                  <Meta item={item} className=" md:mb-6 mt-4 mb-8" />
                </div>
              </div>
            </DialogVariant.Boxed>
          </NoFooter>
        </Container>
      </PageMode>

      {/* Only for mobile */}
      <PageMode pageModes={["chat"]}>
        <Container>
          <NoFooter>
            <DialogVariant.Boxed open={true} closeButton={false} spaced={false}>
              <CommentsDialog item={item} />
            </DialogVariant.Boxed>
          </NoFooter>
        </Container>
      </PageMode>

      <PageMode pageModes={["main"]}>
        <MainWrapper>
          <Container>
            <PageMain space="" className="justify-center">
              <PageMainLeft size="lg:w-3/5">
                <div className="flex items-center">
                  <div className="flex-1">
                    <EmojiTitle
                      variant={"h1"}
                      element="h1"
                      titleProps={{ className: "md:leading-10 py-1" }}
                      emoji={item.emoji}
                      title={item.title}
                    />
                  </div>

                  <div className="flex-0">
                    <MemberGuard item={item}>
                      <DropdownMenu item={item} className="-mr-2" />
                    </MemberGuard>
                  </div>
                </div>
                <Breakpoint large up>
                  {is_current && <NextStepCta item={item} />}
                  <NonMemberGuard item={item}>
                   {inviteRequired ? inviteRequiredMessage : <EnterCta item={item} />}
                  </NonMemberGuard>
                </Breakpoint>
                <Breakpoint large up>
                  <Meta item={item} className=" md:mb-6 mt-4 mb-8" />
                </Breakpoint>
                <Breakpoint medium down>
                  {!is_member(item) && !item.is_public ? (
                    <Meta item={item} className=" md:mb-6 mt-4 mb-8" />
                  ) : (
                    <MetaSummary
                      item={item}
                      className="my-4"
                      roundLoading={roundLoading}
                    />
                  )}
                </Breakpoint>

      
                {roundLoading ? (
                  <div className="w-full relative">
                    <Loading className="mt-32" />
                  </div>
                ) : (
                  <>
                  <Breakpoint medium down>
                  {is_current && <NextStepCta item={item} />}
                  <NonMemberGuard item={item}>
                  {inviteRequired ? inviteRequiredMessage : <EnterCta item={item} />}
                  </NonMemberGuard>
                </Breakpoint>
                   {/* <Loading className="mt-32" /> */}
                  <PublicOrMemberGuard item={item}>
                    {item && item.selected_round && (
                      <ChartSection
                        key={`${item.id}-chart-section`}
                        item={item}
                      />
                    )}
                    <div className="flex justify-between items-center mt-6">
                      <ButtonRadioGroup
                        options={[
                          { label: "Rankings", value: "rankings" },
                          { label: "Trades", value: "trades" },
                        ]}
                        value={rankingsView ? "rankings" : "trades"}
                        onChange={(val) => {
                          setSelected(val === "rankings");
                        }}
                      />
                     {is_current && <Actions className="" item={item} />}
                    </div>
                    <div className="mt-4 md:mt-10">
                      {rankingsView ? (
                        <>
                          <RankingsTable
                            prizes={item.selected_round.prizes}
                            editHoldingsClick={() => setPageMode("edit")}
                            items={ranked_users}
                          />
                          {!!un_ranked_users.length && (
                            <>
                              <Typography className="mt-8 mb-2 font-semibold text-xl">Unranked</Typography>
                              <RankingsTable
                                prizes={item.selected_round.prizes}
                                editHoldingsClick={() => setPageMode("edit")}
                                items={un_ranked_users}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <TradesActivityTable items={item.trades || []} />
                      )}
                    </div>

                    <FixedBottomBar item={item} />
                  </PublicOrMemberGuard>
                </>
                )}
              </PageMainLeft>
              {}
              <PublicOrMemberGuard item={item}>
                <PageMainRight size="lg:w-2/5" className="md:pl-16 2xl:pl-28 ">
                  <div className="md:flex flex-col hidden h-full">
                    <Comments
                      item={item}
                      type="fantasy"
                      readonly={!is_member(item)}
                    />
                  </div>
                </PageMainRight>
              </PublicOrMemberGuard>
            </PageMain>
          </Container>
        </MainWrapper>
      </PageMode>
    </>
  );
};

const getFilterOptions = (stack: StackOptionsEnum) => {
  switch (stack) {
    case StackOptionsEnum.ANY:
      return [
        FilterTypeEnum.all,
        FilterTypeEnum.crypto,
        FilterTypeEnum.etf,
        FilterTypeEnum.stocks,
      ];
    case StackOptionsEnum.ETFS:
      return [FilterTypeEnum.etf];
    case StackOptionsEnum.STOCKS:
      return [FilterTypeEnum.stocks];
    case StackOptionsEnum.CRYPTO:
      return [FilterTypeEnum.crypto];
  }
};

export default FantasyLayout;
