import {
  BasketItemDetail,
  DropdownButton,
  GlobalUISizesEnum,
  LineIcon,
} from "@potato/components"
import React from "react"
import useDelete from "../use-delete"
import { useReport } from "../use-report"
import useUnpublish from "../use-unpublish"
import useViewHistory from "../use-view-history"

const DropdownMenu: React.FC<{
  item: BasketItemDetail,
  className?:string
}> = ({ item, className }) => {
  const { dialog: delete_dialog, menuItem: deleteMenuItem } = useDelete({ item })
  const { report_menu_item, showReportItems, setShowReportItems, report_items } = useReport({id: item.id })
  const { menuItem: historyMenuItem } = useViewHistory();

  // const { dialog: unpublish_dialog, menuItem: unpublishMenuItem } = useUnpublish({ item })

  const is_published = item.is_published || item.is_queued_for_art
  const copyMenuItem = {
    label: "Copy",
    value: "/basket/copy/" + item.id,
    icon:"copy"
  }
  const is_mine_menu = !!item.is_mine ? [deleteMenuItem] :[]
  const menu = !!is_published ? [copyMenuItem,historyMenuItem,report_menu_item] : []
  const menu_items = [...is_mine_menu, ...menu]
  return <div className={className}>
    {delete_dialog}
    {/* {unpublish_dialog} */}
    <DropdownButton onChange={val => { if(!val) { setShowReportItems(false) }}} size={GlobalUISizesEnum.LARGE} items={showReportItems ? report_items : menu_items} space={'px-4'} position={'right'} />
  </div>

}

export default DropdownMenu