import {
  Container,
  Typography,
  Breadcrumbs,
  TagList,
  BasketCardList,
  useTruncate,
  UserType,
  PageTop,
  PageMain,
  PageMainLeft,
  PageMainRight,
  Markdown,
} from "@potato/components";
import useApi from "../../_contexts/api/useApi";
import React from "react";
import { ValueOf } from "../../_types/value-of";
import EmptyPageState from "../../components/empty-page-state";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { GlobalUISizesEnum } from "@potato/components";
import WatchBasketPriceUpdate from "../../hoc/watch-basket-updates";
import { api_routes } from "../../_api/api-routes";
import PageMeta from "../../components/page-meta";
import { useWatch } from "../../_utils/use-watch";
import { TopicPageResponse } from "../../_api/pages/topic";

const Topic: React.FC = () => {
  const { apiLoading, ...api } = useApi();

  const location = useLocation();
  const uriParam = encodeURI(location.pathname);

  const { watchFn } = useWatch();

  const getTopic = api.get({
    url: api_routes.pages.topic + uriParam,
    redirect404: true,
  });
  const _tagResponse = useQuery<TopicPageResponse>(
    location.pathname,
    getTopic,
    {
      enabled: !apiLoading,
    }
  );
  const topic = _tagResponse.data && _tagResponse.data;
  // const [truncateRelatedTagsButton, truncatedRelatedTagsItems] = useTruncate((tag && tag.related_tags) || [], 8)
  const [truncateBasketsButton, truncatedBasketsItems] = useTruncate(
    (topic && topic.baskets) || [],
    12
  );

  const isLoading = _tagResponse.isLoading;
  if (isLoading) {
    return <div />;
  }

  if (!topic) {
    return <div />;
  }

  (window as any).prerenderReady = true;

  const hasBaskets = !!topic.baskets && !!topic.baskets.length;

  return (
    <Container>
      <PageMeta
        data={topic.meta_tags}
        defaultData={{ title: topic.title + " | Topic" }}
      />
      <PageTop>{/* <Breadcrumbs items={items} /> */}</PageTop>
      <PageMain space="" className="justify-center">
        <PageMainLeft size="lg:w-3/4">
          <Typography variant="h2" element="h1" className="mb-6">
            {topic.title}{" "}
          </Typography>
          {!!topic.description && <Markdown>{topic.description}</Markdown>}
          <TagList
            center={false}
            size={GlobalUISizesEnum.SMALL}
            items={topic.related_tags || []}
            className="mt-5"
          />

          {hasBaskets && (
            <>
              <BasketCardList
                className="mt-8"
                itemClassName="sm:w-1/2 w-full"
                onWatch={watchFn}
                itemWrapper={WatchBasketPriceUpdate}
                items={truncatedBasketsItems as any[]}
              />
              {truncateBasketsButton}
            </>
          )}
        </PageMainLeft>
      </PageMain>
    </Container>
  );
};

export default Topic;
