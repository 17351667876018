import {
  Container,
  Typography,
  PageMain,
  PageMainLeft,
  PageMainRight,
  NewsList,
  useCurrentBreakpointName,
  NewsItemFeatured,
  PageTop,
  NewsSection,
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import React from "react"
import {
  useQuery,
} from 'react-query'
import { useLocation } from "react-router-dom"
import { api_routes } from "../../_api/api-routes";
import { useWatch } from "../../_utils/use-watch";
import PageMeta from "../../components/page-meta";
import { NewsIndexPageResponse } from "../../_api/pages/news-index"
// import FeaturedNewsItem from "./FeaturedNewsItem"

const NewsIndex: React.FC = () => {
  const { apiLoading, ...api } = useApi()
  const name = useCurrentBreakpointName()
  const { watchFn } = useWatch()
  const location = useLocation()
  const getNews = api.get({ url: api_routes.pages.news_index })
  const _newsResponse = useQuery(location.pathname, getNews, {
    enabled: !apiLoading,
    refetchInterval: 10000
  })
  const news = _newsResponse.data as NewsIndexPageResponse
  const isLoading = _newsResponse.isLoading

  if (isLoading) {
    return <div />
  }
  if (!news) {
    return <div />
  }
  (window as any).prerenderReady = true;

  return (
    <Container>
      <PageMeta data={news.meta_tags} />
    

      <PageTop><></></PageTop>
     <Typography variant="h2" element="h1" className="mb-8">Latest News</Typography>
      <PageMain>
        <PageMainLeft>
     {news.latest_news && (<><NewsItemFeatured item={news.latest_news[0]}/>
      <NewsList showImages={true} items={news.latest_news.slice(1,news.latest_news.length - 1 ) || []}/></>)
    }
    </PageMainLeft>
    <PageMainRight>
    {news.crypto_news && (<NewsSection title="Crypto News" className="-mt-1 mb-16" items={news.crypto_news}/>)}
    {news.stock_news && (<NewsSection title="Stock News" className="mb-16" items={news.stock_news}/>)}
    {news.etf_news && (<NewsSection title="ETF News" items={news.etf_news}/>)}

    </PageMainRight>
      </PageMain>
    </Container>
  )

}

export default NewsIndex