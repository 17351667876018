import React from "react";
import {
  Breadcrumbs, Container, formatString, Markdown,
  Loading,
  FormatEnum,
  PageMainLeft,
} from "@potato/components";
import useApi from "../../_contexts/api/useApi";
import {
  useQuery,
} from 'react-query'
import { api_routes } from "../../_api/api-routes";
import { MarkdownContentResponse } from "../../_api/markdown-content";
import { useParams, Redirect } from "react-router-dom";
import PageMeta from "../../components/page-meta";

const Job = () => {
  const { get } = useApi()
  const { job } = useParams<{ job: string }>()

  const getJob = get({ url: api_routes.stealth.pages.job + encodeURI('/careers/' + job), waitForAuth: false })
  const _data = useQuery('job', getJob)

  const data = _data.data as MarkdownContentResponse
  if (_data.isLoading) {
    return <Loading className="mt-32" />
  }

  if (data.status === "error") {
    return <Redirect to="/careers" />
  }

  const items = [{
    label: 'Careers',
    value: '/careers'
  }, {
    label: formatString(job.split('-').join(' '), FormatEnum.Capitalize).replace('Ui', 'UI')
  }]

  if (!data) {
    return <Loading className="mt-32" />
  }
  (window as any).prerenderReady = true;
  return (
    <Container className="mb-20" >
      <PageMeta data={data.meta_tags} defaultData={{ title: "Careers | Potato" }} />
      <PageMainLeft className="mx-auto mt-8">
      <Breadcrumbs items={items} className="mb-8" />


        <Markdown>
          {data.body}
        </Markdown>
      </PageMainLeft>
    </Container>
  );
};

export default Job;

