import React from "react";
import {
  ButtonVariant,
  TagType,
  Dialog,
  useToggle,
  Typography,
  BasketItemDetail,
  LineIcon,
} from "@potato/components"
import { useDeleteBasket } from "../../../_utils/use-delete-basket";
import { MenuListItemProps } from "@potato/components/lib/components/MenuListItem/MenuListItem";
import { ValueOf } from "../../../_types/value-of";
import { useHistory } from "react-router-dom";

export interface PublishProps {
  item: BasketItemDetail
  className?: string
  property?: keyof TagType
};

const useDelete = ({
  item,
  className,
}: PublishProps) => {
  const { deleteBasket } = useDeleteBasket()
  const history = useHistory()
  const { selected: isOpen, onChange: toggleDialog, setSelected: setIsDialogOpen } = useToggle(false)

  const onConfrim = async () => {
    await deleteBasket({ id: item.id, user: item.user! })
    setIsDialogOpen(false)
    history.push('/')
  }

  const menuItemOnClick = isEmpty(item) ? onConfrim : toggleDialog
  const itemConfig: ValueOf<Pick<MenuListItemProps, 'item'>> = {
    onClick: menuItemOnClick,
    label: 'Delete',
    icon: 'trash2',
    value: '',
    closeOnClick: true
  }

  const dialogFooter = <div className={'mt-4 flex  space-x-4'}>
    <ButtonVariant.Pill fullWidth={true} iconLeft={<LineIcon icon="cross" className="flex items-center" />} onClick={toggleDialog} label="Cancel" />
    <ButtonVariant.Cta fullWidth={true} onClick={onConfrim} label="Delete" />
  </div>
  return {
    menuItem: itemConfig,
    dialog: (
      <div className={className}>
        <Dialog closeButton={false} onClose={() => setIsDialogOpen(false)} height={200} width={300} open={isOpen} footer={dialogFooter}>
        <Inner item={item}/>
        </Dialog>
      </div>
    )
  };
};

export default useDelete;
const Inner = ({ height, item }: { height?: number, item:BasketItemDetail }) => {
  return <div className="flex items-center text-center w-full" style={{ height}}> 
  <div className="flex-1">
    <Typography variant="p" >Are you sure you want to delete <br className="hidden md:block"/>{`${item.emoji} ${item.title}?`}</Typography>
  </div>
  </div>

}
const isEmpty = (basket: BasketItemDetail) => {
  return !basket.title && !basket.holdings.length && !basket.description
}