import { useState } from "react";
import useApi from "../../_contexts/api/useApi";
import { useQueryClient } from "react-query";
import { api_routes } from "../../_api/api-routes";
import { CommentReferenceType } from "@potato/components";
export type UseCommentsArgs = {
  id: string
  type: CommentReferenceType
}

export const useComments = ({ id, type }: UseCommentsArgs) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const [reset, setReset] = useState(0)

  const onSubmit = async (data: {
    comment: string,
    is_bearish: number
    is_bullish: number
  }) => {
    const result = await api.post({
      url: api_routes.actions.post_comment, data: {
        ...data,
        reference_type: type,
        reference_id: id
      }
    })()
    if (result && result.status === "success") {
      setReset(reset + 1)
      queryClient.invalidateQueries(`${id}-comments`)
      queryClient.invalidateQueries(`${id}-comments-dialog`)
    }
    return result
  }
  const onDelete = async (_id: string) => {
    const result = await api.delete({
      url: api_routes.actions.post_comment, data: {
        id: _id
      }
    })()
    if (result.status === "success") {
      setReset(reset + 1)
      queryClient.invalidateQueries(`${id}-comments`)
      queryClient.invalidateQueries(`${id}-comments-dialog`)
    }
    return result
  }
  const onReport = async (args: { reason: string, reference_id: string, reference_type: CommentReferenceType }) => {
    const result = await api.post({
      url: api_routes.actions.report,
      data: args
    })()
    return result && result.status === "success"
  }
  return { onSubmit, onDelete, onReport, reset }
}