import useApi from "../../_contexts/api/useApi"
import BillingLayout from "./BillingLayout"
import { api_routes } from "../../_api/api-routes";
import { useQuery } from "react-query";
import { BillingPageResponse } from "../../_api/pages/billing";
import { Loading } from "@potato/components";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";

export interface BillingProps {
};

const Billing = ({

}: BillingProps) => {
    const api = useApi()
    const { user } = usePotatoUser()
    const getBilling = api.get({ url: api_routes.pages.billing, redirect404: true })

    const billing_response = useQuery(`billing-${user ? user.id : ''}`, getBilling, {
        enabled: !!user
    })

    if (billing_response.isLoading || !billing_response || !billing_response.data) {
        return <Loading className="mt-32" />
      }
    const billing = billing_response.data as BillingPageResponse
    // return <></>
    return <BillingLayout billing={billing} ></BillingLayout>
}

export default Billing