import {
  NewsItemType,
  useTruncate,
  Container,
  Typography,
  TagList,
  BasketCardList,
  GlobalUISizesEnum,
  PageMain,
  PageMainLeft,
  PageMainRight,
  NewsList,
  FormatEnum,
  ButtonVariant,
  LineIcon,
  useCurrentBreakpointName,
  HoldingsList,
  Format,
  Markdown,
  Breadcrumbs,
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import React from "react"
import {
  useQuery,
} from 'react-query'
import { useLocation } from "react-router-dom"
import { api_routes } from "../../_api/api-routes";
import { NewsPageResponse } from "../../_api/pages/news";
import WatchBasketPriceUpdate from '../../hoc/watch-basket-updates'
import WatchHoldingPriceUpdate from '../../hoc/watch-holdings-updates'
import { useWatch } from "../../_utils/use-watch";
import PageMeta from "../../components/page-meta"

const News: React.FC = () => {
  const { apiLoading, ...api } = useApi()
  const name = useCurrentBreakpointName()
  const { watchFn } = useWatch()
  const location = useLocation()
  const uriParam = encodeURI(location.pathname)
  const getNews = api.get({ url: api_routes.pages.news + uriParam, redirect404: true })
  const _newsResponse = useQuery(location.pathname, getNews, {
    enabled: !apiLoading
  })
  const news = _newsResponse.data as NewsPageResponse
  const [truncateOtherNewsButton, truncatedOtherNewsItems] = useTruncate((news && news.latest_news) || [], 8)
  const [truncateBasketsButton, truncatedBasketsItems] = useTruncate((news && news.related_baskets) || [], 12)
  const isLoading = _newsResponse.isLoading

  if (isLoading) {
    return <div />
  }
  if (!news) {
    return <div />
  }
  (window as any).prerenderReady = true;
  return (
    <Container>
      <PageMeta data={news.meta_tags} />
    
      <PageMain>
        <PageMainLeft>
          <Breadcrumbs items={[{
    label: 'Browse'
  },{
    prefix: <LineIcon icon="news" />,
            label: 'News',
            value: '/news'
          }]} />
          <Typography variant={"h2"} element="h1" className="mt-6 ">{news.title}</Typography>
          <div className="lg:flex md:flex lg:space-x-8 md:space-x-8 mt-6">
            {news.picture && (
              <div className="flex-initial ">
                <div className="lg:w-52 md:w-52 w-full bg-contain bg-no-repeat bg-center rounded" style={{ backgroundImage: `url(${news.picture})` }}>
                  <img alt={news.title} src={news.picture} className="rounded opacity-0" />
                </div>
              </div>
            )}
            <div className="flex-initial">
              <div className="flex divide-x divide-grey text-secondary space-x-2 leading-3 mt-4 md:mt-0 lg:mt-0">
                <Typography  href={news.publisher_url} externalLink={true} >{news.publisher_name}</Typography>
                <Typography className=" pl-2" ><Format value={news.published_on} format={FormatEnum.Date} /></Typography>
              </div>
              <Markdown className="mt-3 whitespace-pre-line">{news.description}</Markdown>
              <ButtonVariant.Text className="pl-0" href={news.url} externalLink={true}  label={<span className="underline">Read more</span>} iconRight={<LineIcon icon="chevron-right" className="block" />} />
              <TagList size={GlobalUISizesEnum.SMALL} center={false} items={news.related_tags || []} truncateCount={15} className="mt-3" />
            </div>
          </div>
          {news.related_tickers && news.related_tickers.length && (
            <>
              <Typography variant="h2" className="mt-8">
                Holdings
              </Typography>
              <HoldingsList clickable={true} itemWrapper={WatchHoldingPriceUpdate} hideProperties={['allocation']} truncateCount={6} items={news.related_tickers} className="mt-4" />
              {truncateBasketsButton}
            </>
          )}
          {news.related_baskets && !!news.related_baskets.length && (
            <>
              <Typography variant="h2" className="mt-8">
                Relevant Baskets
              </Typography>
              <BasketCardList itemClassName="sm:w-1/2 w-full" onWatch={watchFn} itemWrapper={WatchBasketPriceUpdate as any} detailed={true} className="mt-5" items={truncatedBasketsItems as any[]} />
              {truncateBasketsButton}
            </>
          )}
        </PageMainLeft>
        <PageMainRight>
          <Typography variant="h3" className="mt-6 mb-4">
            Latest News
          </Typography>
          <NewsList className="mt-2" items={truncatedOtherNewsItems as NewsItemType[]} />
          {truncateOtherNewsButton}
        </PageMainRight>
      </PageMain>
    </Container>
  )

}

export default News