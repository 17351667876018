import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { HorizontalScrollList, LazyLoadBackground, useThemeContext } from '@potato/components'
import { useCurrentBreakpointName } from "react-socks";
import clsx from "clsx";
const rio = require("react-intersection-observer");
const img1 = 'https://s3.amazonaws.com/static.potato.trade/component-assets/league.png';
const img2 = 'https://s3.amazonaws.com/static.potato.trade/component-assets/trades.png';
const img3 = 'https://s3.amazonaws.com/static.potato.trade/component-assets/chat.png';


export interface JoinALeagueProps {
};
const textVariants = {
  visible: { opacity: 1, y: 0, transition: { ease: "easeInOut", duration: 1 } },
  hidden: { opacity: 0, y: 20 }
};

const container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.2,
    }
  }
}

const cardVariants = {
  offscreen: {
    y: 300
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

const item = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }
}

const JoinALeague = ({
}: JoinALeagueProps) => {
  const controls = useAnimation();
  const [ref, inView] = rio.useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);


  return (<div className="my-8">
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={textVariants}
      className="text-center"
    >
      <h3 className="md:text-4xl md:leading-10 leading-6 text-2xl font-bold mb-6">
        Join a league or <br className="block md:hidden" />create your own
      </h3>
      <p className="md:text-xl text-md mx-auto block"><span className="">Create your own fantasy trading league with your friends or enter a public league.<br className="md:block hidden" />
        {' '} Take on <strong>Warren Buffett, Ray Dalio, Cathy Wood,</strong> the <strong>S&amp;P 500</strong> and more.</span></p>

    </motion.div>
    <Images />

  </div>
  );
};

const Images = ({
}: JoinALeagueProps) => {
  const controls = useAnimation();
  const [ref, inView] = rio.useInView({
    threshold: 0.5,
  });
  const name = useCurrentBreakpointName()
  const { theme, isDark } = useThemeContext()
  const isLarge = ['large', 'xlarge', 'medium'].includes(name)

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return isLarge ? (
    <motion.ol
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={container}
      className="space-x-8 mt-20 flex max-w-5xl mx-auto"
    >
      <motion.li variants={item} className="">

        <LazyLoadBackground
          className={clsx("bg-cover rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 ")}
          src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-1-${theme}.png`} // use normal <img> attributes as props
        >
          <img
            width="280"
            height="494"
            className="w-full rounded-2xl lg:left-0 lg:w-auto lg:max-w-full opacity-0"
            src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-1-${theme}.png`}
            alt="Fantasy trading leader-board chart UI example"
          />
        </LazyLoadBackground>

      </motion.li>
      <motion.li variants={item} className="">
        <LazyLoadBackground
          className={clsx("bg-cover rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 ")}
          src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-2-${theme}.png`} // use normal <img> attributes as props
        >
          <img
            width="280"
            height="494"
            className="w-full rounded-2xl lg:left-0 lg:w-auto lg:max-w-full opacity-0"
            src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-2-${theme}.png`}
            alt="Fantasy trading first place ranking example"
          />
        </LazyLoadBackground></motion.li>
      <motion.li variants={item} className="">  <LazyLoadBackground
          className={clsx("bg-cover rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 ")}
          src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-3-${theme}.png`} // use normal <img> attributes as props
        >
          <img
            width="280"
            height="494"
            className="w-full rounded-2xl lg:left-0 lg:w-auto lg:max-w-full opacity-0"
            src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-3-${theme}.png`}
            alt="Chat screen example"
          />
        </LazyLoadBackground></motion.li>
    </motion.ol>
  ) :
    <>
      <motion.ol
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={textVariants}
        className="space-x-2 mt-6 flex"
      >

      </motion.ol>
      <HorizontalScrollList itemWidth={241} items={[
     
     <LazyLoadBackground
     className={clsx("bg-cover rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 ")}
     src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-1-${theme}.png`} // use normal <img> attributes as props
   >
     <img
       width="280"
       height="494"
       className="w-full rounded-2xl lg:left-0 lg:h-full lg:w-auto lg:max-w-full opacity-0"
       src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-1-${theme}.png`}
       alt="Fantasy trading leader-board chart UI example"
     />
   </LazyLoadBackground>,
      <LazyLoadBackground
      className={clsx("bg-cover rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 ")}
      src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-2-${theme}.png`} // use normal <img> attributes as props
    >
      <img
        width="280"
        height="494"
        className="w-full rounded-2xl lg:left-0 lg:h-full lg:w-auto lg:max-w-full opacity-0"
        src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-2-${theme}.png`}
        alt="Fantasy trading first place ranking example"
      />
    </LazyLoadBackground>,
      <LazyLoadBackground
      className={clsx("bg-cover rounded-2xl shadow-xl ring-1 ring-black ring-opacity-5 ")}
      src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-3-${theme}.png`} // use normal <img> attributes as props
    >
      <img
        width="280"
        height="494"
        className="w-full rounded-2xl lg:left-0 lg:h-full lg:w-auto lg:max-w-full opacity-0"
        src={`https://s3.amazonaws.com/static.potato.trade/component-assets/screen-3-${theme}.png`}
        alt="Chat screen example"
      />
    </LazyLoadBackground>]}
      />
    </>
}

export default JoinALeague;
