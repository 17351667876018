import {
  BasketItemDetail,
  D3Chart,
  Typography,
  usePosition,
} from "@potato/components";
import React, { useEffect, useRef, useState } from "react";
import { api_routes } from "../../_api/api-routes";
import { useD3ChartData } from "../../_utils/use-d3-chart-data";
import LiveButton from "../fantasy/LiveButton";

type ChartSectionProps = {
  item: BasketItemDetail;
};

const ChartSection = ({ item }: ChartSectionProps) => {
  const [view, setView] = useState<string>("full");
  const [initialZoom, setInitialZoom] = useState(false);
  let timeout: any = useRef(null);

  useEffect(() => {
    if (initialZoom && timeout.current) {
      clearTimeout(timeout.current);
      timeout = null;
    }
  }, [initialZoom, timeout]);

  useEffect(() => {
    if (!initialZoom && item.is_live) {
      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          setView("closeup");
          setInitialZoom(true);
        }, 3000);
      }
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  return (
    <div>
      <div className="flex items-center mt-16 md:mt-24  mb-6">
        <div className="flex-1">
          <Typography variant="h3" element="h2" className="">
            Performance
          </Typography>
        </div>
        {item.is_live && (
          <LiveButton
            onClick={() => {
              setView(view === "full" ? "closeup" : "full");
              setInitialZoom(true);
            }}
            className="float-right"
            on={view === "closeup"}
          />
        )}
      </div>
      <BasketChart view={view} id={item.id} />
    </div>
  );
};
type BasketChartProps = {
  view: string;
  id: string;
};
const BasketChart = ({ view, id }: BasketChartProps) => {
  const ref = useRef();
  let {
    position: { width, height },
  } = usePosition(ref);
  const { active_view } = useD3ChartData({
    options: {
      id: id,
      view,
    },
    active_item: view,
    key: "view",
    full_item: "full",
    latest_item: "latest",
    fetch_updates_on: "closeup",
    url: api_routes.charts.basket,
  });

  return (
    <div
      className="w-full max-w-full md:h-[350px] h-[250px] text-grey mt-4 md:mt-4"
      ref={ref as any}
    >
      <div className="relative h-full w-full">
        {width > 0 && active_view.data.length ? (
          <D3Chart
            y_formatter={(y) => {
              return `${y.toPrecision(3)}%`;
            }}
            series={active_view.data}
            range={active_view.range}
            options={{
              width,
              height,
              shift_x: view === "closeup",
              markers: {
                picture: true,
                ping: view === "closeup",
                outline: view !== "closeup",
              },
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
const propsAreEqual = (prev: ChartSectionProps, next: ChartSectionProps) => {
  return prev.item.id === next.item.id;
};
export default React.memo(ChartSection, propsAreEqual);
