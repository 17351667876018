import React, { useEffect } from "react";
import clsx from "clsx";
import { ButtonVariant, Ping, useThemeContext, useToggle } from "@potato/components";

export interface LiveButtonProps {
    on: boolean,
    className?: string
    onClick: () => void
};

const LiveButton = ({
    on,
    className,
    onClick
}: LiveButtonProps) => {
    const { selected, onChange, setSelected } = useToggle(on)
    const { colors: { bgColor}, isDark} = useThemeContext()
    useEffect(() => {
        setSelected(on)
    }, [on])

    const classes = selected ? {
        base: 'rounded-full ',
        default: 'bg-black text-white',
        active: 'bg-black text-white',
        disabled: 'opacity-60 cursor-default',
        hover: 'hover:bg-black-light'
    } : {
        base: 'rounded-full',
        default: '',
        active: '',
        disabled: 'opacity-60 cursor-default',
        hover: 'hover:bg-pill'
    }
    const _onClick
        = () => {
            onChange()
            onClick()
        }
    return (
        <ButtonVariant.Text
            className={className}
            classes={{hover: ''}}
            // classes={classes}
            onClick={_onClick}
            label="LIVE"
            iconLeft={
                <Ping
                    on={selected}
                    className={"mb-px mr-0.5 -ml-0.5"}
                    color={ isDark ? '#ffffff' : '#000000'} />
            }
        />
    );
};

export default LiveButton;