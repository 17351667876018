import {
  DropdownButton,
  GlobalUISizesEnum,
  NotificationsButton,
  NotificationsDialog,
  NotificationsList,
  usePageStatus,
} from "@potato/components";
import React from "react";
import { Breakpoint } from "react-socks";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";

export interface NotificationsContentProps {}

const NotificationsContent = ({}: NotificationsContentProps) => {
  const {
    notifications,
    notifications_loading,
    clearNotifications,
  } = usePotatoUser();
  const { userNotifications } = usePageStatus();

  const notification_count = notifications.filter((n) => !n.seen_at).length;

  return (
    <>
      <Breakpoint large up>
        <DropdownButton
          size={GlobalUISizesEnum.LARGE}
          space={"py-4 px-4"}
          buttonElem={
            <NotificationsButton
              onClick={() => clearNotifications()}
              notification_count={notification_count}
            />
          }
          position={"right"}
        >{({ close, open }) => (
            <div
              className="w-sm bg-lifted shadow-2xl rounded flex flex-col"
              style={{
                height:
                  !notifications_loading &&
                  notifications &&
                  notifications.length
                    ? 500
                    : 100,
                width: 400,
              }}
            >
              <div className="flex-1">
                <NotificationsList
                  onClose={close}
                  className=""
                  loading={notifications_loading}
                  items={notifications || []}
                />
              </div>
            </div>
          )}</DropdownButton>
      </Breakpoint>
      <Breakpoint medium down>
        <NotificationsButton
          onClick={() => {
            userNotifications.openMenu();
            clearNotifications();
          }}
          notification_count={notification_count}
        />
        <NotificationsDialog
          notifications={notifications || []}
          loading={notifications_loading}
        />
      </Breakpoint>
    </>
  );
};

export default NotificationsContent;
