import { Typography } from "@potato/components"
type Props =  { stock_count?: number, crypto_count?: number, etf_count?: number }
export const getStack = (args: Props) => {
  const stack: string[] = []
  if (args.stock_count) {
    stack.push(`${args.stock_count} Stock${args.stock_count === 1 ? '' : 's'}`)
  }
  if (args.crypto_count) {
    stack.push(`${args.crypto_count} Crypto${args.crypto_count === 1 ? '' : 's'}`)
  }
  if (args.etf_count) {
    stack.push(`${args.etf_count} ETF${args.etf_count === 1 ? '' : 's'}`)
  }
  return stack
} 

export const getStackString = (args: Props) => {
  const stack = getStack(args)
  return stack.length ? stack.join(', ') : ''
}
 const Stack = (item:Props) => {
 const stack = getStack(item)

  return <div className="flex items-center">
    {stack.map((item) => {
      return <div key={item} className="first:pl-0 px-2"><Typography>{item}</Typography></div>
    })}
  </div>


}

export default Stack