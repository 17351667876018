import { Typography, BasketList, Container, LineIcon, Breakpoint, useCurrentBreakpointName, ButtonVariant, GlobalUISizesEnum } from '@potato/components'
import { BasketsSectionItem } from "../../../_api/pages/home";
import WatchBasketPriceUpdate from '../../../hoc/watch-basket-updates'
import clsx from "clsx"
export interface GainersLosersProps { 
  item: BasketsSectionItem
  className?:string
};

const GainersLosers = ({
 item,
 className
}: GainersLosersProps) => {
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)

return (
  <Container className={clsx("lg:py-12 py-4", className)}>
    <div className="grid lg:grid-cols-2 lg:gap-6 gap-4">
      <div className="md:mb-0 lg:mb-0 mb-4">
        <div className="flex items-center space-x-1 my-4">
          <div className="flex-auto">
            <Typography variant="h3" element="h2">Gainers</Typography>
          </div>
          <div className="flex-initial">
          <Breakpoint medium down>
            <ButtonVariant.Pill href={item.slug} size={isLarge ? GlobalUISizesEnum.MEDIUM : GlobalUISizesEnum.SMALL } iconRight={<LineIcon className="block text-sm -mr-1 mt-px" icon="chevron-right" />} label="See more" />
          </Breakpoint>
          </div>
        </div>
        <BasketList itemWrapper={WatchBasketPriceUpdate as any} data={item.gainers || []} />
      </div>
      <div className="">
        <div className="flex items-center">
          <div className="flex-auto">
            <Typography variant="h3" element="h2" className="my-4">Losers</Typography>
          </div>
          <div className="flex-initial">
          <ButtonVariant.Pill href={item.slug} size={isLarge ? GlobalUISizesEnum.MEDIUM : GlobalUISizesEnum.SMALL } iconRight={<LineIcon className="block text-sm -mr-1 mt-px" icon="chevron-right" />} label="See more" />
          </div>
        </div>
        <BasketList itemWrapper={WatchBasketPriceUpdate as any} data={item.losers || []} />
      </div>
    </div>
    </Container>
  );
};

export default GainersLosers;