import { useDelay } from "@potato/components";
import React, { FormHTMLAttributes, useEffect, useState } from "react";
import useApi from "../../_contexts/api/useApi";
type OnChangeMap = {
  input: (e: React.ChangeEvent<any>) => void,
  file: (e: React.ChangeEvent<any>) => void
}
export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: (args: {
    onchange_functions: OnChangeMap,
    data: any
    status_text: string | undefined
    error_message: string | false
  }) => React.ReactNode
  initialData: { [x: string]: any }
  onSuccess?: () => void
};

const Form = React.forwardRef<any, FormProps>(({ initialData = {}, onSuccess, ...props }, ref) => {
  const [data, setData] = useState<any>(initialData)
  const [status_text, setStatusText] = useState<string | undefined>('Save Changes')
  const [errors, setErrors] = useState<string | false>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { _delay, triggerDelay, initCount } = useDelay(4000)
  const api = useApi()
  useEffect(() => {
    if (loading) {
      setStatusText('Saving...')
      return
    }
    if (_delay && initCount) {
      setStatusText(errors ? 'Failed to save' : 'Saved')
    } else {
      setStatusText('Save Changes')
    }
  }, [loading, _delay, initCount, errors])
  useEffect(() => {
    if(errors && !_delay) {
      setErrors(false)
    }
  }, [data, errors, _delay])
  const input_onchange = (e: React.ChangeEvent<any>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  }
  const file_onChange = (e: React.ChangeEvent<any>) => {
    setData({
      ...data,
      [e.target.name]: e.target.files[0]
    });
  }
  const onchange_functions = {
    input: input_onchange,
    file: file_onChange,
  }
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: any) => {
    e.preventDefault();
    const h: HeadersInit = {}; //headers
    let formData = new FormData(e.target);
    h.Accept = 'application/json';

    if (props.action && api.auth0ApiReady) {
      setLoading(true)
      const result = await api.patch({
        url: props.action,
        headers: h,
        data: formData
      })()
      setLoading(false)
      triggerDelay()
      if (result.status === "success") {
        if (onSuccess) {
          onSuccess()
        }
      } else {
        setErrors(!!result.error_message ? result.error_message : false)
      }
      setData({});
    }
  }
  return <form ref={ref} onReset={() => { setData({}) }} encType={'multipart/form-data'} {...props} style={{ ...props.style, maxWidth: '100%' }} onSubmit={onSubmit}>
    {props.children({ onchange_functions, data, status_text, error_message: errors })}
  </form>
});

export default Form;