import React, {
  createContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { motion, useAnimation } from "framer-motion";
import type { FC, ReactNode } from 'react';

// what gets passed to consumer  const { shake, shakeit } = useShakeContext()
export interface ShakeContextValue {
  shake: number
  shakeit: () => void
  Shaker: ({ id, children }: {
    id: string;
    children: React.ReactNode | JSX.Element;
  }) => JSX.Element
  variants: { start: any, reset: any },
}
// what passed to provider
interface ShakeProviderProps {
  children?: ReactNode
}
// an initial value for the consumer 
const ShakeContext = createContext<ShakeContextValue>({
  shake: 0,
  variants: { start: (i: number) => ({}), reset: {} },
  shakeit: () => { },
  Shaker: () => <></>,
});

const variants = {
  start: ({
    rotate: [-4,10.3, 0],
    transition: {
      delay:.05,
      repeat: 2,
      duration: .15
    }
  }),
  reset: {
    rotate: 0
  }
};


export const ShakeProvider: FC<ShakeProviderProps> = ({ children }) => {
  const [shake, setShake] = useState<number>(0);
  const controls = useAnimation();
  const shakeit = () => {
    setShake(shake + 1)
  }
  useEffect(() => {
    if (shake) {
      controls.start("start");
    
    }
  }, [shake])

  const wrapper = ({ id, children }: { id: string, children: React.ReactNode | JSX.Element }) => {
    return <motion.div
      animate={controls}
      key={id}
      style={{
        originX: .5,
        originY: .5
      }}
      variants={variants}
    >{children}</motion.div>
  }
  return (
    <ShakeContext.Provider
      value={{
        Shaker: wrapper,
        shake,
        shakeit,
        variants
      }}
    >
      {children}
    </ShakeContext.Provider>
  );
};

export const SomeConsumer = ShakeContext.Consumer;

export default ShakeContext;
