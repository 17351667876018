
import React from 'react'
import { FantasyDetailItemType } from "@potato/components"

export const is_member = (item: FantasyDetailItemType) => {
    return (item.is_member)
}


export const NonMemberGuard = ({ item, children }: { item: FantasyDetailItemType, children: React.ReactNode }) => {
    if (item.is_member) {
        return <></>
    }
    return <>{children}</>
}

export const MemberGuard = ({ item, children }: { item: FantasyDetailItemType, children: React.ReactNode }) => {
    if (!item.is_member) {
        return <></>
    }
    return <>{children}</>
}

export const PublicOrMemberGuard = ({ item, children }: { item: FantasyDetailItemType, children: React.ReactNode }) => {
    if (!item.is_public && !item.is_member) {
        return <></>
    }
    return <>{children}</>
}
