import React, {
  createContext,
  useEffect,
  useState
} from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

// what gets passed to consumer  const { loaded, setPageMode } = usePageModeContext()
export interface PageModeContextValue {
  pageMode: string
  setPageMode: (val: string) => void
}
// what passed to provider
interface PageModeProviderProps {
  initialMode: string,
  children?: ReactNode
}
// an initial value for the consumer 
const PageModeContext = createContext<PageModeContextValue>({
  pageMode: '',
  setPageMode: (val: string) => { }
});



export const PageModeProvider: FC<PageModeProviderProps> = ({ children, initialMode }) => {
  const [pageMode, setPageMode] = useState<string>(initialMode);
  const { pathname } = useLocation();

  useEffect(() => {
    setPageMode(initialMode)
  }, [pathname]);

  return (
    <PageModeContext.Provider
      value={{
        pageMode,
        setPageMode
      }}
    >
      {children}
    </PageModeContext.Provider>
  );
};

export const PageModeConsumer = PageModeContext.Consumer;

export default PageModeContext;
