import { useState } from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useQueryClient } from "react-query";
import { usePageStatus } from "@potato/components";
import { UpdateHoldingsArgs } from "../components/edit-holdings/EditHoldings";
export const useUpdateBasket = () => {
  const {setSaving, setSaved, setUnsavedChanges } = usePageStatus()
  const api = useApi()
  const queryClient = useQueryClient()

  const updateBasket = async (data: { id: string, description?: string, title?: string, emoji?: string }) => {
    if (data.title) {
      setSaving()
      await api.patch({
        url: api_routes.actions.update_basket,
        data: data
      })()
      queryClient.invalidateQueries(data.id)
      queryClient.invalidateQueries('me')
      setSaved()
    } else {
      setUnsavedChanges()
    }
  }

  return { updateBasket };
}