import { LineIcon, Typography, UserAvatar, GlobalUISizesEnum, FormatEnum, format } from "@potato/components"
import React from "react"
import { MetaProps } from "./types"

const Meta: React.FC<MetaProps> = ({ item }) => {
  return <div className={("space-y-2 mb-8 mt-8")}>
    {!!item.ceo &&
      <div className="flex items-center">
        <div className="flex-0 space-x-0 min-w-12 flex items-center " style={{ minWidth: '120px' }} ><LineIcon className="text-secondary w-6" icon="user" /> <Typography className="text-secondary inline-block "> CEO</Typography></div>
        <div className=" "><UserAvatar bio={false} size={GlobalUISizesEnum.SMALL} item={{ username: item.ceo!, picture: item.ceo_picture }} /></div>
      </div>}
    {!!item.exchange_description && (
      <div className="flex items-center">
        <div className="flex-0 space-x-0 min-w-12 flex items-center " style={{ minWidth: '120px' }} ><LineIcon className="text-secondary -ml-0.5 text-xl w-6" icon="share3" /> <Typography className="text-secondary inline-block"> Exchange</Typography></div>
        <div className="">{item.exchange_description}</div>
      </div>
    )}
    {!!item.locale_description && item.type !== 'crypto' && (
      <div className="flex items-center">
        <div className="flex-0 space-x-0 min-w-12 flex items-center " style={{ minWidth: '120px' }} ><LineIcon className="text-secondary w-6" icon="earth" /> <Typography className="text-secondary inline-block"> Market</Typography></div>
        <div className="">{item.locale_description}</div>
      </div>
    )}
    {!!item.type && (
      <div className="flex items-center">
        <div className="flex-0 space-x-0 min-w-12 flex items-center " style={{ minWidth: '120px' }} ><LineIcon className="text-secondary w-6" icon="cube" /> <Typography className="text-secondary inline-block"> Type</Typography></div>
        <div className="">{format(item.type, FormatEnum.Capitalize)}</div>
      </div>
    )}
  </div>
}
export default Meta