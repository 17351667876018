import React from "react";
import { AboutUsLayout } from "./AboutUsLayout";
import useApi from "../../_contexts/api/useApi";
import { api_routes } from "../../_api/api-routes";
import { useQuery } from "react-query";
import { Loading } from "@potato/components";
import { useLocation } from "react-router-dom"
import PageMeta from "../../components/page-meta";

const AboutUs = () => {
  const api = useApi()
  const location = useLocation()
  const getAboutUs = api.get({ url: api_routes.pages.about, waitForAuth: false })
  const _aboutUsResponse = useQuery([location.pathname], getAboutUs)
  const aboutUs = _aboutUsResponse.data as any
  const isLoading = _aboutUsResponse.isLoading
  if (isLoading) {
    return <Loading />
  }
  if (!aboutUs) {
    return <div />
  }
  (window as any).prerenderReady = true;
  return (<>
    <PageMeta data={aboutUs.meta_tags} defaultData={{ title: "About | Potato" }} />
    <AboutUsLayout  />
  </>)
}
export default AboutUs