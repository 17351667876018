import { useNotify } from "@potato/components";
import { useState } from "react";
import { api_routes } from "../../../_api/api-routes";
import useApi from "../../../_contexts/api/useApi";

export const useReport = ({ id }: { id: string }) => {
  const api = useApi();
  const [showReportItems, setShowReportItems] = useState(false)
  const { notify } = useNotify();
  const report_menu_item = {
    label: "Report",
    onClick: () => setShowReportItems(true),
    closeOnClick: false,
    icon: "bubble-alert",
  };
 
  const onReport = async (reason: string) => {
    const result = await api.post({
      url: api_routes.actions.report,
      data: {
        reference_id: id,
        reason,
        reference_type: "basket",
      },
    })();
    const success = result && result.status === "success";
    if (success) {
      notify({ message: "Basket has been reported" });
    }
  };

  const report_items = [
    {
      label: "It's spam",
      closeOnClick: true,
      onClick: () => onReport("It's spam"),
    },
    {
      label: "Bulling or harassment",
      closeOnClick: true,
      onClick: () => onReport("Bulling or harassment"),
    },
    {
      label: "Scam or fraud",
      closeOnClick: true,
      onClick: () => onReport("Scam or fraud"),
    },
    {
      label: "Manipulation",
      closeOnClick: true,
      onClick: () => onReport("Manipulation"),
    },
    {
      label: "Offensive content",
      closeOnClick: true,
      onClick: () => onReport("Offensive content"),
    },
    {
      label: "False information",
      closeOnClick: true,
      onClick: () => onReport("False information"),
    },
    {
      label: "Other",
      closeOnClick: true,
      onClick: () => onReport("Other"),
    },
  ];
 
  return {
    showReportItems,
    setShowReportItems,
    report_items,
    report_menu_item
  }
};
