import React, { useEffect, useState } from "react";
import {
  ButtonVariant,
  useEditAllocation,
  LineIcon,
  Typography,
  Breadcrumbs,
  FilterTypeEnum,
  TickerFilters,
  Tabs,
  useSwitch,
  useToggle,
  HoldingType,
} from "@potato/components"
import { useQuery, useQueryClient } from "react-query";
import useApi from "../../_contexts/api/useApi";
import { api_routes } from "../../_api/api-routes";
import { TickerResults, TickerResultsProps } from "../../views/tickers/TickerResults";
import { useTickerSearch } from "../../views/tickers/use-ticker-search";
import { TickersPageResponse } from "../../_api/pages/tickers";
import AllocationDropdown from "./AllocationDropdown";
import { EditHoldingAllocation } from "./EditHoldingAllocation";
import clsx from "clsx";
import { ReviewChanges } from "./ReviewChanges";
import { UpdateHoldingsArgs } from "./EditHoldings";

export interface EditHoldingsProps {
  id: string,
  holdings: HoldingType[],
  title: string,
  emoji: string,
  enable_filter_types?: FilterTypeEnum[]
  updateHoldings: (args: UpdateHoldingsArgs) => void
  className?: string
  onClose: () => void,
  height?: number
  width?: number
};

export const EditHoldings_Mobile = ({
  id, emoji, title, holdings,
  className,
  onClose,
  updateHoldings,
  enable_filter_types = [FilterTypeEnum.all, FilterTypeEnum.stocks, FilterTypeEnum.crypto, FilterTypeEnum.etf],
  height
}: EditHoldingsProps) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const { selectedHoldings, allocation_method, reset, changesCount } = useEditAllocation()
  const { activeItem, onChange } = useSwitch<'select' | 'edit'>('select')
  const { selected: filtersOpen, onChange: toggleFilters, setSelected } = useToggle(false)
  const { activeItem: editViewActiveItem, onChange: editViewOnChange } = useSwitch<'edit_view' | 'changes_view'>('edit_view')
  const [saving, setSaving] = useState(false)

  const getTickersPage = api.get({ url: api_routes.pages.tickers, waitForAuth: false })
  const { data: tickerPageData, isLoading: tickerPageLoading } = useQuery('ticker_filters', getTickersPage)
  const tickersPage = tickerPageData as TickersPageResponse
  const { isLoading, hasNextPage, fetchNextPage, items, setFilters, type } = useTickerSearch({ type: enable_filter_types[0], filters_by_type: tickersPage ? tickersPage.filters : [] })

  const resultListProps: TickerResultsProps = {
    isLoading, hasNextPage, fetchNextPage, items, filter_type: type
  }

  const saveHoldings = async () => {
    setSaving(true)
    updateHoldings({ id, holdings: selectedHoldings, allocation_method })
    await queryClient.invalidateQueries(id)
    setTimeout(() => {
      setSaving(false)
      onClose()
    }, 1000)
  }
  if (!tickersPage) {
    return <div />
  }
  const tabs = [{
    label: 'Select',
    value: 'select',
  }, {
    label: 'Edit',
    value: 'edit',
    badge: changesCount ? <span className="bg-green-transparent text-green font-semibold px-1.5 py-0.5 text-center mb-1 rounded-lg text-sm ml-1">{changesCount}</span> : undefined
  }]
  return (
    <div className={clsx(className, 'px-4')}>
      <div className="flex flex-col" style={{ height: height ? height - 46 : '100vh' }}>

        <div className="flex-1 order-last">

          {
            activeItem === 'select' ? (<>
              <TickerResults onScroll={() => { setSelected(false) }} headerHeight={37} selectable={true} className="h-full" {...resultListProps} />
            </>
            ) : (
              <div className="flex flex-col h-full ">
                <div className="flex-0">

                  <div className="flex space-x-4">
                    <AllocationDropdown />
                    <div className="flex justify-end flex-0 items-center">
                      {changesCount === 0 && <Typography className="text-secondary">0 Changes</Typography>}
                      {!!changesCount && editViewActiveItem === 'edit_view' && <ButtonVariant.Text onClick={() => editViewOnChange('changes_view')} label="See Changes" className="underline" />}
                      {!!changesCount && editViewActiveItem === 'changes_view' && <ButtonVariant.Text onClick={() => editViewOnChange('edit_view')} label="Edit Changes" className="underline" />}
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  {editViewActiveItem === 'edit_view' ? <EditHoldingAllocation /> : <ReviewChanges holdings={holdings} />}
                </div>
              </div>
            )
          }
        </div>
        <div className="flex-0 relative z-10 pt-2">
          {/* <Breadcrumbs items={[{
            prefix: emoji,
            space: '2',
            label: `${title}`
          }]}
          /> */}
          {/* <Typography variant="h2" className="text-left">{holdings.length ? 'Edit Holdings' : 'Add Holdings'}</Typography> */}
          {/* <Tabs align={"justify"} className={clsx("mb-4 text-lg")} onChange={(onChange as (arg: string) => void)} items={tabs} activeItem={activeItem} /> */}
          {activeItem === 'select' && <TickerFilters
            isOpen={filtersOpen}
            onOpenChange={toggleFilters}
            layout="stacked"
            currentType={type || FilterTypeEnum.all}
            enable_filter_types={enable_filter_types}
            filters={tickersPage.filters}
            onChange={setFilters}
          />}
        </div>
      </div>


      <div className="fixed bottom-0 left-0 w-full py-2 z-20 bg-white shadow-md px-4 flex space-x-4 items-center" >
        <div className="flex-1"><ButtonVariant.Pill iconLeft={<LineIcon icon="cross" className="flex items-center" />} fullWidth={true} label="Cancel" onClick={onClose} /></div>
        {activeItem === 'edit' && (
          <div className="flex-1"><ButtonVariant.Cta disabled={changesCount === 0} iconLeft={<LineIcon icon={saving ? "loading" : "check"} className={clsx(saving ? "animate-spin" : '')} />}  fullWidth={true} label={saving ? "Saving..." : "Apply Changes"} onClick={saveHoldings} /></div>
        )}
        {activeItem === 'select' && (
          <div className="flex-1"><ButtonVariant.Cta label={<>Edit Allocation {changesCount ? <span className="text-green font-semibold px-1.5 py-0.5 text-center rounded-lg ml-1 w-full">{changesCount}</span> : undefined} </>}fullWidth={true}  onClick={() => onChange('edit')} /></div>
        )}
      </div>
    </div>
  )
}

