import {
  ButtonVariant as Button,
  FantasyDetailItemType,
  GlobalUISizesEnum
} from "@potato/components"

import React from "react"
import clsx from 'clsx'
import usePageMode from "../../../_contexts/page-mode/page-mode-hook"
import { is_member } from "../PaymentGuard"
import useShakeContext from "../../../_contexts/shaker/shake-context-hook"
const Actions: React.FC<{
  item: FantasyDetailItemType,
  className?: string
}> = ({ item }) => {
  const { setPageMode } = usePageMode()
  const { shakeit } = useShakeContext()

  const users_ranking = item.rankings && item.rankings.find(ranking => ranking.is_mine);
  const has_holdings = users_ranking && users_ranking.holdings && users_ranking.holdings.length

  return <div onClick={!is_member(item) ? shakeit : undefined}>
      <Button.Outline disabled={!is_member(item)} size={ GlobalUISizesEnum.MEDIUM} label={`${has_holdings ? 'Edit' : 'Add'} Holdings`} onClick={() => { setPageMode('edit') }} />
    </div>
}

export default Actions

