import React, { useEffect } from "react";
import {
  BasketHistory,
  Loading,
  Breadcrumbs,
  DialogVariant,
  Typography,
} from "@potato/components"
import { useQuery, useQueryClient } from "react-query";
import useApi from "../../_contexts/api/useApi";
import { api_routes } from "../../_api/api-routes";
import { BasketHistoryResponse } from "../../_api/basket/history";
import { AutoSizer } from "react-virtualized";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
export interface BasketHistoryViewProps  {
    id: string
    title: string
    emoji: string
};


const BasketHistoryView = ({
 id, title, emoji
}: BasketHistoryViewProps) => {
 
    const { setPageMode } = usePageMode()
  return (
    <DialogVariant.FullView widthClass="max-w-6xl lg:w-3/5" onClose={() => setPageMode('main')} open={true} headingContent={<>  
    <Breadcrumbs items={[{
        prefix: emoji,
        space: '2',
        label: `${title}`
      }]}
      />
      <Typography variant="h2" className="mt-2 lg:mb-4 mb-3 text-left lg:text-left">Basket History</Typography>
     </>}>
     <ViewHistoryComponent id={id} />
   
 
  </DialogVariant.FullView>
  );
};

export default BasketHistoryView;


interface ViewHistoryProps {
    id: string
  };
  
  const ViewHistoryComponent = ({
    id,
    height,
    width
  }: ViewHistoryProps & { height?: number, width?: number }) => {
    const queryClient = useQueryClient()
    const { apiLoading, ...api } = useApi()
    const getBasketHistory = api.get({ url: api_routes.basket.history + id })
    const _basketHistoryResponse = useQuery([`history_${id}`, id], getBasketHistory, {
      enabled: !apiLoading,
    })
    useEffect(() => {
      return () => {
        queryClient.invalidateQueries(`history_${id}`)
      }
    }, [id, queryClient])
    let basket_history = _basketHistoryResponse.data as BasketHistoryResponse
    if (!basket_history) {
      return <Loading className="mt-32" />
    }
    return basket_history.history ? <BasketHistory height={height} items={basket_history.history} /> : <div><Loading /></div>
  }