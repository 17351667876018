import { usePageStatus } from '@potato/components'
import React, { useEffect } from 'react'
export const NoFooter = ({ children }: { children: React.ReactNode }) => {
  const { footer, setFooter } = usePageStatus()
  useEffect(() => {
    setFooter(false)
    return () =>  setFooter(true)
  }, [])
  return !footer ? <>
    {children} </>
  : <></>
}