import React, { useCallback, useEffect, useState } from "react";
import { DraftBlockType, Editor, RichUtils, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './styles.scss'
import clsx from "clsx";
import { EditorStateProvider } from "./contexts/editor-state/EditorStateContext";
import useEditorState from "./contexts/editor-state/editor-state-hook";
import { TickerAutocompleteProvider } from "./contexts/ticker-autocomplete/TickerAutocompleteContext";
import TickerAutocomplete from "./decorators/ticker-handle/TickerAutocomplete";
import { TickerHandlesProvider } from "./contexts/ticker-handles-store/TickerHandlesContext";
import { CustomIcon, GlobalUISizesEnum, HoldingType, useEditAllocation, useHover } from "@potato/components";
import { useUpdateHoldings } from "../../_utils/use-update-holdings";

export interface RichTextInputProps {
  readOnly?: boolean
  initialValue?: string
  placeholder?: string
  onChange?: (val: string) => void
  className?: string
};

const _RichTextInput = ({
  readOnly,
  placeholder,
  className
}: RichTextInputProps) => {
  const { editorState, setEditorState, loading, mdValue } = useEditorState()

  const { ref: hoverRef, isHovered: _isHovered } = useHover();
  const [_isFocussed, setIsFocussed] = useState<boolean>()
  const toggleBlockType = (_blockType: DraftBlockType) => () => {
    const selection = editorState.getSelection()
    const newEditorState = EditorState.forceSelection(editorState, selection.merge({ anchorOffset: selection.getEndOffset(), focusOffset: selection.getEndOffset() }))
    setEditorState(RichUtils.toggleBlockType(newEditorState, _blockType));
  }
  if (loading) {
    return <></>
  }
  const hoverClasses = `outline-divider light:outline-pill-hover outline-dashed`
  const focusClasses = `outline-divider light:outline-pill-hover outline-offset-0 outline-dashed`
  const emptyClasses = `outline-lifted light:outline-divider outline-dashed`
  const inputClasses = `border-none bg-transparent outline-2 outline-offset-0 outline-dashed`
  const editableClasses = clsx(inputClasses, editorState.getCurrentContent().hasText() ? 'outline-none outline-solid outline-2' : emptyClasses, _isFocussed && focusClasses, _isHovered && hoverClasses, 'px-2 py-0.5 ')

  return <div className={clsx(className, "flex flex-col")} >

    {!readOnly && <TickerAutocomplete />}

    <div className={clsx(!readOnly && editableClasses, "prose dark:prose-invert cyberpunk:prose-invert max-w-none rounded-md")} ref={hoverRef}>
      <Editor
        placeholder={placeholder}
        readOnly={readOnly}
        editorState={editorState}
        onFocus={() => { setIsFocussed(true) }}
        onBlur={() => { setIsFocussed(false) }}
        onChange={setEditorState}
      />
    </div>
  </div>;
};


const RichTextInput = ({
  initialValue,
  onChange,
  basketId,
  ...props
}: RichTextInputProps & { basketId: string }) => {
  const [newTicker, setNewTicker] = useState<string | null>(null)
  const { updateHoldings } = useUpdateHoldings()
  const { addItem, selectedHoldings, allocation_method } = useEditAllocation()
  const saveHoldings = useCallback(async () => {
    await updateHoldings({ id: basketId, holdings: selectedHoldings, allocation_method })
  }, [selectedHoldings, basketId, allocation_method])

  const onTickerAdd = (item: HoldingType) => {
    const exists = !!selectedHoldings.find(holding => holding.ticker === item.ticker)
    if (exists) { return }
    setNewTicker(item.ticker)
    addItem(item)
  }

  useEffect(() => {
  }, [newTicker])
  useEffect(() => {
    if (newTicker) {
      saveHoldings()
      setNewTicker(null)
    }
  }, [selectedHoldings])

  return <EditorStateProvider onChange={onChange} forceUpdate={basketId} initialValue={initialValue} readOnly={!!props.readOnly}>
    <TickerHandlesProvider onTickerAdd={onTickerAdd}>
      <TickerAutocompleteProvider>
        <_RichTextInput {...props} />
      </TickerAutocompleteProvider>
    </TickerHandlesProvider>
  </EditorStateProvider>
}
export default RichTextInput;

