import {
    ButtonVariant,
    Dialog,
    LineIcon,
    Typography,
    useToggle,
  } from "@potato/components"
  import { MenuListItemProps } from "@potato/components/lib/components/MenuListItem/MenuListItem"
  import clsx from "clsx"
  import { ValueOf } from "../../_types/value-of"
  import { useCancelSubscription } from "./use-cancel-subscription"
  
  export type CancelSubscriptionArgs = {
    description:string,
    id:string
  }
  
  type Props = {
    onCancelCallback?: () => void
    item: CancelSubscriptionArgs,
  }
  
  export const useConfirmCancelSubscription = ({ item, ...props }: Props) => {
    const { cancelSubscription: cancelSubscription } = useCancelSubscription()
    const { selected: isOpen, onChange: toggleDialog, setSelected: setIsDialogOpen } = useToggle(false)
  
    const onConfrim = async () => {
      await cancelSubscription({ id:item.id })
      setIsDialogOpen(false)
      if(props.onCancelCallback) {
        props.onCancelCallback()
      }
    }
    
    const itemConfig: ValueOf<Pick<MenuListItemProps, 'item'>> = {
      label: "Cancel Subscription",
      icon: "exit",
      value: "",
      onClick: toggleDialog,
      closeOnClick: true
    }
  
    const dialogFooter = <div className={'mt-4 flex space-x-4'}>
      <ButtonVariant.Pill onClick={toggleDialog} label="Cancel"  iconLeft={<LineIcon icon="cross" className="flex items-center" />} fullWidth={true}/>
      <ButtonVariant.Cta onClick={onConfrim} label="Confirm Cancellation" fullWidth={true}/>
    </div>
  
    return {
      menuItem: itemConfig,
      toggleDialog, 
      dialog: (<Dialog closeButton={false} onClose={() => setIsDialogOpen(false)} height={200} width={400} open={isOpen} footer={dialogFooter}>
        <Inner item={item} />
      </Dialog>)
    }
  }
  const Inner = ({ height, item }: { height?: number, item: CancelSubscriptionArgs }) => {
    return <div className={clsx("flex items-center w-full")} style={{ height }}>
      <div className="flex-1">
        <Typography variant="p" >Are you sure you want to cancel <br className="hidden md:block"/>{`${item.description}?`}</Typography>
      
      </div>
    </div>
  
  }