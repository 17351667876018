import {
  Redirect,
} from 'react-router-dom';
import Categories from '../views/categories';
import Tickers from '../views/tickers';
import Basket from '../views/basket';
import Ticker from '../views/ticker';
import {default as BasketIndex, BasketIndex as Baskets} from '../views/basket-index/BasketIndex';
import Login from '../views/login';
import Category from '../views/category';
import Fantasy, { CreateFantasy } from '../views/fantasy';
import AboutUsNew from '../views/about-us-new';
import User from '../views/user';
import Settings from '../views/settings';
import Render404 from '../views/404/Render404';
import News from '../views/news';
import BasketEmbed from '../views/basket-embed';
import Tag from '../views/tag';
import Auth0Callback from '../components/auth-callback/Auth0Callback'
import GuestGuard from '../hoc/guest-guard'
import AuthGuard from '../hoc/auth-guard'
import Privacy, { LaunchPrivacy } from '../views/privacy/Privacy';
import TermsOfService, { LaunchTermsOfService } from '../views/terms-of-service/TermsOfService';
import Partners from '../views/partners/Partners';
import Careers from '../views/careers/Careers';
import Job from '../views/job/Job';
import { Routes } from './RenderRoutes';
import GainersLosers from '../views/gainers-losers/GainersLosers';
import FAQ from '../views/faq/FAQ';
import Unsubscribe from '../views/unsubscribe/Unsubscribe';
import NewsIndex from '../views/news/NewsIndex';
import Landing from '../views/landing';
import Chat from '../views/chat';
import FantasyLanding from '../views/fantasy-landing/FantasyLanding';
import Fantasies from '../views/fantasies/Fantasies';
import Devmode from '../views/dev-mode/Devmode';
import LaunchMode from '../views/launch-mode/LaunchMode';
import Prizedmode from '../views/prized-mode/Prizedmode';
import FantasyMode from '../views/fantasy-mode/FantasyMode';
import Billing from '../views/billing';
import Pricing from '../views/pricing';
import Topic from '../views/topic/Topic';
import { AnimateBasket } from '../views/animate-basket/AnimateBasket';
import UpgradeBasket from '../views/upgrade-basket/UpgradeBasket';
import Releases from '../views/releases/Releases';
import Docs from '../views/docs/Docs';


const routes: Routes = [
  {
    path: '/404',
    exact: true,
    component: Render404
  },
  {
    path: '/sneaky-potato',
    exact: true,
    component: Devmode
  },
  {
    path: '/launch-potato',
    exact: true,
    component: LaunchMode
  },
  {
    path: '/prized-potato',
    exact: true,
    component: Prizedmode
  },
  {
    path: '/fantasy-potato',
    exact: true,
    component: FantasyMode
  },
  {
    exact: true,
    path: '/login',
    guard: GuestGuard,
    component: Login
  },
  {
    exact: true,
    path: '/redirect',
    component: Auth0Callback
  },
  {
    path: '/rankings',
    exact: true,
    component: GainersLosers
  },
  {
    path: '/news',
    exact: true,
    component: NewsIndex
  },
  {
    path: '/landing/in-2050',
    exact: true,
    component: Landing
  },
  {
    path: '/about',
    exact: true,
    component: AboutUsNew
  },
  {
    path: '/categories',
    exact: true,

    component: Categories
  },
  {
    path: '/tickers',
    exact: true,

    component: Tickers
  },
  {
    path: '/stocks',
    exact: true,

    component: Tickers
  },
  {
    path: '/crypto',
    exact: true,

    component: Tickers
  },
  {
    path: '/etf',
    exact: true,

    component: Tickers
  },
  {
    path: '/unsubscribe/:list/:hash',
    exact: true,
    component: Unsubscribe
  },
  {
    path: '/news/:news',
    exact: true,
    component: News
  },
  {
    path: '/category/:category',
    exact: true,
    component: Category
  },
  {
    path: '/category/:category/:tag',
    exact: true,
    component: Tag
  },
  {
    path: '/topic/:topic',
    exact: true,
    component: Topic
  },
  {
    path: '/basket/create',
    exact: true,
    guard: AuthGuard,
    component: Basket
  },
  {
    path: '/tiktok',
    exact: true,
    component: FantasyLanding
  },
  {
    path: '/fantasy/create',
    exact: true,
    component: CreateFantasy
  },
  {
    path: '/fantasy',
    exact: true,
    component: Fantasies
  },
  {
    path: '/baskets',
    exact: true,
    component: Baskets
  },
  {
    path: '/fantasy/:fantasy/:round?/:subscribed?',
    exact: true,
    component: Fantasy
  },
  {
    path: '/basket/copy/:basket',
    exact: true,
    guard: AuthGuard,
    component: Basket
  },
  {
    path: '/basket/upgrade/:private_basket_id/:prompt',
    exact: true,
    guard: AuthGuard,
    component: UpgradeBasket
  },
  {
    path: '/basket/:basket',
    exact: true,
    component: Basket
  },
  {
    path: '/animate/basket',
    exact: true,
    component: AnimateBasket
  },
  {
    path: '/portfolio/:basket',
    exact: true,
    component: Basket
  },
  {
    path: '/basket/:basket/:embed',
    exact: true,
    component: BasketEmbed
  },
  {
    path: '/stocks/:ticker',
    exact: true,
    component: Ticker
  },
  {
    path: '/fund/:ticker',
    exact: true,
    component: Ticker
  },
  {
    path: '/crypto/:ticker',
    exact: true,
    component: Ticker
  },
  {
    path: '/etf/:ticker',
    exact: true,
    component: Ticker
  },
  {
    path: '/settings/:section?',
    exact: true,
    guard: AuthGuard,
    component: Settings
  },
  {
    path: '/users/:user/:section?',
    exact: true,
    component: User
  },
  {
    path: '/billing',
    exact: true,
    component: Billing
  },
  {
    path: '/pricing',
    exact: true,
    component: Pricing
  },
  {
    path: '/privacy',
    exact: true,
    component: Privacy
  },
  {
    path: '/terms-of-service',
    exact: true,
    component: TermsOfService
  },
  {
    path: '/releases',
    exact: true,
    component: Releases
  },
  {
    path: '/faq',
    exact: true,
    component: FAQ
  },
  {
    path: '/partners',
    exact: true,
    component: Partners
  },
  {
    path: '/careers',
    exact: true,
    component: Careers
  },
  {
    path: '/careers/:job',
    exact: true,
    component: Job
  },
  {
    path: '/partners/cdootr673jrosf4o1jm0',
    exact: true,
    component: Docs
  },
  {
    path: '/',
    exact: true,
    component: BasketIndex
  },
  {
    path: '*',
    component: () => <Redirect to={'/404'} />
  }
];

export const launch_routes = [   {
  path: '/404',
  exact: true,
  component: Render404
},{
  path: '/sneaky-potato',
  exact: true,
  component: Devmode
},{
  path: '/privacy',
  exact: true,
  component: LaunchPrivacy
},{
  path: '/terms-of-service',
  exact: true,
  component: LaunchTermsOfService
},{
  path: '*',
  component: () => <Redirect to={'/404'} />
}]

export default routes;
