import { ButtonVariant, FantasyDetailItemType, format, FormatEnum, GlobalUISizesEnum, Typography } from '@potato/components'
import clsx from 'clsx'
import React from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import usePotatoUser from '../../_contexts/potato-user-store/potato-user-hook'
import useShakeContext from '../../_contexts/shaker/shake-context-hook'
import { useJoinFantasy } from '../../_utils/use-join-fantasy'
import { usePaymentLink } from '../../_utils/use-payment-link'
import { useQueryParams } from '../../_utils/use-search-params'

export const EnterCta = ({ item }: { item: FantasyDetailItemType }) => {
    let queryParams = useQueryParams();
    const newbieParam = queryParams.get("newbie") || undefined
    const wrapperClass = 'flex space-x-4 md:justify-start mt-4 mb-6 items-center'
    const textClass = ''

    // paid
    if (item.is_paid) {
        // newbie
        if (newbieParam) {
            return <div className={clsx(wrapperClass, '')}>
                <div>
                    <Typography variant="p" className={clsx(textClass, '')}>
                        Payment Processing...
                    </Typography>
                </div>
            </div>
        } else {
            return <div className={clsx(wrapperClass)}>
                <div >
                    <Typography variant="p" className={clsx(textClass, 'text-purple')}>
                        This is prized league with a {format(item.selected_round.entry_fee!, FormatEnum.PriceWholeNumber)} entry fee to enter.
                    </Typography>
                </div>
                <div className='flex-0'>

                    <EnterCtaButton item={item} />
                </div>
            </div>
        }
    }

    // private
    // unpaid
    if (!item.is_public && !item.is_paid) {

        return <div className={clsx(wrapperClass)}>
            <div>
                <Typography variant="p" className={clsx(textClass, 'mt-4 mb-4')}>
                    You have been invited join this private league.
                </Typography>
            </div>
            <div>

                <EnterCtaButton item={item} />
            </div>
        </div>

    }


    // public
    // unpaid
    if (item.is_public && !item.is_paid) {
        return <div className={clsx(wrapperClass)}>
            <div className='xl:inline-block'>
                <Typography variant="p" className={clsx(textClass, '')}>
                    Welcome to this public league, free and open to everyone.
                </Typography>
            </div>
            <div className='xl:inline-block'>
                <EnterCtaButton item={item} />
            </div>
        </div>
    }

    return <></>
}

const PayButton = ({ className, size, item }: { className?: string, size?: GlobalUISizesEnum, item: FantasyDetailItemType }) => {
    const location = useLocation() 
    const { Shaker } = useShakeContext()
    const { user } = usePotatoUser()
    const { getPaymentLink } = usePaymentLink()
    const history = useHistory()
    const { pathname } = useLocation()
    const { data } = useQuery(['payment_link', item.id], getPaymentLink({ fantasy_id: item.id }), { enabled: !!user })
    const paymentClick = () => {
        if (!user) {
            if (!pathname.includes('unsubscribe')) {
                localStorage.setItem('returnTo', location.pathname)
            }
            history.push('/login')
        } else if (data && data.payment_link) {
            window.location.href = data.payment_link
        }
    }
    return <Shaker id={item.id}>
        <ButtonVariant.Cta
            onClick={paymentClick}

            externalLink={true}
            classes={{
                default: 'text-white  bg-purple whitespace-nowrap',
                active: 'text-white bg-purple',
                disabled: 'opacity-60 cursor-default',
                hover: 'hover:bg-yellow-light'
            }} spacingSizeClasses={{ [GlobalUISizesEnum.LARGE]: 'lg:px-8 px-6 py-1.5' }} className={className} size={size || GlobalUISizesEnum.LARGE} label={`Pay ${format(item.selected_round.entry_fee!, FormatEnum.PriceWholeNumber)}`} />
    </Shaker>
}

export const EnterCtaButton = ({ item, size }: { item: FantasyDetailItemType, size?: GlobalUISizesEnum }) => {
    const { joinFantasy } = useJoinFantasy()
    const { Shaker } = useShakeContext()
    const location = useLocation()
    const buttonClass = 'xl:-mr-0.5 font-semibold'
    // paid
    if (item.is_paid) {
        return <PayButton className={buttonClass} item={item} size={size} />
    }

    // private
    // unpaid
    if (!item.is_public && !item.is_paid) {
        return <Shaker id={item.id}>
            <ButtonVariant.Cta
                onClick={() => { joinFantasy({ private_slug: location.pathname }) }}
                spacingSizeClasses={{ [GlobalUISizesEnum.LARGE]: 'lg:px-8 px-6 py-1.5' }}
                className={buttonClass}
                size={size || GlobalUISizesEnum.LARGE}
                label={"Join"} /></Shaker>
    }

    // public
    // unpaid
    if (item.is_public && !item.is_paid) {
        return <Shaker id={item.id}>
            <ButtonVariant.Cta
                onClick={() => { joinFantasy({ private_slug: location.pathname }) }}
                spacingSizeClasses={{ [GlobalUISizesEnum.LARGE]: 'lg:px-8 px-6 py-1.5' }}
                className={buttonClass}
                size={size || GlobalUISizesEnum.LARGE}
                label={"Enter"} /></Shaker>
    }

    return <></>
}