import {
  DropdownButton,
  FantasyDetailItemType,
  GlobalUISizesEnum,
  useCopyToClipboard,
  useNotify,
} from "@potato/components";
import { useDelete } from "./use-delete";
import { useLeave } from "./use-leave-fantasy";
import { useHistory } from "react-router-dom";
import { ReactNode, useEffect } from "react";

const DropdownMenu: React.FC<{
  item: FantasyDetailItemType;
  className?: string;
}> = ({ item, className }) => {
  const { copyToClipboard, copied } = useCopyToClipboard();
  const { notify } = useNotify();
  useEffect(() => {
    if (copied) {
      notify({
        message: "Copied to clipboard",
        icon: "clipboard-check",
        delay: 5000,
        close: true,
      });
    }
  }, [copied]);

  const history = useHistory();
  const onLeaveCallback = () => {
    if(!item.is_public) {
      history.push("/");
    }
  };
  const inviteMenuItem = {
    label: "Invite to league via link",
    icon: "link",
    closeOnClick: true,
    onClick: () =>
      copyToClipboard(
        window.location.origin +
          (item.is_public ? item.slug : item.private_slug)
      ),
  };

  const { dialog: delete_dialog, menuItem: deleteMenuItem } = useDelete({
    item,
  });
  const { dialog: leave_dialog, menuItem: leaveMenuItem } = useLeave({
    item: {
      slug: item.slug,
      emoji: item.emoji,
      title: item.title,
      is_paid: !!item.is_paid,
    },
    onLeaveCallback,
  });

  let not_mine_menu: any[] = [leaveMenuItem];
  if (item.is_public) {
    not_mine_menu.push(inviteMenuItem);
  }

  return item.is_mine ? (
    <div className={className}>
      {delete_dialog}
      <DropdownButton
        size={GlobalUISizesEnum.LARGE}
        items={[deleteMenuItem, inviteMenuItem]}
        space={"px-4"}
        position={"right"}
      />
    </div>
  ) : (
    <div className={className}>
      {leave_dialog}
      <DropdownButton
        size={GlobalUISizesEnum.LARGE}
        items={not_mine_menu}
        space={"px-4"}
        position={"right"}
      />
    </div>
  );
};
export default DropdownMenu;
