import {
  ButtonVariant,
  Dialog,
  LineIcon,
  Typography,
  useToggle,
} from "@potato/components"
import { MenuListItemProps } from "@potato/components/lib/components/MenuListItem/MenuListItem"
import clsx from "clsx"
import { ValueOf } from "../../../_types/value-of"
import { useLeaveFantasy } from "../../../_utils/use-leave-fantasy"

export type LeaveFantasyArgs = {
  slug:string,
  is_paid:boolean,
  title:string,
  emoji:string
}

type Props = {
  onLeaveCallback?: () => void
  item: LeaveFantasyArgs,
}

export const useLeave = ({ item, ...props }: Props) => {
  const { leaveFantasy: leaveFantasy } = useLeaveFantasy()
  const { selected: isOpen, onChange: toggleDialog, setSelected: setIsDialogOpen } = useToggle(false)

  const onConfrim = async () => {
    await leaveFantasy({ slug: item.slug })
    setIsDialogOpen(false)
    if(props.onLeaveCallback) {
      props.onLeaveCallback()
    }
  }
  
  const itemConfig: ValueOf<Pick<MenuListItemProps, 'item'>> = {
    label: "Leave Fantasy",
    icon: "exit",
    value: "",
    onClick: toggleDialog,
    closeOnClick: true
  }

  const dialogFooter = <div className={'mt-4 flex space-x-4'}>
    <ButtonVariant.Pill onClick={toggleDialog} label="Cancel"  iconLeft={<LineIcon icon="cross" className="flex items-center" />} fullWidth={true}/>
    <ButtonVariant.Cta onClick={onConfrim} label="Leave" fullWidth={true}/>
  </div>

  return {
    menuItem: itemConfig,
    toggleDialog, 
    dialog: (<Dialog closeButton={false} onClose={() => setIsDialogOpen(false)} height={!!item.is_paid ? 260 : 200} width={300} open={isOpen} footer={dialogFooter}>
      <Inner item={item} />
    </Dialog>)
  }
}
const Inner = ({ height, item }: { height?: number, item: LeaveFantasyArgs }) => {
  return <div className={clsx(!!item.is_paid ? 'text-left': 'text-center', "flex items-center w-full")} style={{ height }}>
    <div className="flex-1">
      <Typography variant="p" >Are you sure you want to leave <br className="hidden md:block"/>{`${item.emoji} ${item.title}?`}</Typography>
      {!!item.is_paid && (
        <Typography variant="p" className="mt-4">
          Your subscription will be cancelled and you will be ineligible for
          prizes in the next round. You can re-join this league as a paid entry again.
        </Typography>
      )}
    </div>
  </div>

}