import { CategoryList, Container, Typography, Loading } from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import { CategoriesPageResponse } from "../../_api/pages/categories";
import React from "react"
import PageMeta from "../../components/page-meta";
import {
  useQuery,
} from 'react-query'

const Categories: React.FC = () => {
  const { apiLoading, ...api } = useApi()

  const getCategories = api.get({ url: 'pages/categories' })
  const _categoryResponse = useQuery('categories-index', getCategories, {
    enabled: !apiLoading
  })
  const data = _categoryResponse.data  as CategoriesPageResponse

  if(_categoryResponse.isLoading) {
    return <Loading className="mt-32" />
  }
  if(!data) {
    return <div/>
  }
  (window as any).prerenderReady = true;
  return (
    <Container>
      <PageMeta data={data.meta_tags} defaultData={{ title: "Basket Cetegories | Potato" }} />
      <div className="flex space-x-8">
        <div className="w-full">
          <Typography variant="h2" element="h1" className="text-center my-8">Categories</Typography>
          <CategoryList className='max-w-5xl m-auto mb-8' items={data.categories || []} />
        </div>
      </div>
    </Container>
  )

}

export default Categories