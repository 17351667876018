import {
  Typography,
  HoldingListItem,
  HoldingType,
  Loading,
  usePageStatus,
  LineIcon,
  useEditAllocation,
  useCurrentBreakpointName,
  FilterTypeEnum
} from "@potato/components"
import Scrollbars from "react-custom-scrollbars";
import { debounce } from 'lodash'
import React, { CSSProperties, useCallback, useEffect, useState } from "react"
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from 'react-query'
import InfiniteLoader from 'react-window-infinite-loader'
import { Transition } from '@headlessui/react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import clsx from "clsx";
import { HeaderRow } from "./TickerResultsHeader";

export type TickerResultsProps = {
  className?: string
  items: HoldingType[]
  isLoading: boolean
  hasNextPage?: boolean
  headerHeight?: number
  selectable?: boolean,
  filter_type: FilterTypeEnum
  onScroll?:() => void
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<any, unknown>>
}
export const TickerResults = ({ filter_type,items, isLoading, fetchNextPage, hasNextPage, className, selectable, headerHeight, onScroll }: TickerResultsProps) => {
  const listRef = React.createRef<HTMLDivElement>()
  const ref = React.createRef<HTMLDivElement>()
  const { selectedHoldings,canDelete, deleteItem, addItem } = useEditAllocation()
  const [top, setTop] = useState(0)
  const { scrollToTop } = usePageStatus()
  const [isBottom, setIsBottom] = useState(false)
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const filter_type_hidden_properties: {[K in FilterTypeEnum]:  Array<keyof HoldingType>} = {
    [FilterTypeEnum.all]:['ticker_type', 'allocation'],
    [FilterTypeEnum.stocks]: ['ticker_type', 'allocation'],
    [FilterTypeEnum.etf]: ['ticker_type', 'allocation'],
    [FilterTypeEnum.crypto]: ['ticker_type', 'allocation', 'exchange_description', 'sector']
  }
  let hideProperties = filter_type_hidden_properties[filter_type]
  const callOnSclroll = useCallback(debounce((top:number) => {
    if(top > 50) {
      onScroll && onScroll()
    }
  }, 500), [debounce])

  useEffect(() => {
    onScroll && callOnSclroll(top)
  }, [top])

  const itemCount = hasNextPage ? items.length + 1 : items.length;
  if(!isLarge) {
    hideProperties = hideProperties.concat(['sector','exchange_description','market_cap', 'movement_1y'])
    if(selectable) {
      hideProperties = hideProperties.concat(['price'])
    }
  }
  if(selectable) {
    hideProperties = hideProperties.concat(['movement_1y','exchange_description', 'sector'])
  }
  // const hide_properties: Array<keyof HoldingType> = isLarge ? hideProperties || [] : [...(hideProperties||[]), ]
  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isLoading ? () => { } : () => fetchNextPage();

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  // Render an item or a loading indicator.
  const Item = ({ index, style }: { index: number, style: CSSProperties }) => {

    if (!items[index]) {
      return <div style={style} className="flex items-center justify-center"><Loading className="mt-32 text-sm text-divider" /> </div>
    }

    const onChange = (item: HoldingType, selected: boolean) => {
      if (selected) {
        addItem(item)
      } else {
        deleteItem(item)
      }
    }

    const item = items[index] as HoldingType & { sector: string }
    const selected = selectedHoldings.find(_item => _item.ticker_id === item.id)
    const enableDelete = canDelete(item)
    return <div key={item.id} style={style}>
      {selectable
        ? <HoldingListItem disabled={!enableDelete} onChange={onChange} key={item.id} item={item} selected={!!selected} hideProperties={
          hideProperties
        } />
        : <HoldingListItem clickable={true} item={item} hideProperties={hideProperties} />
      }
    </div>
  };

  const handleScroll = ({ target }: any) => {
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (listRef) {
      (listRef as any).current.scrollTo(scrollTop);
      setIsBottom(scrollTop + clientHeight === scrollHeight)
    }
    setTop(scrollTop);
  };

  const _scrollToTop = () => {
    if (ref && ref.current) {
      (ref.current as any).view.scroll({
        top: 0,
      })
    }
    scrollToTop()
  }

 

  return <div className={clsx(className, 'flex flex-col h-full relative z-10')}>
    <div className="flex-0">
    <div className={clsx(!selectable && "px-4 lg:px-8","lg:max-w-7xl lg:mx-auto h-full")}>
      <div onClick={_scrollToTop}><HeaderRow hideProperties={hideProperties} height={headerHeight} selectable={selectable} /></div>
      </div>
    </div>
    <div className="flex-1">
    <AutoSizer>{({ height: _height, width: _width }) => {
      return <div className="relative bg-white" style={{ height: _height, width: _width }}>
        <div className={clsx(!hasNextPage && isBottom && "hidden", "absolute w-full h-14 bg-gradient bg-gradient-to-t from-white bottom-0 z-10")} />
        <div
          onClick={_scrollToTop}
          className={clsx(
            !hasNextPage && isBottom && "hidden",
            top > 50 ? 'opacity-100' : 'opacity-0',
            !selectable && "lg:hidden",
            "transition-opacity duration-200 rounded-full bg-black  ring-2 ring-offset-1 ring-black text-white flex items-center justify-center shadow-md z-20 absolute cursor-pointer"
          )}
          style={{ bottom: 10, right: 10, height: 40, width: 40 }}>
          <LineIcon icon="chevron-up" className="text-xl" />
        </div>
        <Scrollbars ref={ref as any} autoHide={true} height={_height} onScroll={handleScroll}>
          <div className={clsx(!selectable && "px-4 lg:px-8","lg:max-w-7xl lg:mx-auto h-full")}>
          <Transition
            show={!items.length && !isLoading}
            as={React.Fragment}
            leave="transition ease-in duration-100"
            enter="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="h-20 flex items-center justify-center">
              <Typography>No tickers found</Typography>
            </div>
          </Transition>
          <Transition
            show={isLoading && !items.length}
            as={React.Fragment}
            enter="transition ease-in duration-400 "
            leave="transition ease-in duration-400 "
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div><Loading className="mt-12" /> </div>
          </Transition>

          <AutoSizer>{({ height: __height, width: __width }) => {
          return <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={listRef as any}
                itemSize={56}
                width={__width}
                height={__height}
                style={{ overflow: false, position: 'relative' } as any}
              >
                {Item}
              </FixedSizeList>
            )}
          </InfiniteLoader>
          }}</AutoSizer>
          </div>
        </Scrollbars>
      </div>
    }}</AutoSizer>
  </div>
  </div>
}