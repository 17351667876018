import { ContentBlock } from "draft-js";
import { Strategy_Callback } from "./types";

export const findWithRegex = (regex:RegExp, contentBlock:ContentBlock, callback:Strategy_Callback) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

