
  import {
    ButtonVariant,
    Dialog,
    FantasyDetailItemType,
    LineIcon,
    Typography,
    useToggle,
  } from "@potato/components"
  import { useDeleteFantasy } from "../../../_utils/use-delete-fantasy"
  import { useHistory } from "react-router-dom"
import { ValueOf } from "../../../_types/value-of"
import { MenuListItemProps } from "@potato/components/lib/components/MenuListItem/MenuListItem"

  export const useDelete = ({ item }: { item:  FantasyDetailItemType}) => {
    const { deleteFantasy } = useDeleteFantasy()
    const { selected: isOpen, onChange: toggleDialog, setSelected: setIsDialogOpen } = useToggle(false)
    const history = useHistory()
  
    const onConfrim = async () => {
      await deleteFantasy({ id: item.id })
      setIsDialogOpen(false)
      history.push('/')
    }
    const itemConfig: ValueOf<Pick<MenuListItemProps, 'item'>> = {
        onClick: toggleDialog,
        label: 'Delete',
        icon: 'trash2',
        value: '',
        closeOnClick: true
      }
      const dialogFooter = <div className={'mt-4 flex space-x-4'}>
      <ButtonVariant.Pill onClick={toggleDialog} label="Cancel" iconLeft={<LineIcon icon="cross" className="flex items-center" />} fullWidth={true}/>
      <ButtonVariant.Cta onClick={onConfrim} label="Delete" fullWidth={true}/>
    </div>
    return {
        menuItem:itemConfig,
        dialog: ( <Dialog closeButton={false} onClose={() => setIsDialogOpen(false)} height={200} width={300} open={isOpen} footer={dialogFooter}>
     <Inner item={item}/>
    </Dialog>)
    }
  }

  const Inner = ({ height, item }: { height?: number, item:FantasyDetailItemType }) => {
    return <div className="flex items-center text-center w-full" style={{ height}}> 
    <div className="flex-1">
      <Typography variant="p">Are you sure you want to delete <br className="hidden md:block"/>{`${item.emoji} ${item.title}?`}</Typography>
    </div>
    </div>
  
  }