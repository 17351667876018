import React from "react";
import { BasketsSectionItem, BasketsSectionType } from "../../../_api/pages/home";
import GainersLosers from './GainersLosers'
import Baskets from './Baskets'
import News from './News'
import Categories from './Categories'
import Tags from './Tags'

export interface SectionProps  {
  item: BasketsSectionItem
};

const SectionComponents = {
  [BasketsSectionType.GainersLosers]: GainersLosers,
  [BasketsSectionType.Baskets]: Baskets,
  [BasketsSectionType.News]: News,
  [BasketsSectionType.Categories]: Categories,
  [BasketsSectionType.Tags]: Tags,
}
/**
 * Primary UI component for user interaction
 */
const Section = ({
 item
}: SectionProps) => {
  if (typeof SectionComponents[item.section_type] !== "undefined") {
    return React.createElement(SectionComponents[item.section_type], {
      item,
      key: item.slug
    });
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div className="text-center">The component "{item.section_type}" has not been created yet.</div>,
    { key: item.slug }
  );
};

export default Section;