import {
  GlobalUISizesEnum,
  Typography,
  ButtonVariant,
  Breakpoint,
  RankingType,
  MovementChip,
  Avatar,
  FantasyDetailItemType,
} from "@potato/components";
import clsx from "clsx";
import "./pulse.scss";
import React from "react";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import useShakeContext from "../../_contexts/shaker/shake-context-hook";
import { is_member } from "./PaymentGuard";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";

export const FixedBottomBar: React.FC<{
//   ranking: RankingType;
  item: FantasyDetailItemType,
  className?: string;
}> = ({ item, className }) => {
  const { setPageMode } = usePageMode();
  const { shakeit } = useShakeContext()
  const {user} = usePotatoUser()
  const onAddHoldingsClick = () => {
    !is_member(item) ? shakeit() : setPageMode('edit')
  }
   const ranking = item.rankings && item.rankings.find((r) => r.is_mine);
  return  (
    <Breakpoint medium down>
      <div className={clsx("h-16", className)} />
      <div className="fixed bottom-0 left-0 px-4 py-2 w-full bg-white top-shadow">
        <div className="flex items-center space-x-3">
          <div className="flex-0">
            <Avatar
              size={GlobalUISizesEnum.MEDIUM}
              avatar={user? user.picture : undefined}
              color={ranking? ranking.color: undefined}
            />
          </div>
          <div className="flex-1">
            <Typography variant="p" className="font-semibold">
              <MovementChip arrow={true} movement={ranking && !!ranking.roi ? ranking.roi : 0} />
            </Typography>

            {ranking && ranking.holdings && !!ranking.holdings.length ? (
              <Typography className="text-secondary -mt-1">
                Ranked {ranking.ranking}{" "}
              </Typography>
            ) : (
              <Typography className="text-secondary -mt-1">
                <span className="underline" onClick={onAddHoldingsClick}>
                  Add Holdings
                </span>{" "}
                to enter{" "}
              </Typography>
            )}
          </div>
          <div className="flex-0">
            <ButtonVariant.Outline
              iconLeft={
                !!item.comment_count ? (
                  <span className="bg-black rounded-full px-2 py-1 -ml-2 text-white text-sm">
                    {item.comment_count}
                  </span>
                ) : undefined
              }
              label="Chat"
              onClick={() => {
                setPageMode("chat");
              }}
            />
          </div>
        </div>
      </div>
    </Breakpoint>
  );
};
