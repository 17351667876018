import React from "react";
import {
  ButtonVariant as Button,
  LineIcon,
  MenuList,
  Typography,
  useCurrentBreakpointName,
  UserDetailType,
} from "@potato/components";
import { Link, useLocation } from "react-router-dom";
import { UserMenu } from "./UserMenu";

export interface SidebarContentProps {
  user?: UserDetailType | null;
  logout: () => void;
}


const SidebarContent = ({ user, logout }: SidebarContentProps) => {
  const { pathname } = useLocation();
  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);
 
  const fantasyMode = localStorage.getItem("potato_fantasy_mode") === "fantasy";

  const menuItems = [
      fantasyMode && {
        label: "Fantasy",
        icon: "trophy",
        closeOnClick: !isLarge,
        active: pathname === "/fantasy",
        value: "/fantasy",
      },
        {
          label: "Home",
          icon: "home",
          closeOnClick: !isLarge,
          active: pathname === "/",
          value: "/",
        },
        {
          label: "Rankings",
          icon: "trophy",
          closeOnClick: !isLarge,
          active: pathname === "/rankings",
          value: "/rankings",
        },
        {
          label: "Stocks, ETFs, Crypto",
          icon: "magnifier",
          closeOnClick: !isLarge,
          active: pathname === "/stocks",
          value: "/stocks",
        },
        {
          label: "Categories",
          icon: "library",
          closeOnClick: !isLarge,
          active: pathname === "/categories",
          value: "/categories",
        },
        !!user && {
          label: "Billing",
          icon: "credit-card",
          value: "/billing",
        },
        {
          label: "Pricing",
          icon: "tags",
          value: "/pricing",
        },
      
        !!user && {
          label: "Settings",
          icon: "cog",
          closeOnClick: !isLarge,
          active: pathname === "/settings",
          value: "/settings",
        },
        !isLarge && 'divider',
        !isLarge && {
          label: "About",
          value: "/about",
        },
        !isLarge && {
          label: "FAQ",
          value: "/faq",
        },
        !isLarge && {
          label: "Terms of Service",
          value: "/terms-of-service",
        },
        !isLarge && {
          label: "Privacy Policy",
          value: "/privacy",
        },
        
      ];

 /*  <Typography href="/faq" className="leading-7">
                  FAQ
                </Typography>
                <Typography href="/terms-of-service" className="leading-7">
                  Terms of Service
                </Typography>
                <Typography href="/privacy" className="leading-7">
                  Privacy Policy
                </Typography>*/
  return (
    <div className="flex flex-col h-full">
      {isLarge && user && <UserMenu logout={logout} user={user}/>}
      <MenuList
        className="flex-0 mt-8"
        isCard={false}
        items={[
          isLarge && "divider",
          ...menuItems,
          isLarge && "divider",
        

         isLarge && <div className="px-6 pb-3">
            {" "}
            <Button.Outline
              iconLeft={<LineIcon className="block" icon="exit-right" />}
              className="w-full text-left mt-1"
              label="Log Out "
              onClick={logout}
            />
          </div>,
          <div className="bg-white light:bg-black light:bg-opacity-5 bg-opacity-5 rounded mx-5 p-4 mb-6 mt-4">
            <Typography variant="p" className="text-shadow text-primary">
              Need Help?
            </Typography>
            <Typography variant="small" className="text-secondary">
              Check out our{" "}
              <Link to="/faq" className="text-primary underline">
                FAQ
              </Link>{" "}
              page or{" "}
              <a
                href="mailto:hello@potato.trade"
                className="text-primary underline whitespace-nowrap"
              >
                contact us
              </a>
            </Typography>
          </div>,
        ]}
      />
    </div>
  );
};

export default SidebarContent;
