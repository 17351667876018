import React from 'react'
import { Container } from "@potato/components"
import PageMeta, { StatusTag } from '../../components/page-meta'
import { Link } from 'react-router-dom';

const Render404 = () => {
  (window as any).prerenderReady = true;
  return <Container className='text-left'>
     <PageMeta defaultData={{title:'Page not found'}}/>
     <StatusTag status={404}/>
     <p className='text-5xl' style={{marginTop:'20%'}}>👻 </p>
    <h1 className='text-2xl font-medium'>We can't seem to find the page you're looking for.</h1>
    <p className='text-secondary opacity-50 text-lg'>Error code: 404</p>
    <p className='text-lg mt-4 text-secondary'>Here are some helpful links instead:</p>
    <Link to="/" className='text-primary mt-1 text-lg '>Home</Link><br/>
  <Link to="/rankings" className='text-primary mt-12 text-lg '>Rankings</Link><br/>
    <Link to="/categories" className='text-primary mt-12 text-lg '>Categories</Link>
  </Container>
}

export default Render404