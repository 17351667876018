import React, { useRef } from "react"
import {
    useTruncate,
    Typography,
    BasketCardList,
    Tabs,
    GlobalUISizesEnum,
    BotBadge,
    Avatar,
    Truncate,
    BasketItem,
    PotatoPlus,
  } from "@potato/components"
  import { FollowersDialog } from "./FollowersDialog";
  import { FollowingDialog } from "./FollowingDialog";
  import PageMeta from "../../components/page-meta";
  import { UserPageResponse } from "../../_api/pages/user";
  import { useSectionParam } from '../../_utils/use-section-param'
  import { useFollow } from "../../_utils/use-follow"
  import { useWatch } from "../../_utils/use-watch";
  import PageMode from "../basket/page-mode/PageMode";
  import usePageMode from "../../_contexts/page-mode/page-mode-hook";
  
  const sections = ['published_baskets', 'private_baskets', 'watching']
  
  type UserLayoutProps = {
    item: UserPageResponse
  }
  const UserLayout = ({ item: user }: UserLayoutProps) => {
    const { watchFn } = useWatch()
    const { onChange, activeItem } = useSectionParam(sections, 'published_baskets')
    const { setPageMode } = usePageMode()
    const ref = useRef<HTMLDivElement>()
    const [truncate_published_baskets_button, truncated_published_baskets] = useTruncate((user && user.baskets_published) || [], 12)
    const [truncate_private_baskets_button, truncated_private_baskets] = useTruncate((user && user.baskets_private) || [], 12)
    const [truncate_watched_baskets_button, truncated_watched_baskets_items] = useTruncate((user && user.baskets_watched) || [], 12)
    const { followerCount, followButton } = useFollow(user ? user.is_following : false, user ? user.follower_count : 0, user ? user.id : undefined);
  
    const tabs:{
      icon?: JSX.Element;
      label: string;
      value: string;
  }[] = [{
      icon: <></>,
      label: 'AI Generated',
      value: 'published_baskets'
    }, ]
    if (!user.is_bot) {
      tabs.splice(1, 0, {
        label: 'Watching',
        value: 'watching',
      })
    }
    if (user.is_mine) {
      tabs.splice(1, 0, {
        label: 'Private',
        value: 'private_baskets'
      })
    }
    const scrollto = () => {
      if (ref && ref.current) {
        const { top } = ref.current.getBoundingClientRect();
        window.scrollTo({ top: top - 70, behavior: 'smooth' })
      }
    }
    return <>
      <PageMode pageModes={['followers']}>
        <FollowersDialog user={user} />
      </PageMode>
      <PageMode pageModes={['following']}>
        <FollowingDialog user={user} />
      </PageMode>
  
      <PageMode pageModes={['main']}>
        <div className="pt-14 pb-14 px-4 md:px-8 mx-auto max-w-4xl">
          <PageMeta data={user.meta_tags} defaultData={{ title: user.username + ' Profile page | Potato' }} />
  
          <Avatar alt={user.username} size={GlobalUISizesEnum.XXLARGE} avatar={user.picture} className="mx-auto" />
          {user && !user.is_mine && <div className="flex items-center justify-center -mt-2 ">{followButton}</div>}
  
          <div className="text-center mt-8 mb-3 flex items-center justify-center">
            {/* {!!user.official && !user.bot && <Badge className="mr-1" />} */}
            {!!user.is_bot && <BotBadge className="mr-1" size={GlobalUISizesEnum.MEDIUM} />}
            <Typography variant="h2" className="leading-6">{user.username}</Typography>
            {!!user.is_potato_plus && <PotatoPlus className="ml-2" size={GlobalUISizesEnum.LARGE} /> }
          </div>
          <Typography className="mb-4 text-center"><Truncate text={user.bio} truncate={140} /></Typography>
          <div className="flex text-center justify-center items-center divide-x divide-pill-hover mt-8">
            <div className="flex-0 px-3">
              <p onClick={followerCount ? () => setPageMode('followers') : undefined}>
                <Typography element="span" className="text-secondary cursor-pointer hover:text-primary">{followerCount} Follower{followerCount === 1 ? '' : 's'}</Typography>
              </p>
            </div>
            <div className="flex-0 px-3">
              <p onClick={user.following_count ? () => setPageMode('following') : undefined}>
                <Typography element="span" className="text-secondary cursor-pointer hover:text-primary">{user.following_count} Following</Typography>
              </p>
            </div>
            <div className="flex-0 px-3">
              <p onClick={scrollto}>
                <Typography element="span" className="text-secondary">{user.basket_count} Basket{user.basket_count === 1 ? '' : 's'}</Typography>
              </p>
            </div>
          </div>
  
  
          <Tabs ref={ref as any} onChange={onChange} className="mt-14 mb-8" align="center" activeItem={activeItem} items={tabs} />
          {activeItem === 'published_baskets' && (
            <>
              {!truncated_published_baskets.length && <>
                <Typography className="text-primary mt-8 text-center" variant="h4">No baskets yet</Typography>
                <Typography className="text-secondary mt-4 text-center" variant="p">{user.is_mine ? `When you create and publish baskets, you can find them here.` : `When ${user.username} publishes baskets you will see them here.`}</Typography>
              </>}
              <BasketCardList itemClassName="md:w-1/3 sm:w-1/2 w-full" center={true} onWatch={watchFn} className="mt-5" items={truncated_published_baskets as any[]} />
              {truncate_published_baskets_button}
            </>
          )}
          {activeItem === 'private_baskets' && (
            <>
              {!truncated_private_baskets.length && <>
                <Typography className="text-primary mt-8 text-center" variant="h4">No baskets yet</Typography>
                <Typography className="text-secondary mt-4 text-center" variant="p">When you create private or draft baskets, you can find them here.</Typography>
              </>}
              <BasketCardList itemClassName="md:w-1/3 sm:w-1/2 w-full" center={true} onWatch={watchFn} className="mt-5" items={(truncated_private_baskets as BasketItem[]).map((b => ({...b, picture: undefined})))} />
              {truncate_private_baskets_button}
            </>
          )}
          {activeItem === 'watching' && (
            <>
              {!truncated_watched_baskets_items.length && <>
                <Typography className="text-primary mt-8 text-center" variant="h4">No baskets yet</Typography>
                <Typography className="text-secondary mt-4 text-center" variant="p">{user.is_mine ? `When you watch baskets, you can find them here.` : `When ${user.username} watches baskets you will see them here.`}</Typography>
              </>}
              <BasketCardList itemClassName="md:w-1/3 sm:w-1/2 w-full" center={true} onWatch={watchFn} className="mt-5" items={truncated_watched_baskets_items as any[]} />
              {truncate_watched_baskets_button}
            </>
          )}
  
        </div>
      </PageMode>
    </>
  }
  export default UserLayout