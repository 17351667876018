import { useThemeContext } from '@potato/components';
import React, { useCallback, useEffect, useState } from 'react'
import { useCurrentBreakpointName } from 'react-socks';
import { Strategy } from "../types";
import { findWithRegex } from "../utils";

const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

export const urlStrategy: Strategy = (contentBlock, callback, contentState) => {
  findWithRegex(URL_REGEX, contentBlock, callback);
}

export const RichTextUrl = ({ children, decoratedText, ...props }: { children: React.ReactNode, decoratedText: string }) => {
  const [html, setHtml] = useState<any>()
  const name = useCurrentBreakpointName()
  const {isDark} = useThemeContext()
  const isLarge = ['large', 'xlarge'].includes(name)

  const fetchEmbed = useCallback(async (url: string) => {
    try {
      const response = await fetch(
        `https://iframe.ly/api/iframely?url=${url}&api_key=f924604546485eb037bfa3&omit_script=1&theme=${isDark ? 'dark':'light'}&card=small&omit_css=1`
      );
      const data = await response.json();
      setHtml(data.html);
    } catch (error) {
      console.error('error', error);
      return error;
    }
  }, [setHtml, isLarge])
  useEffect(() => {
    (window as any).iframely && (window as any).iframely.load();
  }, [])

  useEffect(() => {
    fetchEmbed(decoratedText)
  }, [decoratedText, isLarge])

  return <><div className={html ? 'pb-2' :  "inline-block"}>
    <a href={decoratedText} className="text-underline text-primary " target="_blank">{children}</a>
    {!!html && <div dangerouslySetInnerHTML={{ __html: html }} className="bg-white pt-1" /> }
  </div>
    </>
}