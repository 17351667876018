import { useState } from "react";
import useApi from '../../_contexts/api/useApi'
import { api_routes } from "../../_api/api-routes";
import { useQueryClient } from "react-query";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";

export const useCancelSubscription = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const api = useApi()
  const queryClient = useQueryClient()
  const { user } = usePotatoUser()

  const cancelSubscription = async (data: { id: string }) => {
    setLoading(true)
    await queryClient.invalidateQueries(data.id, { refetchActive:false })
    const response = await api.post({
      url: api_routes.actions.cancel_subscription,
      data
    })()
    if(response.status === "success") {
      queryClient.refetchQueries('me')
      queryClient.refetchQueries(`billing-${user ? user.id : ''}`)
    }
    setLoading(false)
  }

  return { cancelSubscription, loading };
}