import { Avatar, GlobalUISizesEnum, MovementChip, Typography } from '@potato/components'
import clsx from 'clsx'
export const renderBenchmarkOption = (clearField: () => void) => (option: any & {label:string, value:string}, args: { checked:boolean, disabled:boolean, active:boolean }) => {
  return <div onClick={() => {console.log({value: option.value, checked: args.checked});if(args.checked) { clearField() }}} className={clsx(
    "focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-25 focus-visible:ring-offset-2 focus-visible:ring-offset-white transition-all ease-in-out duration-150",
    args.disabled ? 'cursor-default' : 'cursor-pointer',args.checked ? "border-black border" : "hover:border-divider border border-[#00000000]"," p-1 rounded-full mx-1 my-1 focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-25 focus-visible:ring-offset-2 focus-visible:ring-offset-white")}>
    <div className="flex items-center space-x-3">
      <Avatar size={GlobalUISizesEnum.XLARGE} avatar={option.picture}/>
      <div className='flex-1'>
        <Typography variant="p" className='font-bold leading-none'>{option.label}</Typography>
        <Typography className='text-secondary'>{option.stack_description}</Typography>
      </div>
      <div className='flex-0'>
      <MovementChip className='mr-2 block font-bold' size={GlobalUISizesEnum.SMALL} movement={option.movement_7d} timeperiod={'7d'} />
      </div>
    </div>
  </div>
}