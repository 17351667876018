import React, { useEffect, useState } from "react";
import {
  useEditAllocation,
  LineIcon,
  AllocationTypeEnum,
  Typography,
  useCurrentBreakpointName,
  SelectDropdown,
  FilterOption,
} from "@potato/components"
import clsx from "clsx";

type AllocationDropdownProps = {

}
const AllocationDropdown = ({
}: AllocationDropdownProps) => {
  const { allocation_method, handleAllocationMethodChange } = useEditAllocation()

  const _handleChange = (val:Array<FilterOption>) => {
    handleAllocationMethodChange(val[0].value as AllocationTypeEnum)
  }
  const allocationOptions = [{
    title: 'Even',
    value: AllocationTypeEnum.EVEN,
    icon: 'balance',
    secondaryText: 'Allocations are distributed evenly. '
  }, {
    title: 'Custom',
    value: AllocationTypeEnum.CUSTOM,
    icon: 'equalizer',
    secondaryText: 'Allocations are manually adjusted.'
  }]

  const RenderOption = ({ val }: { val: FilterOption & { [x: string]: string }, selected: boolean, active: boolean }) => {
    const selected = allocation_method === val.value
    return <div className="flex items-center space-x-2 h-16">
      <div className="flex-0 w-6">
        {val.icon && <LineIcon icon={selected ? "check" : val.icon} className="mt-6" />}
      </div>
      <div className="flex-1">
        <Typography className={clsx(selected ? "font-semibold": "font-normal", "text-primary uppercase text-sm")}>{val.title}</Typography>
        {val.secondaryText && <Typography variant="small" className="text-secondary">{val.secondaryText}</Typography>}
      </div>
     
    </div>
  }
  const allocationValue = allocationOptions.filter(option => option.value === allocation_method)
  const allocationButtonProps = {
    placeholder: "Select Method",
    options: allocationOptions,
    multiple: false,
    value:allocationValue,
    onChange: _handleChange,
    align:'right' as 'right',
    updateLabel:true,
    RenderOption,
    optionHeight:80,
    optionWidth: 250
  }

  return <div className="flex items-center space-x-2 justify-start flex-1 pb-1">
    <Typography variant="span" className="text-secondaryc flex-0">Allocation</Typography>
    <div className="flex-1"><div style={{maxWidth:200}}><SelectDropdown {...allocationButtonProps} /></div></div>
  </div>
}

export default AllocationDropdown