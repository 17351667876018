import {
  TickerFilters,
  FilterTypeEnum,
  Typography,
  Loading,
  useCurrentBreakpointName,
  useToggle,
  HoldingType,
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import React, { useEffect } from "react"
import {
  useQuery,
} from 'react-query'
import { TickersPageResponse } from "../../_api/pages/tickers";
import PageMeta from "../../components/page-meta"
import { useLocation } from "react-router-dom";
import { useTickerSearch } from "./use-ticker-search";
import { TickerResults, TickerResultsProps } from "./TickerResults";
import { NoFooter } from "../../components/no-footer/NoFooter";

const Tickers: React.FC = () => {
  const api = useApi()
  const location = useLocation()
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const { selected: filtersOpen, onChange: toggleFilters, setSelected } = useToggle(false)
  const isXLarge = name === 'xlarge'

  const getType = () => {
    switch (location.pathname.replaceAll('/', '')) {
      case 'stocks':
        return  FilterTypeEnum.stocks
      case 'etf':
        return  FilterTypeEnum.etf
      case 'crypto':
        return FilterTypeEnum.crypto
      default:
        return FilterTypeEnum.all
    }
  }
  const _type = getType()

  const routeProps = {
    [FilterTypeEnum.all]: {url: 'pages/tickers', title: 'Tickers'},
    [FilterTypeEnum.stocks]: {url: 'pages/stocks', title: 'Stocks'},
    [FilterTypeEnum.etf]: {url: 'pages/etf', title: 'ETFs'},
    [FilterTypeEnum.crypto]: {url: 'pages/crypto', title: 'Crypto'}
  }
  const getTickersPage = api.get({ url: routeProps[_type].url, waitForAuth: false })
  const { data: tickerPageData, isLoading: tickerPageLoading } = useQuery(location.pathname, getTickersPage)
  const tickersPage = tickerPageData as TickersPageResponse
  
    const { isLoading, hasNextPage, fetchNextPage, items, setFilters, type } = useTickerSearch({ type: _type, filters_by_type: tickersPage ? tickersPage.filters : [] })

  if (tickerPageLoading) {
    return <NoFooter><div className="relative z-10"><Loading className="mt-32" /></div></NoFooter>
  }
  if (!tickersPage) {
    return <div />
  }
  (window as any).prerenderReady = true;

  const resultListProps: TickerResultsProps = {
    isLoading, hasNextPage, fetchNextPage, items, filter_type: type
  }

  return <NoFooter>
    <div
      className="transition-opacity fixed lg:relative right-0 lg:top-0 top-[56px] h-full w-full bg-white z-10 "
      style={{ height: isLarge ? `calc(100vh - 106px)` : `calc(100% - 56px)` }}
    >
      <div className="flex flex-col h-full relative ">
      <div className="flex-1 order-last">
          <TickerResults onScroll={() => { setSelected(false)}}  className="h-full" {...resultListProps} />
        </div>
        <div className='flex-0 lg:pt-8'>
        <div className="px-4 lg:px-8 lg:max-w-7xl lg:mx-auto">
          <PageMeta data={tickersPage.meta_tags} defaultData={{ title: "Tickers Index", description: "Browse and search for Stocks, ETFs and Crypto available on Potato" }} />
          <Typography variant="h2" element="h1" className="lg:mb-4 mb-1 text-center">{routeProps[type].title}</Typography>
          <TickerFilters isOpen={filtersOpen} onOpenChange={toggleFilters} layout={!isXLarge ? 'center' : 'full'} currentType={type || FilterTypeEnum.all} filters={tickersPage.filters} onChange={setFilters} />
        </div>
        </div>
      
      </div>
    </div>
  </NoFooter>
}

export default Tickers

