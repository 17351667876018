import {
  Loading,
  BasketItemDetail,
  Container,
  ButtonVariant as Button,
  LineIcon,
  Input,
  GlobalUISizesEnum,
  AnimatedText,
  CreateBasketInput,
  EmojiTitleAnimated,
  HoldingsListMobile,
  useCurrentBreakpointName,
} from "@potato/components";
import { TypeAnimation } from "react-type-animation";
import useApi from "../../_contexts/api/useApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NoFooter } from "../../components/no-footer/NoFooter";
import { useQuery } from "react-query";

import { api_routes } from "../../_api/api-routes";
import "./styles.scss";
import { useSequence } from "./use-sequence";
import clsx from "clsx";

type BasketProps = {};
export const AnimateBasket: React.FC<BasketProps> = () => {
  const { apiLoading, error, ...api } = useApi();
  const inputRef = useRef<HTMLInputElement>();
  const animtedTextRef = useRef<HTMLInputElement>();
  const [width, setWidth] = useState(550);
  const [height, setHeight] = useState(160);
  const [basketID, setBasketId] = useState("");
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const { start, sequence_states, reset: reset_sequence } = useSequence([
    0,
    1000,
    2000, 
    1000,
    3000,
    3000,
  ]);
  const [
    _,
    afterPromptBlurAnimation,
    afterLoadingAnimation,
    afterEmojiAnimation,
    afterTitleAnimation,
    afterDescriptionAnimation,
  ] = sequence_states;
  const [track, setTrack] = useState(false);
  const [promptText, setPromptText] = useState("");

  const reset = useCallback(() => {
    reset_sequence();
    const input = document.getElementById(
      "ai_input_prompt"
    ) as HTMLInputElement | null;
    if (input) {
      input.value = "";
    }
    setPromptText("");
    setTrack(false);
  }, []);

  useEffect(() => {
    reset();
  }, [basketID]);
  const scrollToDiv = useCallback((div_id:string) => {
    const targetElm = document.getElementById(div_id);
    const container = document.getElementById("scrolling_div");
    if (targetElm && container) {
      var topPos = targetElm.offsetTop;
      container.scrollTop = topPos;
    }
  }, [])
  useEffect(() => {
    if (afterLoadingAnimation) {
      const input = document.getElementById(
        "ai_input_prompt"
      ) as HTMLInputElement | null;
      if (input) {
        input.blur();
      }
      scrollToDiv("emoji_title");
    }
  }, [afterLoadingAnimation]);

  useEffect(() => {
    if (afterTitleAnimation) {
        scrollToDiv("description")
    }
  }, [afterTitleAnimation]);
  useEffect(() => {
    if (afterDescriptionAnimation) {
        scrollToDiv("holdings");
    }
  }, [afterDescriptionAnimation]);

  const getBasket = (_basketID: string) =>
    api.get({
      url: api_routes.pages.basket + "/" + _basketID,
    });

  const _basketResponse = useQuery(basketID, getBasket(basketID), {
    enabled: !!basketID,
  });

  const animateBasket = () => {
    if (inputRef && inputRef.current && inputRef.current.value) {
      setBasketId(inputRef.current.value);
    }
  };

  let interval: any = useRef(null);
  useEffect(() => {
    if (track && animtedTextRef.current) {
      interval.current = setInterval(() => {
        setPromptText(animtedTextRef.current!.innerText);
      }, 100);
    }
    if (!track) {
      if (interval.current) {
        clearInterval(interval.current);
        start();
      }
    }
  }, [track]);

  useEffect(() => {
    const input = document.getElementById(
      "ai_input_prompt"
    ) as HTMLInputElement | null;
    if (input) {
      input.spellcheck = false;
      input.blur();
      input.value = promptText;
      input.focus();
      input.setSelectionRange(-1, -1);
    }
  }, [promptText]);

  useEffect(() => {
    return reset;
  }, []);

  const showCursorAnimation = (show: boolean) => {
    if (!animtedTextRef.current) {
      return;
    }
    setTrack(show);
  };
  const basket = _basketResponse.data as
    | undefined
    | (BasketItemDetail & { error_message?: string });
  return (
    <Container className={clsx(!isLarge && "px-0")}>
      <NoFooter>
        <div className="px-4 md:px-0 flex flex-col md:flex-row md:items-end md:space-x-4 space-y-3 md:space-y-0  justify-center cursor-text">
          {isLarge && <div className="w-32">
            <label>Width:</label>
            <Input
              value={width}
              onChange={(e) => setWidth(Number(e.target.value))}
              {...{ type: "number", placeholder: "0", className: "w-32" }}
            />
          </div>}
          <div className="w-32">
            <label>Height:</label>
            <Input
              value={height}
              onChange={(e) => setHeight(Number(e.target.value))}
              {...{ type: "number", placeholder: "0", className: "w-32" }}
            />
          </div>
          <div className="w-72">
            <label>Basket ID:</label>
            <Input
              ref={inputRef as any}
              {...{ type: "text", placeholder: "Basket ID", className: "w-72" }}
            />
          </div>
          <Button.Text
            size={GlobalUISizesEnum.LARGE}
            className="rounded-md"
            onClick={animateBasket}
            label="Animate"
            spacingSizeClasses={{ [GlobalUISizesEnum.LARGE]: "py-2" }}
            iconLeft={<LineIcon icon="play-circle" />}
          />
        </div>
        <div
          id="scrolling_div"
          style={{
            width: isLarge ? width : '100%',
            height,
            paddingRight: isLarge ? "4rem" : undefined,
            scrollBehavior: "smooth",
          }}
          className="relative mt-8 mx-auto border border-divider md:p-8 p-4 shadow-card rounded-md overflow-y-scroll"
        >
       
          <CreateBasketInput
            onSubmit={() => {}}
            placeholder=""
            loading={afterPromptBlurAnimation}
          />
          {basket && (
            <>
              <TypeAnimation
                id="prompt_text"
                wrapper="span"
                className="hidden"
                sequence={[
                  () => showCursorAnimation(true),
                  basket.ai_user_input || "",
                  () => showCursorAnimation(false),
                ]}
                //@ts-ignore
                ref={animtedTextRef as any}
              />
              <div id="emoji_title" style={{ height: 120 }}>
                {afterLoadingAnimation && (
                  <EmojiTitleAnimated
                    className="py-8"
                    titleProps={{ className: "md:text-3xl md:leading-8" }}
                    emoji={basket.emoji}
                    title={afterEmojiAnimation ? basket.title : undefined}
                  />
                )}
              </div>
              <div id="description" style={{ height: 140 }} className="py-8">
                {afterTitleAnimation && (
                  <div className=" w-full max-h-[200px] line-clamp-3">
                    <AnimatedText
                      value={basket.description}
                      speed={95}
                      pause_count={100}
                      className="text-xl"
                    />
                  </div>
                )}
              </div>
              <div id="holdings" className=" -mt-1">
                {afterDescriptionAnimation && (
                  <div className="large-base-font-size">
                    <HoldingsListMobile
                      headings={false}
                      hideProperties={["allocation", "movement_1y"]}
                      items={basket.holdings}
                      className="mt-0"
                    />
                  </div>
                )}
              </div>
              <div id="after_holdings" />
              {afterPromptBlurAnimation && !afterDescriptionAnimation && (
                <Loading className="left-0 mt-8" />
              )}
            </>
          )}
        </div>
      </NoFooter>
    </Container>
  );
};
