import {
  Countdown,
  D3Chart,
  usePosition,
} from "@potato/components";
import React, { useEffect, useRef, useState } from "react";
import { api_routes } from "../../_api/api-routes";
import { useD3ChartData } from "../../_utils/use-d3-chart-data";
import LiveButton from "../fantasy/LiveButton";

type ChartSectionProps = {
  is_live: boolean;
  market_close:string
  market_open:string
  market_last_close:string
  ranking_type?:'losers'|'gainers'
};

const ChartSection = ({ is_live, ranking_type, market_close, market_last_close, market_open }: ChartSectionProps) => {
  const [view, setView] = useState<string>("full");
  const [initialZoom, setInitialZoom] = useState(false);
  let timeout: any = useRef(null);

  useEffect(() => {
    if (initialZoom && timeout.current) {
      clearTimeout(timeout.current);
      timeout = null;
    }
  }, [initialZoom, timeout]);

  useEffect(() => {
    if (!initialZoom && is_live) {
      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          setView("closeup");
          setInitialZoom(true);
        }, 3000);
      }
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  return (
    <div className="md:bg-white md:rounded-2xl md:shadow-card md:border md:border-divider mb-8 md:p-4 pl-0 pt-2">
      <div className="flex items-center md:mb-6">
        <div className="flex-1">
        <div className="md:flex hidden pl-2"><Countdown endingLabel="Market closes" startingLabel="Market opens" previous_end_at={market_last_close} start_at={market_open} end_at={market_close} /></div>

        </div>
        {is_live && (
          <LiveButton
            onClick={() => {
              setView(view === "full" ? "closeup" : "full");
              setInitialZoom(true);
            }}
            className="float-right mr-2"
            on={view === "closeup"}
          />
        )}
      </div>
      <RankingsChart view={view} ranking_type={ranking_type || 'gainers'} />
    </div>
  );
};
type RankingChartProps = {
  view: string;
  ranking_type:string
};
const RankingsChart = ({ view, ranking_type }: RankingChartProps) => {
  const ref = useRef();
  let {
    position: { width, height },
  } = usePosition(ref);
  const { active_view } = useD3ChartData({
      options: {
          view,
          type: ranking_type
      },
    //   id: id,
      active_item: view,
      key: 'view',
      full_item: 'full',
      latest_item: 'latest',
      fetch_updates_on: 'closeup',
      url: api_routes.charts.rankings
  })

  return (
    <div
      className="w-full max-w-full md:h-[350px] h-[250px] text-grey mt-4 md:mt-4"
      ref={ref as any}
    >
      <div className="relative h-full w-full md:ml-0 -ml-6">
        {width > 0 && active_view.data.length ? (
          <D3Chart
            y_formatter={(y) => {
              return `${y.toPrecision(3)}%`;
            }}
            series={active_view.data}
            range={active_view.range}
            options={{
              width,
              height,
              shift_x: view === "closeup",
              markers: {
                picture: true,
                ping: view === "closeup",
                outline: view !== "closeup",
              },
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ChartSection;
