import { BasketItem, BasketItemDetail, BasketPerformanceItem } from "@potato/components"
import React, { ReactNode, useEffect } from "react"
import useBasketUpdateStore from "../../_contexts/basket-patch-store/useBasketUpdateStore"
import usePriceUpdateStore from "../../_contexts/price-update-store/usePriceUpdateStore"

const WatchBasketUpdate = ({
  item: propItem,
  children
}: { item: BasketItem | BasketItemDetail | BasketPerformanceItem, children: ReactNode}) => {
  const { basketPriceUpdates, addBasketIds, basketIds } = usePriceUpdateStore()
  const { basketUpdates } = useBasketUpdateStore()

  useEffect(() => {
    if(!basketIds.find(pid => pid === propItem.id))
    addBasketIds([propItem.id])
  }, [propItem.id])
  
  const priceData = basketPriceUpdates[propItem.id]
  const basketUpdateData = basketUpdates[propItem.id]
  const item = {
  ...propItem,
    ...priceData,
    ...basketUpdateData
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { item });
    }
    return child;
  });

  return <>{childrenWithProps}</>
}

export default WatchBasketUpdate