import { RankingSeriesType, useThemeContext } from "@potato/components";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import useApi from "../_contexts/api/useApi";

type D3ChartDataProps = {
  url: string;
//   id?: string;
  active_item: string;
  key: string;
  options: { [x: string]: string };
  full_item: string;
  latest_item?: string;
  fetch_updates_on?: string;
};
type ActiveView = {
  data: RankingSeriesType[];
  range: number;
};
type ChartResponse = { chart?: { series?: RankingSeriesType[] } } | undefined;
type ValidChartResponse = { chart: { series: RankingSeriesType[] } };
const isValidChartResponse = (
  chart_response: ChartResponse
): chart_response is ValidChartResponse => {
  return !!(
    chart_response &&
    chart_response.chart &&
    chart_response.chart.series &&
    chart_response.chart.series.length &&
    chart_response.chart.series[0].data
  );
};
export const useD3ChartData = ({
  options,
  url,
//   id,
  active_item,
  full_item,
  fetch_updates_on,
  latest_item,
  key,
}: D3ChartDataProps) => {
  const {
    colors: { upDownColors: colors },
    isDark,
  } = useThemeContext();
  const api = useApi();
  const [merged_data, setMergedData] = useState<{ [x: string]: ActiveView }>(
    {}
  );
  const [latest_updates, setLatestUpdates] = useState<RankingSeriesType[]>([]);

  const getPositiveNegativeColor = (data: { x: number; y: number }[]) => {
    if (!data || !data.length) {
      return colors.upward;
    }
    if (data.length > 0 && data[0].y > data[data.length - 1].y) {
      return colors.downward;
    }
    return colors.upward;
  };

  const getData = (_options: { [x: string]: string }) => async () => {
    return await api.get({
      url: `${url}${Object.keys(_options)
        .map((k) => `${k}=${_options[k]}&`)
        .join("")}`,
    })();
  };

  const { data } = useQuery(
    [`chart-${options.id}`, JSON.stringify(options)],
    getData(options),
    {
      refetchInterval: undefined,
    }
  );

  const getLatest = (_options: { [x: string]: string }) => async () => {
    const latest_options: { [x: string]: string } = {
      ..._options,
      [key]: latest_item!,
    };
    return await api.get({
      url: `${url}${Object.keys(latest_options)
        .map((k) => `${k}=${latest_options[k]}&`)
        .join("")}`,
    })();
  };
  const getFull = (_options: { [x: string]: string }) => async () => {
    const latest_options: { [x: string]: string } = {
      ..._options,
      [key]: full_item,
    };
    return await api.get({
      url: `${url}${Object.keys(latest_options)
        .map((k) => `${k}=${latest_options[k]}&`)
        .join("")}`,
    })();
  };
  const { data: latest } = useQuery<ChartResponse>(
    [`chart-latest-${options.id || "0"}`, JSON.stringify(options)],
    getLatest(options),
    {
      enabled: !!latest_item,
      refetchInterval: active_item === fetch_updates_on ? 3000 : undefined,
    }
  );
  const { data: full } = useQuery(
    [`chart-full-${options.id || "0"}`, JSON.stringify(options)],
    getFull(options),
    {
      enabled: !!full_item,
    }
  );

  useEffect(() => {
    if (isValidChartResponse(latest)) {
      if (!latest_updates.length) {
        setLatestUpdates(latest.chart.series);
        return;
      }
      const updated = latest_updates.map((s) => {
        const latest_s = latest.chart.series.find((ls) => ls.id === s.id);
        // latest is always one item. Persist these items into latest_updates state.
        // latest one item is either a new x plot or an updated x plot.
        // if new x plot simply concat, if update, replace the last item with the new one
        return {
          ...s,
          data: latest_s
            ? latest_s.data[0].x !== s.data[s.data.length - 1].x
              ? s.data.concat(latest_s.data)
              : s.data.slice(0, -1).concat(latest_s.data)
            : s.data,
        };
      });
      setLatestUpdates(updated);
    }
  }, [latest]);

  // merge data function
  const make_merge_data = (series: RankingSeriesType[]) => {
    const range =
      series[0].data[series[0].data.length - 1].x - series[0].data[0].x;
    return {
      data: series.map((s: RankingSeriesType) => {
        return {
          ...s,
          color: s.color || getPositiveNegativeColor(s.data),
        };
      }),
      range,
    };
  };

  // merge data object holds all data updates. We can then simply watch this object to recreate our computed 'active_view'
  useEffect(() => {
    let item;
    let latest_item_data: ActiveView | undefined;
    let full_item_data: ActiveView | undefined;
    if (isValidChartResponse(data)) {
      item = make_merge_data(data.chart.series);
    }
    if (latest_item && latest_updates && latest_updates.length) {
      latest_item_data = make_merge_data(latest_updates);
    }
    if (full_item && isValidChartResponse(full)) {
      full_item_data = make_merge_data(full.chart.series);
    }

    if (
      (latest_item && full_item
        ? latest_item_data && full_item_data
        : latest_item
        ? latest_item_data
        : full_item
        ? full_item_data
        : true) &&
      item
    ) {
      setMergedData({
        ...merged_data,
        [active_item]: item,
        ...(latest_item ? { [latest_item]: latest_item_data! } : {}),
        ...(full_item ? { [full_item]: full_item_data! } : {}),
      });
    }
  }, [data, latest_updates, full, active_item]);

  // return the current chart view with the full chart view data prepended upto the current view and the latest data past the current view.
  const active_view = useMemo(() => {
    const view_with_full = merged_data[full_item]
      ? (JSON.parse(
          JSON.stringify(merged_data[full_item].data)
        ) as RankingSeriesType[])
      : [];

    view_with_full.forEach((fs) => {
      if (active_item !== full_item) {
        const current_view =
          merged_data[active_item] &&
          merged_data[active_item].data.find((cs) => {
            return cs.id === fs.id;
          });
        if (!current_view) {
          return;
        }
        // get full view data only upto closeup start_at point. (incase full view overlaps closeup)
        const upto_current_view = fs.data.slice(
          0,
          fs.data.findIndex(
            (fsd) => fsd.x > merged_data[active_item].data[0].data[0].x
          )
        );
        fs.data = upto_current_view.concat(current_view.data);
      }

      const latest_series =
        latest_item &&
        merged_data[latest_item].data.find((cs: RankingSeriesType) => {
          return cs.id === fs.id;
        });
      if (!latest_series) {
        return;
      }
      const latest_first_item = latest_series.data[0];
      const last_item = fs.data[fs.data.length - 1];
      if (last_item.x < latest_first_item.x) {
        fs.data = fs.data.concat(latest_series.data);
      } else if (last_item.x === latest_first_item.x) {
        fs.data = fs.data.slice(0, -1).concat(latest_series.data);
      }
    });

    return {
      ...merged_data[active_item],
      data: view_with_full,
    };
  }, [merged_data]);

  return {
    active_view,
  };
};
