
import React from "react";
import {
  Typography,
  ButtonVariant,
  LineIcon,
} from "@potato/components";
import Comments from "../../components/comments";
import { GainersLosersPageResponse } from "../../_api/pages/gainers-losers";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";

export interface CommentsProps {
  item: GainersLosersPageResponse
  width?: number
  height?:number
};

export const CommentsDialog = ({
  item,
  width,
  height
}: CommentsProps) => {
    const { setPageMode } = usePageMode();
    return <div className={"px-4 py-2 h-full flex-col"} style={{height}}>
    <div className=" flex-0 flex items-center space-x-4 justify-end mb-2">
      <div className="flex-1">
      <Typography className="text-secondary">#general</Typography>
      </div>
      <div className="flex-0">
        <ButtonVariant.Icon
          onClick={() => {
            setPageMode("main");
          }}
          label={<LineIcon icon="cross" className="text-lg" />}
          className="leading-none"
        />
      </div>
    </div>

    <div className="flex-1 flex-col" >
    <Comments item={{ id: item.chat_reference_id }} type="chat" />
    </div>
  </div>

        }