import React from "react";
import { Container, FantasyHero, Loading } from "@potato/components";
import PageMeta from "../../components/page-meta";
import JoinALeague from "./JoinALeague";
import useApi from "../../_contexts/api/useApi";
import { useQuery } from "react-query";
import { api_routes } from "../../_api/api-routes";
import { FantasyLandingPageResponse } from "../../_api/pages/fantasy-landing";
import Section from "./Section";

export interface FantasyLandingProps {
};

const FantasyLanding = ({

}: FantasyLandingProps) => {
  const { apiLoading, auth0ApiReady, ...api } = useApi()
  const getFantasyLanding = api.get({ url: api_routes.pages.fantasy_landing })
  const _fantasyLandingResponse = useQuery('fantasyLanding', getFantasyLanding, {
    enabled: !apiLoading,
    refetchInterval: 30000
  })

  const fantasyLanding = _fantasyLandingResponse.data as FantasyLandingPageResponse

  if (fantasyLanding) {
    (window as any).prerenderReady = true;
  }
  return (<>
    <Container>
      <PageMeta data={fantasyLanding && fantasyLanding.meta_tags} defaultData={{ title: 'Fantasy Trading', description: 'The ultimate platform for Fantasy Trading. Compete against your friends and celebrety investors.' }} />
      <FantasyHero />
    </Container>
    <Container className="bg-lifted light:bg-lifted-highlight py-12 md:py-32 ">
      <JoinALeague />
    </Container>
    <Container>
      {
        fantasyLanding ? fantasyLanding.sections.map(item => {
          return <Section key={item.slug || item.title} item={item} />
        }) : <Loading className="mt-32" />
      }
    </Container>
  </>
  );
};

export default FantasyLanding;