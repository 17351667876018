import React from "react"
import { Avatar, Breadcrumbs, DialogVariant, GlobalUISizesEnum, Typography, UserAvatarList, UserDetailType, UserType, useTruncate } from "@potato/components"
import usePageMode from "../../_contexts/page-mode/page-mode-hook"

export const FollowersDialog = ({ user }: { user: UserDetailType }) => {
  const followers = user.followers || []
  const { setPageMode } = usePageMode()
  return (
    <>
      <DialogVariant.FullView headingContent={<>
        <Breadcrumbs items={[{
        prefix: <Avatar size={GlobalUISizesEnum.XSMALL} avatar={user.picture} />,
        space: '2',
        label: `${user.username}`
      }]}
      />
        <Typography variant="h3" className="lg:mb-4 mb-3 mt-2">Followers</Typography>
      </>} widthClass="max-w-2xl" onClose={() => setPageMode('main')} open={true}>
          <div className={'mt-4 flex space-x-4'}>
            <UserAvatarList items={followers as UserType[]} />
          </div>
      </DialogVariant.FullView>
    </>
  )
}
