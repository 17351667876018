import {
  Container,
  useCurrentBreakpointName,
  Typography,
  BasketDetailedList,
  useSwitch,
  Tabs,
  PageTop,
  ButtonVariant,
  GlobalUISizesEnum,
  PageMain,
  PageMainLeft,
  PageMainRight,
  Countdown,
  LineIcon,
  DialogVariant,
} from "@potato/components";
import clsx from "clsx";
import useApi from "../../_contexts/api/useApi";
import WatchBasketPriceUpdate from "../../hoc/watch-basket-updates";
import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { api_routes } from "../../_api/api-routes";
import { GainersLosersPageResponse } from "../../_api/pages/gainers-losers";
import { useWatch } from "../../_utils/use-watch";
import Comments from "../../components/comments";

import PageMeta from "../../components/page-meta";
import ChartSection from "./ChartSection";
import { FixedBottomBar } from "./FixedBottomBar";
import PageMode from "../basket/page-mode/PageMode";
import { NoFooter } from "../../components/no-footer/NoFooter";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import { PageModeProvider } from "../../_contexts/page-mode/PageModeContext";
import { CommentsDialog } from "./CommentsDialog";

const GainersLosers: React.FC = () => {
  const api = useApi();
  const { watchFn } = useWatch();

  const name = useCurrentBreakpointName();
  const { setPageMode } = usePageMode();
  const isLarge = ["large", "xlarge"].includes(name);
  const location = useLocation();
  const { activeItem, onChange } = useSwitch<"gainers" | "losers">("gainers");

  const { activeItem: activeSort, onChange: sortOnChange } = useSwitch<
    "24h" | "7d" | "1y"
  >("7d");

  const getGainersLosers = api.get({
    url: `${api_routes.pages.rankings}?order_by=${activeSort}&type=${activeItem}`,
    waitForAuth: false,
  });
  const _gainersLosersResponse = useQuery(
    [location.pathname, activeSort, activeItem],
    getGainersLosers,
    { keepPreviousData: true }
  );

  const rankings = _gainersLosersResponse.data as GainersLosersPageResponse;
  const isLoading = _gainersLosersResponse.isLoading;

  if (!rankings) {
    return <div />;
  }
  (window as any).prerenderReady = true;

  const tabs = [
    {
      label: "Gainers",
      value: "gainers",
    },
    {
      label: "Losers",
      value: "losers",
    },
  ];
  // const sortByOptions = (
  //   <div className={clsx(!isLarge && "mt-1", "flex py-2 items-center")}>
  //     {isLarge && <Typography className="text-base mr-1">Sort by</Typography>}
  //     <ButtonVariant.Chip
  //       active={activeSort === "24h"}
  //       size={isLarge ? GlobalUISizesEnum.SMALL : GlobalUISizesEnum.XSMALL}
  //       label="24h"
  //       onClick={() => {
  //         sortOnChange("24h");
  //       }}
  //       className="ml-1"
  //     />
  //     <ButtonVariant.Chip
  //       active={activeSort === "7d"}
  //       size={isLarge ? GlobalUISizesEnum.SMALL : GlobalUISizesEnum.XSMALL}
  //       label="7d"
  //       onClick={() => {
  //         sortOnChange("7d");
  //       }}
  //       className="ml-1"
  //     />
  //     <ButtonVariant.Chip
  //       active={activeSort === "1y"}
  //       size={isLarge ? GlobalUISizesEnum.SMALL : GlobalUISizesEnum.XSMALL}
  //       label="1y"
  //       onClick={() => {
  //         sortOnChange("1y");
  //       }}
  //       className="ml-1"
  //     />
  //   </div>
  // );
  return (
    <div className="md:px-8 px-4 max-w-7xl mx-auto">
      <PageMeta
        data={rankings.meta_tags}
        defaultData={{
          title: "Basket Rankings",
          description: "The best and worst performing baskets on Potato",
        }}
      />

      {/* Only for mobile */}
      <PageMode pageModes={["chat"]}>
        <Container>
          <NoFooter>
            <DialogVariant.Boxed open={true} closeButton={false} spaced={false}>
              <CommentsDialog item={rankings} />
            </DialogVariant.Boxed>
          </NoFooter>
        </Container>
      </PageMode>
      <PageMode pageModes={["main"]}>
        <PageTop>
          <></>
        </PageTop>
        <PageMain space="" className="justify-center">
          <PageMainLeft size="lg:w-3/4">
            <div className="flex items-center mb-4">
              <Typography variant="h2" element="h1" className="">
                Rankings
              </Typography>
              <div className="flex-1">
                <Tabs
                  align={"end"}
                  className={clsx("text-lg")}
                  onChange={onChange as (arg: string) => void}
                  items={tabs}
                  activeItem={activeItem}
                />
              </div>
            </div>
            <ChartSection
              market_close={rankings.market_close}
              market_last_close={rankings.market_last_close}
              market_open={rankings.market_open}
              is_live={rankings.is_live}
              ranking_type={activeItem}
            />
            <BasketDetailedList
              onWatch={watchFn}
              orderBy={activeSort}
              onChange={sortOnChange}
              itemWrapper={WatchBasketPriceUpdate as any}
              loading={isLoading}
              data={rankings.rankings || []}
            />
            <FixedBottomBar item={rankings} />
          </PageMainLeft>
         {isLarge && <PageMainRight size="lg:w-2/5" className="md:pl-4 2xl:pl-8">
            <div className="flex flex-col h-full">
              <Comments show_bot_typing={false} item={{ id: rankings.chat_reference_id }} type="chat" />
            </div>
          </PageMainRight>}
        </PageMain>
      </PageMode>
    </div>
  );
};

const WithPageMode = () => {
  return (
    <PageModeProvider initialMode="main">
      <GainersLosers />
    </PageModeProvider>
  );
};
export default WithPageMode;
