import React, { useCallback, useEffect } from "react";
import { useAppClass, useSearch } from '@potato/components'
import useApi from '../../_contexts/api/useApi'
import { debounce } from 'lodash'
import { api_routes } from "../../_api/api-routes";
import {
  useQuery,
} from 'react-query'
import { useLocation } from "react-router-dom";

// Initialises search context with data from api.potato. 
// Impliments searching via api for baskets and users.
// Categories and Tags are filtered client side by search context.

const Search = () => {
  const { apiLoading, ...api } = useApi()
  const { pathname } = useLocation()
  const { isOpen, reset, tags, searchText, searchTopic, setBaskets, setTags, setCategories, setUsers, setTickers, filteredBaskets, filteredTickers, filteredUsers, setIsOpen } = useSearch()
  const { setAppClass } = useAppClass()

  const getTags = api.get({ url: api_routes.tags, waitForAuth: false })
  const getCategories = api.get({ url: api_routes.categories, waitForAuth: false })

  const tagsResponse = useQuery('tags', getTags)
  const categoriesResponse = useQuery('categories', getCategories)

  useEffect(() => {
    reset()
  }, [pathname]);

  useEffect(() => {
    if (tagsResponse.data && !tags.length) {
      setTags(tagsResponse.data.tags)
    }
  }, [tagsResponse])

  useEffect(() => {
    if (categoriesResponse.data) {
      setCategories(categoriesResponse.data.categories)
    }
  }, [categoriesResponse])

  // ------ Users -------- //
  const getUsers = (searchText: string) => async () => {
    return api.get({
      url: `${api_routes.search.users}${encodeURI(searchText.trim())}`, 
      waitForAuth: false
    })()
  }

  const { data: user_results } = useQuery(['user_search', searchText], getUsers(searchText), {
    enabled: searchTopic === 'users',
    retry: false
  })

   useEffect(() => {
    setUsers(user_results && user_results.users ? user_results.users : [])
  }, [user_results])
  
  
  // ------ Baskets -------- //
  const getBaskets = (searchText: string) => async () => {
    return api.get({
      url: `${api_routes.search.basket}${encodeURI(searchText.trim())}`, 
      waitForAuth: false
    })()
  }

  const { data: basket_results } = useQuery(['basket_search', searchText], getBaskets(searchText), {
    enabled: searchTopic === 'baskets',
    retry: false
  })

   useEffect(() => {
    setBaskets(basket_results && basket_results.baskets ? basket_results.baskets : [])
  }, [basket_results])

  // ------ Tickers -------- //
  const getTickers = (searchText: string) => async () => {
    return api.post({
      url: api_routes.search.tickers, data: {
        query: searchText.trim(),
        cursor: 0
      }, waitForAuth: false
    })()
  }

  const { data: ticker_results } = useQuery(['ticker_search', searchText], getTickers(searchText), {
    enabled: searchTopic === 'tickers',
    retry: false
  })

  useEffect(() => {
    setTickers(ticker_results && ticker_results.tickers ? ticker_results.tickers : [])
  }, [ticker_results])

  useEffect(() => {
    if (isOpen) {
      setAppClass('overflow-hidden h-screen')
    } else {
      setAppClass('')
    }
    return () => setAppClass('')
  }, [isOpen])


  return (
    <></>
  );
};

export default Search