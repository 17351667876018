import React from "react";
import {
  Container,
  Markdown,
  Loading,
  PageMainLeft,
  Logo,
  BotAnimation,
  useCurrentBreakpointName,
} from "@potato/components";
import useApi from "../../_contexts/api/useApi";
import { useQuery } from "react-query";
import { api_routes } from "../../_api/api-routes";
import { MarkdownContentResponse } from "../../_api/markdown-content";
import PageMeta from "../../components/page-meta";

const Releases = () => {
  const { get } = useApi();
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const getReleases = get({
    url: api_routes.pages.releases,
    waitForAuth: false,
  });
  const _data = useQuery("releases", getReleases);
  const data = _data.data as MarkdownContentResponse;
  if (!data) {
    return <Loading className="mt-32" />;
  }
  (window as any).prerenderReady = true;
  return (
    <>
      {isLarge &&<div
        className="absolute"
        style={{ width: "100%", height: "900px", top: "-100px" }}
      >
        <BotAnimation />
      </div>}
      <Container className="mb-20">
        <PageMeta
          data={data.meta_tags}
          defaultData={{
            title: "What's New",
            description: "Latest features and potato AI model updates",
          }}
        />
        <PageMainLeft className="mx-auto mt-8 relative">
          <div className="prose">
            <div className="mb-16 md:mb-32" style={{marginTop:isLarge ? '12rem' : '4rem'}}>
              <h1 className="mb-0 text-5xl">What's New</h1>
              <p className="mt-1 text-secondary text-xl leading-6">
                Latest features &amp; potato AI model updates
              </p>
            </div>
          </div>
           <ReleasesContent/>
        
        </PageMainLeft>
      </Container>
    </>
  );
};

export const ReleasesContent = () => {
    return      <div className="prose">
    <div className="mt-8">
      <p className="text-secondary mb-2">Jul 25, 2023</p>
      <h1 className="mt-0 mb-4">Munger 3.0</h1>
      <ul>
      <li>
          <p>
            <span className="text-xl leading-5">
            <b>Major AI meta-model improvement - thesis generation</b>
            </span>
            <br />
          </p>
          <p>Today we're pleased to announce the release of Munger 3.0, Potato's latest AI release to construct your investment ideas. This is a big one, let us explain!</p>

            <span className="text-xl leading-5">
             <b>~ Ripple effects</b>
            </span><br/>

            <p>This release brings significant improvements to the Potato meta-model and its second-order thinking capabilities. Munger 3.0 has been purpose built to consider the direct effects and indirect, broader implications of your investment idea. The model now also considers both positively and negatively implicated sectors before stock selection begins, considering the ripple effects set in motion.</p>

            <p><b>What does this actually mean?</b></p>

            <p>Take a look at a few hand picked baskets here:</p>

            <p>🦠 <a href="https://potato.trade/basket/virus-returns-profits-in-ppe-civgf6u73jrvkaslmitg">There will be another pandemic</a></p>
            <p>Munger selected a perfect array of pandemic stocks: Peloton, Zoom, DoorDash, Teladoc, Pfizer, Disney, UPS, Netflix, Moderna, Amazon, Home Depot, J&J, MSFT. Templeton didn't consider the ripple effects of the virus and missed Peleton, DoorDash, Teladoc, HomeDepot, Zoom and Netflix.<br/></p>

            <p>🌊 <a href="https://potato.trade/basket/seattle-s-liquid-assets-surge-civgeju73jrvkaslmin0">A giant tsunami has hit Seattle</a></p>
            <p>Munger invested in rebuild stocks, technology stocks, remote health services and insurance companies which was an interesting thought given the substantial claims they would face. Tsunami events are very rare however price premiums would lift, lifting profits to insurers. Templeton produced a basket of mostly infrastructure and construction, mostly focusing on the rebuild efforts (first-order thinking).<br/></p>

            <p>🔬 <a href="https://potato.trade/basket/alchemist-s-dream-fool-s-gold-no-more-civh33e73jrvkaslmnf0">Cheap lab-grown gold is now abundant</a></p>
            <p>This basket has always been a challenge for our model but we're heading in the right direction now. Munger invested in technology stocks as chipsets become cheaper and identified the issues with gold a store of wealth, the luxury status of gold potentially changing, along with suggesting you may want to short mining stocks. Templeton awkwardly invested in gold producers, not keenly aware that they would likely suffer the most.<br/></p>

            <p> All baskets can be upgraded to Munger 3.0 for free.</p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
             <b>Art improvements</b>
            </span>
            <br />
            Goodbye obscure abstracted art - hello to detail-rich art that tells a story of your theme, created instantly with your basket.
          </p>
          <div className="rounded-md inline-block border border-divider shadow-card overflow-hidden max-w-[407px] my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/artwork-update.jpg" alt="AI basket artwork"/>
            </div>
        </li>
      </ul>
    </div>
    <hr/>
    <div className="mt-8">
      <p className="text-secondary mb-2">May 26, 2023</p>
      <h1 className="mt-0 mb-4">Templeton 2.1</h1>
      <ul>
      <li>
          <p>
            <span className="text-xl leading-5">
              <b>Specialist AI chatbot</b>
            </span>
            <br />
            Wouldn't it be nice to know why the AI chose a specific stock or which stocks belong to a specific sub-sector? Just ask!</p>
            <p> All baskets built on Templeton 2.0+ now come with a specialist AI chatbot to answer questions about the basket.
          </p>
           <div className="rounded-md inline-block border border-divider shadow-card overflow-hidden max-w-[407px] my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2.1-chat.png" alt="AI specialist chatbot feature"/>
            </div>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Reduction in error rates.</b>
            </span>
            <br />
            The new model reduces ticker selection error rates by 5% - 20%. This results in a substancially better constructed basket, parcticularly for niche baskets with lesser known stocks.
          </p>
        </li>
      </ul>
    </div>
    <hr/>
    <div className="mt-8">
      <p className="text-secondary mb-2">11 May, 2023</p>
      <h1 className="mt-0 mb-4">Templeton 2.0</h1>
      {/* <p className="text-xl">
        The latest Potato AI model has taken big steps towards improving
        ticker selections.
      </p> */}
      <ul>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Significant improvements to stock and ETF selection</b>
            </span>
            <br />
            The new model can more accurately resolve AI ticker
            suggestions to actual tickers in our system as well as
            select cryptocurrencies where relevant.
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Broader support for cryptocurrency</b>
            </span>
            <br />
            Less use of Cryptocurrency when it wasn't relevant (sorry to that user that asked our AI for 'boring stocks' and ended up with Ether!)
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>International investments now supported</b>
            </span>
            <br />
            (e.g. try "Japanese tech" or "aussie mining")
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Upgrade feature</b>
            </span>
            <br />
            You now have the ability to upgrade existing AI baskets to the new model. (Without using credits!)
          </p>
            <div className="rounded-md border border-divider shadow-card overflow-hidden max-w-md my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-upgrade.png" alt="upgrade feature"/>
            </div>
            <p>This will regenerate the title, emoji, artwork, description and select a new set of holdings and perform trades accordingly. You can see a log of changes to your basket by clicking "View History".</p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Notifications</b>
            </span>
            <br />
            Get updated on user activity happening in your baskets and baskets you watch.
            <div className="rounded-md border border-divider shadow-card overflow-hidden max-w-md my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-notifications.png" alt="notifications feature"/>
            </div>
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Mobile Navigation Changes</b>
            </span>
            <br />
            We have updated our menus on mobile so you discover more on potato using the main menu. Easily access your own baskets via your avatar in the header.
            <div className="flex items-start space-x-4">
            <div className="rounded-md border border-divider shadow-card overflow-hidden max-w-xs my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-main-menu.png" alt="main menu feature"/>
            </div>
            <div className="rounded-md border border-divider shadow-card overflow-hidden max-w-xs my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-user-menu.png" alt="user menu feature"/>
            </div>
            </div>
          </p>
        </li>
      </ul>
    </div>
    <hr/>
    <div className="mt-8">
      <p className="text-secondary mb-2">20 April, 2023</p>
      <h1 className="mt-0 mb-4">Graham 1.0</h1>
  
      <ul>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>AI Generated Baskets</b>
            </span>
            <br />
            Foundation model: Generate emoji, title, description and select 5-20 relevant tickers (Stocks/Crypto/ETFs)
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Basket History</b>
            </span>
            <br />
            Track changes to your baskets by clicking the View History button.
            <div className="rounded-md border border-divider shadow-card overflow-hidden max-w-md my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-history.png" alt="view basket history feature"/>
            </div>
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Copy Basket</b>
            </span>
            <br />
            You can copy AI generated baskets to your private collection and edit the basket's holdings, emoji, title and description.
            <div className="rounded-md border border-divider shadow-card overflow-hidden my-4" style={{maxWidth:242}}>
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-copy.png" alt="copy AI basket history feature"/>
            </div>
          </p>
        </li>
        <li>
          <p>
            <span className="text-xl leading-5">
              <b>Rankings</b>
            </span>
            <br />
            <a href="/rankings">Rankings Page</a>. See the best and worst performing baskets on potato for the last 7 days. General chat added to discuss rankings, features and AI improvements.
            <div className="rounded-md border border-divider shadow-card overflow-hidden max-w-md my-4">
                <img className="my-0" src="https://s3.amazonaws.com/static.potato.trade/email-template-assets/Templeton-2-0-rankings.png" alt="Rankings page"/>
            </div>
          </p>
        </li>
        </ul>
        </div>
        </div>

}

export default Releases;
