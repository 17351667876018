import React, { useCallback, useEffect, useState } from "react";
import { Container, format, FormatEnum, Loading, Markdown, SectionNav, usePageStatus } from "@potato/components";
import useApi from "../../_contexts/api/useApi";
import PageMeta from "../../components/page-meta";
import { api_routes } from "../../_api/api-routes";
import { useQuery } from "react-query";
import { MarkdownSectionsResponse } from "../../_api/markdown-sections";

const Docs = ({ }) => {
  const { get } = useApi();
  const [activeSection, setActiveSection] = useState("");
  const { mainContentRef } = usePageStatus()
  const getPartnersDocs = get({
    url: api_routes.pages.docs,
    waitForAuth: false,
  });
  const _data = useQuery("docs", getPartnersDocs);
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let activeSectionNow = "";
      for (let i = 0; i < sections.length; i++) {
        const sectionCoords = sections[i].getBoundingClientRect();

        if (sectionCoords.top >= 0) {
          activeSectionNow = sections[i].id;
          break;
        }
      }
      setActiveSection(activeSectionNow);
    }

    handleScroll();
    const scrollDiv = mainContentRef && mainContentRef.current && (mainContentRef.current as any).view
    if (scrollDiv) {
      scrollDiv.addEventListener('scroll', handleScroll);
      return () => scrollDiv.removeEventListener('scroll', handleScroll);
    }

  }, []);

  useEffect(() => {
  }, [activeSection])

  if (!_data.data) {
    return <Loading className="mt-32" />;
  }
  (window as any).prerenderReady = true;

  const data = _data.data as MarkdownSectionsResponse;

  return (
    <Container className="mb-20 max-w-7xl mx-auto">
      <PageMeta
        data={data.meta_tags}
        defaultData={{ title: "Partners API Documentation | Potato" }}
      />
      <div className="md:flex md:space-x-8">
        <div className="flex-0 md:w-1/4">
          <div className="sticky top-[47px]">
            <SectionNav activeSection={activeSection} sections={data.sections} />
          </div>
        </div>
        <div className="flex-0 md:w-3/4">
          {data.sections.map((s) => {
            return <section id={format(s.title, FormatEnum.Slug)} className="mt-12 spy-section">
              <Markdown>{s.body}</Markdown>
              {!!s.sections && !!s.sections.length && s.sections.map((_s) => {
                return <section id={format(_s.title, FormatEnum.Slug)} className="mt-12">
                  <Markdown>{_s.body}</Markdown>
                </section>;
              })}
            </section>;
          })}
        </div>
      </div>
    </Container>
  );
};

export default Docs;
