import {
  UserAvatar,
  GlobalUISizesEnum,
  FormatEnum,
  format,
  LabelValueDisplay,
  Typography,
  Prize,
  FantasyItemType,
  FantasyDetailItemType,
  DurationEnum,
  StackOptionsEnum,
  MembersList,
  useToggle,
  ButtonVariant,
  LineIcon,
  CustomIcon,
} from "@potato/components";
import clsx from "clsx";
import "./pulse.scss";
import React from "react";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import { useQueryParams } from "../../_utils/use-search-params";
import { useHistory } from "react-router-dom";
import { is_member } from "./PaymentGuard";

const Meta: React.FC<{ item: FantasyDetailItemType; className?: string }> = ({
  item,
  className,
}) => {
  const ranked_members = item.rankings && item.rankings.filter(r => !r.is_unranked)
  return (
    <div className={className}>
      <div className={"space-y-2"}>
        {item.is_paid && item.selected_round.prizes && (
          <LabelValueDisplay
            label="Prizes"
            value={<Prizes items={item.selected_round.prizes} />}
            icon="trophy"
          />
        )}
        {item.is_paid && (
          <LabelValueDisplay
            label="Entry"
            value={format(
              item.selected_round.entry_fee || 0,
              FormatEnum.PriceWholeNumber
            )}
            icon="tag"
          />
        )}
        <LabelValueDisplay
          label="Founder"
          value={
            <UserAvatar
              bio={false}
              size={GlobalUISizesEnum.SMALL}
              item={item.founder!!}
            />
          }
          icon="feather"
        />
        <LabelValueDisplay
          label="Duration"
          value={format(item.duration, FormatEnum.Capitalize)}
          icon="calendar-full"
        />
        <LabelValueDisplay
          label="Stack"
          value={
            item.stack === "any"
              ? "Stocks, Crypto, ETFs"
              : format(item.stack, FormatEnum.Capitalize)
          }
          icon="layers"
        />
        {item.stack === StackOptionsEnum.STOCKS &&
          item.market_cap &&
          item.market_cap !== "Any" && (
            <LabelValueDisplay
              label="Market Cap"
              value={item.market_cap}
              icon="cash-dollar"
            />
          )}
        {ranked_members && (
          <LabelValueDisplay
            label={item.is_paid ? "Entries" : "Members"}
            value={
              <MembersList
                items={ranked_members}
                count={ranked_members.length || 0}
              />
            }
            icon="users2"
          />
        )}

        {!!item.benchmark && !!item.benchmark.picture && (
          <LabelValueDisplay
            label="Taking on"
            value={
              <UserAvatar
                bio={false}
                size={GlobalUISizesEnum.SMALL}
                item={{
                  slug: item.benchmark.slug,
                  username: item.benchmark.title!,
                  is_bot: true,
                  picture: item.benchmark.picture!,
                }}
              />
            }
            icon="graph"
          />
        )}
        {!!item.description && (
          <Typography variant="p" className={clsx("py-4 w-full")}>
            {item.description}
          </Typography>
        )}
       {item.is_public || is_member(item) && <RoundSelection className="pt-4" item={item} />}
      </div>
    </div>
  );
};

const RoundSelection: React.FC<{
  item: FantasyDetailItemType;
  className?: string;
}> = ({ item, className }) => {
  const { setPageMode } = usePageMode();
  let queryParams = useQueryParams();
  const history = useHistory();
  const roundParam = queryParams.get("round") || undefined;

  const roundOnClick = (round_number: number) => () => {
    const round = item.rounds?.find((r) => r.round_number === round_number);
    if (
      round &&
      ((roundParam && round.round_number !== Number(roundParam)) || !roundParam)
    ) {
      queryParams.set("round", round.round_number.toString());
      history.push(`?${queryParams.toString()}`);
    }
    setPageMode("main");
  };
  return (
    <div className={clsx(className, "flex-1 flex justify-items-stretch")}>
      {item.rounds && !!item.rounds.length && (
        <div className="flex items-center space-x-2">
          {item.rounds.map((_round) => {
            const selected =
              item.selected_round.round_number === _round.round_number;
            const isActive = selected && item.selected_round.is_live;
            const Button = selected ? ButtonVariant.Cta : ButtonVariant.Outline;
            return (
              <div key={_round.end_at} className="flex-0">
                <Button
                  onClick={roundOnClick(_round.round_number)}
                  className={clsx(isActive && "pulse-shadow", "font-semibold")}
                  style={{ width: selected ? undefined : 35 }}
                  spacingSizeClasses={{
                    [GlobalUISizesEnum.MEDIUM]: !selected
                      ? "px-0 py-1.5"
                      : "px-4 py-1.5",
                  }}
                  label={
                    <span
                      style={{ width: selected ? undefined : 33 }}
                      className="text-center"
                    >
                      {selected
                        ? `Round ${_round.round_number}`
                        : String(_round.round_number)}
                    </span>
                  }
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const MetaSummary: React.FC<{
  item: FantasyDetailItemType;
  roundLoading: boolean;
  className?: string;
  onClick?: () => void;
}> = ({ item, className, roundLoading }) => {
  const { setPageMode } = usePageMode();
  return (
    <div
      onClick={() => setPageMode("details")}
      className={clsx(
        className,
        "flex space-x-4 items-center justify-between bg-lifted light:bg-lifted-highlight rounded-full px-4 py-2"
      )}
    >
      <div className="flex space-x-4 items-center">
        {item.rankings && (
          <div className="-ml-1 whitespace-nowrap">
            <MembersList items={item.rankings} count={item.member_count || 0} />
          </div>
        )}
        <div className="border-l border-divider h-4 whitespace-nowrap" />
        <Typography>{format(item.duration, FormatEnum.Capitalize)}</Typography>
        <div className="border-l border-divider h-4 " />
        <Typography className="whitespace-nowrap">
          {roundLoading
            ? "Loading..."
            : `Round ${item.selected_round.round_number}`}
        </Typography>
      </div>
      <div className="">
        <ButtonVariant.Icon
          onClick={() => setPageMode("details")}
          label={<CustomIcon icon="down-arrow" className="block" />}
        />
      </div>
    </div>
  );
};

const Prizes = ({ items }: { items: Prize[] }) => {
  return (
    <div className="flex space-x-1 justify-between">
      {items.map((p) => {
        const color =
          p.ranking === 1
            ? "bg-gold"
            : p.ranking === 2
            ? "bg-silver"
            : "bg-bronze";
        return (
          <div className={clsx(color, "pt-0.5 pb-0 px-2 rounded-sm")}>
            <Typography className="font-semibold text-md">
              {p.description}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default Meta;
