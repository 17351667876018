import { SegmentsBar, Typography, SegmentType } from "@potato/components"
type SegmentsProps = {
  title: string
  items?: null | SegmentType[]
}
const Segments = ({
  title,
  items
}:  SegmentsProps) => {
  if(!items || !items.length) {
    return <></>
  }
  return <div className="mt-8">
    <Typography variant="h3" className="mb-4">{title}</Typography>
    <SegmentsBar items={items}/>
  </div>
}

export default Segments