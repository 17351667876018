import React, {
  createContext, useState
} from 'react';
import type { FC, ReactNode } from 'react';
import { MarkRequired } from 'ts-essentials';
import { BasketItemDetail } from '@potato/components'


export type BasketUpdate = MarkRequired<Partial<BasketItemDetail>, 'id'>
type BasketUpdatesMap = {[x:string]: BasketUpdate }
export interface BasketUpdateStoreContextValue {
  basketUpdates: BasketUpdatesMap
  addBasketUpdates: (basketUpdates: BasketUpdate[]) => void
  clearBasketUpdateStore: () => void
}
interface BasketUpdateStoreProviderProps {
  children?: ReactNode
}
const BasketUpdateStoreContext = createContext<BasketUpdateStoreContextValue>({
  basketUpdates: {},
  addBasketUpdates: () => {},
  clearBasketUpdateStore: () => {}
});

export const BasketUpdateStoreProvider: FC<BasketUpdateStoreProviderProps> = ({
  children,
}) => {
  const [basketUpdates, setBasketUpdates] = useState<BasketUpdatesMap>({})
  

  const addBasketUpdates = (_basketUpdates: BasketUpdate[]) => {
    setBasketUpdates((prev) => {
      const updates = _basketUpdates.reduce((acc: BasketUpdatesMap, update) => {
        acc[update.id] = {
          ...acc[update.id],
          ...update
        }
        return acc
      }, {})
      return {
        ...prev,
        ...updates
      }
    })
  }

  const clearBasketUpdateStore = () => {
    setBasketUpdates({})
  }
  return (
    <BasketUpdateStoreContext.Provider
      value={{
        basketUpdates,
        addBasketUpdates,
        clearBasketUpdateStore
      }}
    >
      {children}
    </BasketUpdateStoreContext.Provider>
  );
};

export const BasketUpdateStoreConsumer = BasketUpdateStoreContext.Consumer;

export default BasketUpdateStoreContext;
