import React, {
  createContext,
  useEffect,
} from 'react';
import type { FC, ReactNode } from 'react';
import { convertFromRaw, convertToRaw, EditorState, Modifier, SelectionState } from 'draft-js';

import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { compositeDecorator } from '../../decorators';

type BlockInfo = { key: string, elementId: string, start: number, end: number }
// what gets passed to consumer  const { loaded, someMethod } = useEditorStateContext()
export interface EditorStateContextValue {
 editorState: EditorState
 setEditorState: (editorState:EditorState) => void
 loading:boolean
 edited:boolean
 readOnly:boolean
 mdValue:string
 activeText: null | string
 setActiveText: (x: null | string) => void
 moveOn: () => void
 blockInfo: null | BlockInfo
 setBlockInfo: (x: null | BlockInfo) => void
}
// what passed to provider
interface EditorStateProviderProps {
  initialValue: string | undefined
  children?: ReactNode
  readOnly:boolean
  onChange?: (val: string) => void
  forceUpdate?: string
}
// an initial value for the consumer 
const EditorStateContext = createContext<EditorStateContextValue>({
  loading: false,
  mdValue: '',
  readOnly: false,
  edited: false,
  editorState: EditorState.createEmpty(),
  setEditorState: () => { },
  activeText: null,
  setActiveText: () => { },
  moveOn: () => { },
  blockInfo: null,
  setBlockInfo: () => { },
});


const convertToMD = (_editorState: EditorState) => {
  const content = _editorState.getCurrentContent();
  const rawObject = convertToRaw(content);
  return draftToMarkdown(rawObject);
}

const convertToDraft = (md: string) => {
  const rawData = markdownToDraft(md);
  const contentState = convertFromRaw(rawData);
  return EditorState.createWithContent(contentState);
}

export const EditorStateProvider: FC<EditorStateProviderProps> = ({ 
  children, 
  initialValue,
  forceUpdate,
  readOnly,
  onChange
}) => {
  const [activeText, setActiveText] = React.useState<string | null>(null)
  const [blockInfo, setBlockInfo] = React.useState<BlockInfo | null>(null)
  
  const [moveOnCounter, _triggerMoveOn] = React.useState(0)
  const [shouldUpdate, setShouldUpdate] = React.useState<boolean>(false)
  const [initialized, setInitialized] = React.useState<boolean>(false)
  const [edited, setEdited] = React.useState<boolean>(false)
  const [mdValue, setMdValue] = React.useState<string>(initialValue || '')
  const [editorState, setEditorState] = React.useState(() => {
    if (!initialValue) {
      return EditorState.createEmpty(compositeDecorator)
    } else {
      var newEditorState = convertToDraft(initialValue);
      return EditorState.set(newEditorState, { decorator: compositeDecorator })
    }
  });

  const triggerMoveOn = () => {
    _triggerMoveOn(moveOnCounter + 1)
  }


  useEffect(() => {
    if(moveOnCounter) { moveOn() }
   }, [moveOnCounter])
 
   const moveOn = () => {
     if (!blockInfo || !activeText) {
       return
     }
     let contentState = editorState.getCurrentContent();
     const textSelection = SelectionState.createEmpty(blockInfo.key).merge({
       anchorOffset: blockInfo.start,
       focusOffset: blockInfo.end
     })
     const atEnd = contentState.getBlockForKey(blockInfo.key).getLength() == blockInfo.start + activeText.length
 
     if (atEnd) {
       contentState = Modifier.replaceText(
         contentState,
         SelectionState.createEmpty(blockInfo.key).merge({
           anchorOffset: blockInfo.start + activeText.length,
           focusOffset: blockInfo.start + activeText.length,
         }),
         ' ',
       )
     }
     let newEditorState = EditorState.set(editorState, { currentContent: contentState });
     const newSelection = textSelection.merge({ anchorOffset: blockInfo.start + activeText.length + 1, focusOffset: blockInfo.start + activeText.length + 1 })
     newEditorState = EditorState.forceSelection(newEditorState, newSelection)
     setEditorState(newEditorState);
   }

  useEffect(() => {
    if (initialized) {
      setShouldUpdate(true)
    }
  }, [forceUpdate])

  useEffect(() => {
    if (shouldUpdate) {
      if (!initialValue) {
        setEditorState(EditorState.createEmpty(compositeDecorator))
        setMdValue('')
      } else {
        var newEditorState = convertToDraft(initialValue);
        setMdValue(initialValue)
        setEditorState(EditorState.set(newEditorState, { decorator: compositeDecorator }))
      }
      setEdited(false)
      setShouldUpdate(false)
    }
  }, [initialValue])

  useEffect(() => {
    if (initialized && !!editorState) {
      const md = convertToMD(editorState)
      if(md !== mdValue) { 
        onChange && onChange(md);
        setMdValue(md)
        setEdited(true)
      }
    
    }
    if (!initialized) {
      setInitialized(true)
    }
  }, [editorState])
  return (
    <EditorStateContext.Provider
      value={{
        readOnly:readOnly,
        loading: shouldUpdate,
        editorState,
        mdValue,
        edited,
        setEditorState,
        activeText,
        setActiveText,
        setBlockInfo,
        moveOn: triggerMoveOn,
        blockInfo,
      }}
    >
      {children}
    </EditorStateContext.Provider>
  );
};

export const EditorStateConsumer = EditorStateContext.Consumer;

export default EditorStateContext;
