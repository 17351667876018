import React, { useState } from "react";
import {
  ButtonVariant,
  useEditAllocation,
  EditAllocationProvider,
  LineIcon,
  Typography,
  PageMain,
  Breadcrumbs,
  useCurrentBreakpointName,
  PageMainLeft,
  PageMainRight,
  FilterTypeEnum,
  TickerFilters,
  Breakpoint,
  useSwitch,
  DialogVariant,
  HoldingType,
  AllocationTypeEnum,
} from "@potato/components"
import { useQuery, useQueryClient } from "react-query";
import useApi from "../../_contexts/api/useApi";
import { api_routes } from "../../_api/api-routes";
import { TickerResults, TickerResultsProps } from "../../views/tickers/TickerResults";
import { useTickerSearch, QueryFilterOption } from "../../views/tickers/use-ticker-search";
import { TickersPageResponse } from "../../_api/pages/tickers";
import AllocationDropdown from "./AllocationDropdown";
import { EditHoldingAllocation } from "./EditHoldingAllocation";
import { EditHoldings_Mobile } from "./EditHoldings.mobile";
import clsx from "clsx";
import { ReviewChanges } from "./ReviewChanges";
import { NoFooter } from "../no-footer/NoFooter";

export type UpdateHoldingsArgs = { id: string, holdings: HoldingType[], allocation_method: AllocationTypeEnum }

export interface EditHoldingsProps {
  id: string,
  holdings: HoldingType[],
  title: string,
  emoji: string,
  enable_filter_types?: FilterTypeEnum[]
  fixed_filters?: QueryFilterOption[]
  updateHoldings: (args: UpdateHoldingsArgs) => void

  // basket: BasketItemDetail,
  className?: string
  onClose: () => void
};

const EditHoldings = ({
  id, title, emoji, holdings,
  className,
  updateHoldings,
  onClose,
  fixed_filters,
  enable_filter_types = [FilterTypeEnum.all, FilterTypeEnum.stocks, FilterTypeEnum.crypto, FilterTypeEnum.etf],
}: EditHoldingsProps) => {
  const api = useApi()
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const queryClient = useQueryClient()
  const { activeItem: editViewActiveItem, onChange: editViewOnChange } = useSwitch<'edit_view' | 'changes_view'>('edit_view')
  const { selectedHoldings, allocation_method, reset, changesCount } = useEditAllocation()
  const [saving, setSaving] = useState(false)

  const getTickersPage = api.get({ url: api_routes.pages.tickers, waitForAuth: false })
  const { data: tickerPageData, isLoading: tickerPageLoading } = useQuery('ticker_filters', getTickersPage)
  const tickersPage = tickerPageData as TickersPageResponse
  const { isLoading, hasNextPage, fetchNextPage, items, setFilters, type } = useTickerSearch({ type: enable_filter_types[0], fixed_filters, filters_by_type: tickersPage ? tickersPage.filters : [] })

  const resultListProps: TickerResultsProps = {
    isLoading, hasNextPage, fetchNextPage, items, filter_type: type
  }

  const saveHoldings = async () => {
    setSaving(true)
    updateHoldings({ id, holdings: selectedHoldings, allocation_method })
    await queryClient.invalidateQueries(id)
    setTimeout(() => {
      setSaving(false)
      onClose()
    }, 1000)
  }

  if (!tickersPage) {
    return <div />
  }
  // if fixed filters override the filters instructions to lock that filter and update the title to the value of the locked filter item. ie: "Small Cap"
  // fixed_filter { type: "market_cap", value: "Small Cap"}
  // filter instruction { "title": "Market Cap", "value": "market_cap", "types": [ "all", "stocks", "crypto" ], "items": [ { "title": "Small Cap", "emoji": "", "value": "Small Cap" }, ... ] }
  const filters = fixed_filters ? tickersPage.filters.map((filter) => {
    // does this filter have a fixed_filter?
    const fixed = fixed_filters.find(fixed_filter => fixed_filter.type === filter.value)
    if (fixed) {
      return {
        ...filter,
        title: fixed.value,
        locked: true
      }
    }
    return filter
  }) : tickersPage.filters

  return (<div className={clsx(className, '  max-w-7xl mx-auto')} style={
    { height: isLarge ? `calc(100vh - 100px)` : `calc(100vh - 56px)` }
  }>
    <PageMain className="h-full px-8 pb-4">
      <PageMainLeft className="h-full" paddingBottom={' lg:pb-0'}>
        <div className="flex h-full flex-col">
          <div className="flex-1 order-last">
            <TickerResults selectable={true} className="h-full" {...resultListProps} />
          </div>
          <div className="flex-0">
            <Breadcrumbs items={[{
              prefix: emoji,
              space: '2',
              label: `${title}`
            }]}
            />
            <Typography variant="h2" className="mt-4 lg:mb-4 mb-1 text-left lg:text-left">{holdings.length ? 'Edit Holdings' : 'Add Holdings'}</Typography>
            <TickerFilters layout="stacked" currentType={type || FilterTypeEnum.all} enable_filter_types={enable_filter_types} filters={filters} onChange={setFilters} />
          </div>
        </div>

      </PageMainLeft>
      <PageMainRight className="h-full">
        <div className="flex flex-col h-full ">
          <div className="flex-0">
            <div className="flex space-x-4 items-center mt-4 justify-end">
              <ButtonVariant.Text iconLeft={<LineIcon icon="cross" />} label="Cancel" className="mr-4" onClick={onClose} />
              <ButtonVariant.Cta disabled={changesCount === 0} iconLeft={<LineIcon icon={saving ? "loading" : "check"} className={saving ? "animate-spin" : ''} />} label={saving ? "Saving..." : "Apply Changes"} onClick={saveHoldings} />
            </div>
            <div className="flex space-x-4 mt-12 mb-12">
              <AllocationDropdown />
              <div className="flex justify-end flex-0 items-center">
                {changesCount === 0 && <Typography className="text-secondary">0 Changes</Typography>}
                {!!changesCount && editViewActiveItem === 'edit_view' && <ButtonVariant.Text onClick={() => editViewOnChange('changes_view')} label="See Changes" className="underline" />}
                {!!changesCount && editViewActiveItem === 'changes_view' && <ButtonVariant.Text onClick={() => editViewOnChange('edit_view')} label="Edit Changes" className="underline" />}
              </div>
            </div>
          </div>
          <div className="flex-1">
            {editViewActiveItem === 'edit_view' ? <EditHoldingAllocation /> : <ReviewChanges holdings={holdings} />}
          </div>
        </div>
      </PageMainRight>
    </PageMain>
  </div>
  )
}

const EditHoldingsWithProvider = (props: EditHoldingsProps) => {
  return (
    <NoFooter>
      <EditAllocationProvider items={props.holdings} >
        <Breakpoint large up>
          <EditHoldings {...props} />
        </Breakpoint>

        <Breakpoint medium down>
          <DialogVariant.Boxed onClose={props.onClose} open={true} closeButton={false} spaced={false}>
            <EditHoldings_Mobile {...props} />
          </DialogVariant.Boxed>
        </Breakpoint>

      </EditAllocationProvider>
    </NoFooter>
  )
}
export default EditHoldingsWithProvider;
