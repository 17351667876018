import React, { createRef, useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import 'draft-js/dist/Draft.css';
import AutoSizer from 'react-virtualized-auto-sizer'
import useTickerAutocomplete from "../../contexts/ticker-autocomplete/ticker-autocomplete-hook";
import Scrollbars from "react-custom-scrollbars";
import { GlobalUISizesEnum, LineIcon, Ticker, Typography, useOutsideClick, usePortalElement } from "@potato/components";
import { Listbox } from '@headlessui/react'
import { usePopper } from "react-popper";
import './ticker-autocomplete.styles.scss';
import clsx from "clsx";
import useEditorState from "../../contexts/editor-state/editor-state-hook";

export interface TickerAutocompleteProps {
};

const TickerAutocomplete = ({

}: TickerAutocompleteProps) => {
  const { tickers, confirmTicker, loading } = useTickerAutocomplete()
  const { activeText, blockInfo, moveOn } = useEditorState()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const editorFocused = document.activeElement && document.activeElement.classList.contains('public-DraftEditor-content')
  const { portalElement, loaded } = usePortalElement()
  const { outsideClickCount } = useOutsideClick({ refs: [popperElement || undefined], shouldListen: !!activeText })
  const activeRef = document.getElementById(blockInfo?.elementId!)
  const { styles, attributes } = usePopper(activeRef, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, { name: 'offset', options: { offset: [0, 8] } }],
  });
  const activeItem = tickers.find(item => item.ticker === activeText)
  useEffect(() => {
    if (outsideClickCount) {
      moveOn()
    }
  }, [outsideClickCount])
  useEffect(() => {
    const upDownFunction = (event: KeyboardEvent) => {
      if (activeText && event.keyCode === 27) {
        moveOn()
      }
      if ([38, 40].includes(event.keyCode) && !!activeText && !!buttonElement && editorFocused) {
        buttonElement.click()
      }
    }
    document.addEventListener("keydown", upDownFunction, false);
    return () => {
      document.removeEventListener("keydown", upDownFunction, false);
    };
  }, [buttonElement, activeText])

  const popover = (
    <>
      {!!activeText && !loading && <div id="popper"
        className="bg-lifted rounded-md shadow-2xl z-70 py-2"
        style={{ ...styles.popper, height: (tickers.length > 10 ? 300 : (tickers.length||1) * 33) + 16, width: 300 }}
        {...attributes.popper}
        ref={setPopperElement}
      >

        <AutoSizer>
          {({ height: _height, width: _width }) => {
            return <div style={{ height: _height, width: _width }}>
              <Scrollbars autoHide={true} height={_height}>
                <Listbox value={activeItem} onChange={confirmTicker}>

                  <Listbox.Button className={'absolute h-0 w-0 opacity-0'} ref={setButtonElement}>{activeText}</Listbox.Button>
                  <Listbox.Options static className="list-options focus-visible:ring-0">
                    <div className='flex flex-col'>
                      {!tickers.length && <Typography className='my-1 py-2 px-3 line-clamp-1'>Ticker not found</Typography>}
                      {tickers.map((item) => {
                        return <Listbox.Option key={item.id} value={item} >
                          {({ active, selected }) => (
                            <div key={item.id} className={clsx(
                              active && 'bg-pill',
                              'pl-2 pr-4 py-1 flex space-x-2 items-center space-y-1 cursor-pointer hover:bg-lifted-highlight'
                            )}>
                              <Ticker clickable={false} item={item} size={GlobalUISizesEnum.XSMALL} />
                              <Typography className='my-0 line-clamp-1'>{item.title}</Typography>
                              {selected && <div className="flex-0"><LineIcon icon="check" /></div>}
                            </div>
                          )}</Listbox.Option>
                      })}
                    </div>
                  </Listbox.Options>
                </Listbox>
              </Scrollbars>
            </div>
          }}
        </AutoSizer>
        {!!tickers.length && <div id="arrow"
          ref={setArrowElement} style={styles.arrow}
        ></div>}
      </div>}
    </>
  );

  if (!portalElement && loaded) {
    console.warn('FullView: modal-root element not found')
    return <div />
  } else {
    if (portalElement) {
      return ReactDOM.createPortal(
        popover,
        portalElement
      );
    }
    return <></>
  }
};

export default TickerAutocomplete;