import React, { FC, useState } from "react"
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMountedRef } from '../../_utils/is-mounted-ref'
import { Container, Typography } from "@potato/components";
import SocialButton from './SocialButton'
import PageMeta from "../../components/page-meta";

interface LoginProps {
  className?: string;
}
const Login: FC<LoginProps> = ({ className, ...rest }) => {
  const { loginWithRedirect } = useAuth0() as any;
  const [error, setError] = useState<string | null>(null);
  const isMountedRef = useIsMountedRef();

  const handleLogin = (connection:string) => async (): Promise<void> => {
    try {
      await loginWithRedirect({ connection });
    } catch (err) {
      console.error(err);
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };



  (window as any).prerenderReady = true;

  return (
    <Container>
      <PageMeta defaultData={{ title: "Sign in | Potato" }} />

      <div className="p-4 mb-8 m-auto"
        style={{ maxWidth: 330 }}>

        <Typography variant="h2" className="text-center lg:mb-5 mb-2 mt-8">Sign in</Typography>
        <SocialButton
          label="Sign in with Google"
          onClick={handleLogin('google-oauth2')}
        />
        <SocialButton
          connection="apple"
          label="Sign in with Apple"
          onClick={handleLogin('apple')}
        />


        {error && (
          <Typography variant="p" className="text-red">
            {error}
          </Typography>
        )}
        <div className="pt-8">
          <Typography className="inline">By continuing, you agree to Potato's</Typography>
          <Typography href="/terms-of-service" className="font-bold inline pr-1"> Terms of Use</Typography>
          <Typography className="inline pr-1">and confirm that you have read Potato's</Typography>
          <Typography href="/privacy" className="font-bold inline pr-1">Privacy Policy.</Typography>
        </div>
      </div>
    </Container>
  )
}

export default Login