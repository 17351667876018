import React from "react";
import { ButtonVariant, Typography, GlobalUISizesEnum, LineIcon, LazyLoadBackground, useThemeContext, BasketCard } from '@potato/components'
import clsx from 'clsx'
const bg1 = 'https://s3.amazonaws.com/static.potato.trade/component-assets/uncharted-bg-light.png'
const bg2 = 'https://s3.amazonaws.com/static.potato.trade/component-assets/uncharted-bg-dark.png'
const bg3 = 'https://s3.amazonaws.com/static.potato.trade/component-assets/uncharted-bg-cyberpunk.png'
// import BasketCard from './BasketCard'

export interface EmptyPageStateProps  {
  className?:string
  bodyText?:string,
  emoji: string,
  picture: string,
};

const EmptyPageState = ({
 className,
 bodyText,
 emoji,
 picture,
}: EmptyPageStateProps) => {
  const { theme } = useThemeContext()
  const bg: {[x:string]: string} = {
    light: bg1,
    dark: bg2,
    cyberpunk: bg3
  }
  return (
   <div className={clsx('text-center relative py-20 -mx-4', className)}>
     <LazyLoadBackground src={bg[theme]} className="absolute z-0 bottom-0 bg-no-repeat bg-cover lg:bg-contain bg-bottom w-full h-full"/>

     <div className="z-10 mx-4 relative">
     <Typography variant="h2" className="mb-1">You are in uncharted territory...</Typography>
     <p className="text-lg mb-6">{bodyText || 'Be the first to publish content with this tag.'}</p>
     <ButtonVariant.Cta href="/basket/create" className="inline-block" size={GlobalUISizesEnum.LARGE} label={"Create Basket"} iconLeft={<LineIcon icon="plus" className="mr-px"/>} />

     <BasketCard className="w-80 mx-auto my-24 text-left" item={{
       id: '', 
       slug: '/',
       title: 'This could be yours...',
       user_username:'you',
       watch_count: 1000000,
       holding_count: 10,
       price: 8.58,
       picture: picture,
       movement_7d: 3.85,
       movement_24h: 3.85,
       movement_1y: 3.85,
       emoji: emoji,
       user_picture: '',
       user_slug: '',
       chart: null

     }} />
          </div>
   </div>
  );
};

export default EmptyPageState;