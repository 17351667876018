import {
  ButtonVariant,
  CopyToClipboard,
  FantasyDetailItemType,
  format,
  FormatEnum,
  GlobalUISizesEnum,
  Typography,
} from "@potato/components";
import clsx from "clsx";
import React from "react";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import { MemberGuard } from "./PaymentGuard";

export const NextStepCta: React.FC<{
  item: FantasyDetailItemType;
  className?: string;
  onClick?: () => void;
}> = ({ item, className }) => {
  const { setPageMode } = usePageMode();
  const myRanking = item.rankings && item.rankings.find((r) => r.is_mine);
  return (
    <MemberGuard item={item}>
      {
        item.is_mine && item.member_count === 1 ? (
          <div className="pb-6 md:pb-4 md:pt-6">
          
          <Typography variant="p"><>
          <span className="inline-block font-semibold mr-1">
            Next step:
          </span>
          <CopyToClipboard copy={window.location.origin + item.private_slug}>
         <span className="inline-block">
            Invite to group {' '}
            <span
              className="underline cursor-pointer"
            >
             via link
            </span>
            </span>
            </CopyToClipboard>
            </>
          </Typography>
      </div>
        ) : !(myRanking && myRanking.holdings && myRanking.holdings.length) ? (
          <div className="pb-6 md:pb-4 md:pt-6">
          
          <Typography variant="p"><>
          <span className="inline-block font-semibold">
            Next step:
          </span>
            <span
              className="underline cursor-pointer ml-1"
              onClick={() => {
                setPageMode("edit");
              }}
            >
            Add Holdings
            </span>{" "}
            to enter this round and start tracking.
            </>
          </Typography>
      </div>
        ) : (
          <></>
        )}
    </MemberGuard>
  );
};
