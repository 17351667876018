import React, { useEffect, useRef } from "react";
import {
  Dialog,
  useToggle,
  BasketHistory,
  useToggleContext,
  Loading,
  Breadcrumbs,
  DialogVariant,
  Typography,
  usePosition,
} from "@potato/components"
import { MenuListItemProps } from "@potato/components/lib/components/MenuListItem/MenuListItem";
import { ValueOf } from "../../../_types/value-of";
import usePageMode from "../../../_contexts/page-mode/page-mode-hook";

const useViewHistory = () => {
  const { setSelected: setDropdownButtonOpen } = useToggleContext()
  const { setPageMode } = usePageMode()

  const onOpen = () => {
    setPageMode('history')
    setDropdownButtonOpen(false)
  }
  const itemConfig: ValueOf<Pick<MenuListItemProps, 'item'>> = {
    onClick: onOpen,
    label: 'View History',
    icon: 'history',
    value: ''
  }
  return {
    menuItem: itemConfig,
  };
};

export default useViewHistory;
