import {
  GlobalUISizesEnum,
  Typography,
  ButtonVariant,
  Breakpoint,
  RankingType,
  MovementChip,
  Avatar,
  BasketItemDetail,
  Watches,
  ShareButton,
  LineIcon,
} from "@potato/components";
import clsx from "clsx";
import "../fantasy/pulse.scss";
import React from "react";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import { useWatch } from "../../_utils/use-watch";

export const FixedBottomBar: React.FC<{
//   ranking: RankingType;
  item: BasketItemDetail,
  className?: string;
}> = ({ item, className }) => {
  const { setPageMode } = usePageMode();
  const {user} = usePotatoUser()
  const { onWatchToggleClick } = useWatch();
  
  return  (
    <Breakpoint medium down>
      <div className={clsx("h-16", className)} />
      <div className="fixed bottom-0  z-10 left-0 px-4 py-2 w-full bg-white top-shadow">
        <div className="flex items-center justify-center space-x-2">
        <ShareButton
              className={"mr-1 mb-1"}
              size={
                GlobalUISizesEnum.MEDIUM
              }
            />
       
          <Watches
              className="mr-1 mb-1"
              size={
               GlobalUISizesEnum.MEDIUM
              }
              onWatch={onWatchToggleClick(item.id, !!item.is_watched)}
              variant="outline"
              watched={item.is_watched}
              count={item.watch_count}
            />
        
            <ButtonVariant.Outline
              iconLeft={
             
                 !!item.comment_count ? <span className="bg-black rounded-full px-2 py-1 -ml-2 text-white text-sm">
                {item.comment_count}
                  </span> :<LineIcon icon="bubble"/>
              
              }
              label="Chat"
              onClick={() => {
                setPageMode("chat");
              }}
            />
        </div>
      </div>
    </Breakpoint>
  );
};
