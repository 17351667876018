import { Typography, TagList, Container, TagType, LineIcon, Breakpoint, ButtonVariant } from '@potato/components'
import { useHistory } from 'react-router-dom';
import { BasketsSectionItem } from "../../../_api/pages/home";

export interface TagProps {
  item: BasketsSectionItem
};

const Tag = ({
  item
}: TagProps) => {

  return (

    <Container  className="lg:py-6 py-6  mt-4 lg:mt-12">
      <div className="flex items-center">
       {item.title && <div className="flex-auto">
          <Typography variant="h3" element="h2" className="my-8">{item.title}</Typography>
        </div>}
         {item.slug && (
           <div className="flex-initial">
            <ButtonVariant.Pill iconRight={<LineIcon className="block" icon="chevron-right" />} label="See all" href={item.slug} />
          </div>
           )} 
      </div>
      {/* <div className="flex flex-col md:flex-row md:space-x-6 "> */}
        <TagList center={true} items={item.tags as TagType[]} />
      {/* </div> */}
    </Container >
  );
};

export default Tag;