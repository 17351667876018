import { ResponseStatus } from "../response-status"
import { CategoryType, BasketItem, TagType } from "@potato/components"
import { MetaTags } from "../page-meta"
export enum BasketsSectionType {
  GainersLosers = "gainers-losers",
  Baskets = "baskets",
  News = "news",
  Categories = "categories",
  Tags = "tags",
}
export type BasketsSectionItem = {
  section_type: BasketsSectionType,
  slug: string
  title: string
  description?:string
  baskets: BasketItem[] | null
  gainers: BasketItem[] | null
  losers: BasketItem[] | null
  news_left: any[] | null
  news_right: any[] | null
  categories: CategoryType[] | null
  tags: TagType[] | null
}
export type BasketsPageResponse = ResponseStatus & MetaTags & {
  sections: BasketsSectionItem[]
}