import { ContentState } from 'draft-js';
import React, { useEffect, useState } from 'react'
import useEditorState from '../../contexts/editor-state/editor-state-hook';
import useTickerHandlesStore from '../../contexts/ticker-handles-store/ticker-handles-hook'
import useTickerAutocomplete from '../../contexts/ticker-autocomplete/ticker-autocomplete-hook';
import { Strategy } from "../types";
import { findWithRegex } from "../utils";
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import './ticker-handle.styles.scss';

export const TICKER_HANDLE_REGEX = /\$x:([\w]{0,30})|\$([\w]{1,30})/g;

export const preTickerHandleStrategy: Strategy = (contentBlock, callback, contentState) => {
  findWithRegex(TICKER_HANDLE_REGEX, contentBlock, callback);
}

export const PreTickerHandle = ({ children, decoratedText, blockKey, offsetKey, ...props }: { children: React.ReactNode, decoratedText: string, contentState: ContentState, entityKey?: string, start: number, end: number, offsetKey?: string, blockKey: string }) => {
  const [isActive, setIsActive] = useState(false)
  const { editorState, readOnly, setActiveText, setBlockInfo, blockInfo, edited } = useEditorState()
  const { loading } = useTickerAutocomplete()
  const { tickers, handleAddTicker } = useTickerHandlesStore()
  var selectionState = editorState.getSelection();
  const currentBlockKey = selectionState.getAnchorKey()
  var start = selectionState.getStartOffset();
  var end = selectionState.getEndOffset();
  const tickerData = tickers[decoratedText.replace('$', '').trim().toUpperCase()]
  const reset = () => {
    setIsActive(false)
    setActiveText(null)
    setBlockInfo(null)
  }

  useEffect(() => {
    return reset
  }, [])

  useEffect(() => {
    const activateAutocomplete = () => {
      setIsActive(true)
      setActiveText(decoratedText)
      setBlockInfo({ start: props.start, end: props.end, key: blockKey, elementId: offsetKey! })
    }

    const next_isActive = blockKey === currentBlockKey && start >= props.start && end <= props.end && end !== 0
    if (next_isActive) {
      activateAutocomplete()

    } else {
      if (blockInfo?.elementId === offsetKey) {
        if (edited) {
          const thisTicker = decoratedText.replace('$', '').toUpperCase()
          handleAddTicker(tickerData || thisTicker)
        }
        reset()
      }
    }

  }, [start, end, props.start, props.end, decoratedText, currentBlockKey])
  return <>
    <span id={offsetKey} className="pre-ticker-handle inline-block relative " >
      {!isActive && !!tickerData && (
        <Link to={tickerData.slug!} target="_blank" className={clsx(!readOnly ? 'pointer-events-none' : 'z-10', 'ticker-handle-chip absolute no-underline text-black light:text-white font-semibold rounded-sm')} style={{
          background: tickerData.color,

        }}>
          {tickerData.ticker}
        </Link>
      )}
      <span className={clsx(loading && isActive && 'animate-pulse', !isActive && !!tickerData && 'opacity-0', "ticker-handle-text text-primary uppercase")} >{children}</span>
    </span>
  </>
}