import { ButtonVariant, LineIcon, Typography } from "@potato/components";
import clsx from "clsx";
import React from "react";
import { useUpgradeVersionDialog } from "./use-version-dialog";

export interface AIUpgradeProps  {
  id: string;
  prompt: string;
  model: string;
  className?:string
};

const AIUpgrade = ({
  id,
  prompt,
  model,
  className
}: AIUpgradeProps) => {
  const {dialog, toggleDialog} = useUpgradeVersionDialog()
  return (
    <div className={clsx(className, "flex items-center space-x-4")}>
      <div className="inline-block rounded-full bg-purple bg-opacity-10 p-2 pl-4">
        <div className="flex items-center space-x-3">
          <Typography className="font-semibold text-purple">
            New AI model available
          </Typography>
          <Typography className="">{model}</Typography>
          <ButtonVariant.Cta
            href={`/basket/upgrade/${id}/${encodeURI(prompt)}`}
            classes={{
              default: "bg-purple light:text-white text-black",
              active: "bg-purple light:text-white text-black",
              disabled: "opacity-60 cursor-default",
              hover: "hover:bg-purple-dark",
            }}
            label="Upgrade Basket"
            iconLeft={<LineIcon icon="sync" />}
          />
        </div>
      </div>
      <div className="inline-block">
        <Typography onClick={toggleDialog} className="underline cursor-pointer">More info...</Typography>
        {dialog}
      </div>
    </div>
  );
};
export default AIUpgrade;