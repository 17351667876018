import { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom"
import { useSwitch } from "@potato/components"
import { removeLastPath } from "./remove-last-path";

export const useSectionParam = (available_sections: string[], default_section: string) => {
  const [section, setSection] = useState<string>(default_section)
  const params = useParams<{ section?: string }>()
  const location = useLocation()
  const history = useHistory()
  const activeSection = section && available_sections.includes(section) ? section! : default_section
  const { onChange, activeItem } = useSwitch(activeSection)
  const uriParam = encodeURI(params.section ? removeLastPath(location.pathname) : location.pathname)
  useEffect(() => {
    if (params.section) {
      setSection(params.section)
      // history.replace(uriParam)
    }
  }, [params.section])

  return {
    onChange,
    activeItem,
    uriParam
  };
}