import React from "react";
import { FantasiesSectionItem, FantasyLandingSectionType } from "../../_api/pages/fantasy-landing";
import Leagues from "./Leagues";


export interface SectionProps  {
  item: FantasiesSectionItem
};

const SectionComponents = {
  [FantasyLandingSectionType.PublicFantasies]: Leagues,
}
/**
 * Primary UI component for user interaction
 */
const Section = ({
 item
}: SectionProps) => {
  if (typeof SectionComponents[item.section_type] !== "undefined") {
    return React.createElement(SectionComponents[item.section_type], {
      item,
      key: item.slug
    });
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div className="text-center">The component "{item.section_type}" has not been created yet.</div>,
    { key: item.slug }
  );
};

export default Section;