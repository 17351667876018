import React from "react";
import { Container, Markdown, Loading, PageMainLeft } from "@potato/components";
import useApi from "../../_contexts/api/useApi";
import {
  useQuery,
} from 'react-query'
import { api_routes } from "../../_api/api-routes";
import { MarkdownContentResponse } from "../../_api/markdown-content";
import PageMeta from "../../components/page-meta";

const Careers = () => {
  const { get } = useApi()
  const getCareers = get({ url: api_routes.stealth.pages.careers, waitForAuth: false })
  const _data = useQuery('careers', getCareers)
  const data = _data.data as MarkdownContentResponse
  if (!data) {
    return <Loading className="mt-32" />
  }
  (window as any).prerenderReady = true;
  return (
    <Container className="mb-20 " >
      <PageMeta data={data.meta_tags} defaultData={{ title: "Careers | Potato" }} />
      <PageMainLeft className="mx-auto mt-8">
        {!!data.body && (
          <Markdown>
            {data.body}
          </Markdown>
        )}
      </PageMainLeft>
    </Container>
  );
};

export default Careers;
