import {  Container, ActiveFantasiesTable } from '@potato/components'
import { FantasiesSectionItem } from "../../../_api/pages/fantasies";

export interface NewsProps {
  item: FantasiesSectionItem
};

const UserFantasies = ({
  item
}: NewsProps) => {
if(!item.fantasies) {
  return <></>
}
  return (

    <Container  className="lg:py-12 py-6">
      <ActiveFantasiesTable items={item.fantasies}/>
    </Container >
  );
};

export default UserFantasies;