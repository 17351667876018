import React, { Fragment } from "react";
import UserFantasiesTable from './UserFantasiesTable'
import { FantasiesSectionItem, FantasiesSectionType } from "../../../_api/pages/fantasies";
import LatestWinners from "./LatestWinners";
import PublicFantasies from "./PublicFantasies";
import TradeActivity from "./TradeActivity";

export interface SectionProps  {
  item: FantasiesSectionItem
};

const SectionComponents = {
  [FantasiesSectionType.Fantasies]: UserFantasiesTable,
  [FantasiesSectionType.PublicFantasies]: PublicFantasies,
  [FantasiesSectionType.LatestWinners]: LatestWinners,
  [FantasiesSectionType.TradeActivity]: TradeActivity,
}
/**
 * Primary UI component for user interaction
 */
const Section = ({
 item
}: SectionProps) => {
  if (typeof SectionComponents[item.section_type] !== "undefined") {
    return React.createElement(SectionComponents[item.section_type], {
      item,
      key: item.slug
    });
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div className="text-center">The component "{item.section_type}" has not been created yet.</div>,
    { key: item.slug }
  );
};

export default Section;