import React from "react"
import clsx from 'clsx'
import {
  Avatar,
  Badge,
  ButtonVariant,
  FormatEnum,
  GlobalUISizesEnum,
  LineIcon,
  Typography,
  useCurrentBreakpointName,
  UserType,
  format,
  BotBadge
} from "@potato/components"
import { Link } from "react-router-dom";
import { useFollow } from "../../../_utils/use-follow";

export const Author = ({ item, className, is_mine }: { item: UserType, className?: string, is_mine:boolean }) => {
  const name = useCurrentBreakpointName();
  const isLarge = ['large', 'xlarge'].includes(name);
  const { followerCount,  followButton } = useFollow(item ? !!item.is_following : false, item ? item.follower_count || 0 : 0, item.id)

  return <div className={className}>
    <div className={clsx("flex space-x-4",  "items-center")}>
      <div className="flex-initial relative">
        <Link to={item.slug}><Avatar avatar={item.picture} size={isLarge ? GlobalUISizesEnum.XLARGE : GlobalUISizesEnum.LARGE} /></Link>
        {!!item.is_bot && <div className="absolute bottom-0 right-0  ">
          <BotBadge className="scale-125 rounded-full bg-white ring-2 ring-white" />
        </div>}
      </div>
      <div className="flex-1">
        <div className="flex items-center space-x-1 leading-6">
          <Typography href={item.slug} className="font-medium text-lg">Created by {item.username}</Typography>
        </div>
        <Typography className="text-secondary pt-1 line-clamp-1 break-all" variant="p">{item.bio || item.joined_at && `Joined ${format(item.joined_at, FormatEnum.Date)}` || ''}</Typography>
      </div>
    </div>
    <div className="flex flex-center items-center space-x-8 mt-4">
      <Typography>{item.basket_count || 0} Basket{!!(item.basket_count !== 1) && 's'}</Typography>
      <Typography>{followerCount} Follower{!!(item.follower_count !== 1) && 's'}</Typography>
      <Typography>{item.following_count || 0} Following{!!(item.following_count !== 1) && 's'}</Typography>
    </div>

    {!is_mine && <div className="mt-4">{followButton}</div>}
  </div>
}