import React from 'react'
import {
  ButtonVariant as Button,
  LineIcon,
  MenuList,
  Typography,
  UserAvatar,
  BasketLink,
  useCurrentBreakpointName,
  GlobalUISizesEnum,
  FantasyLink,
  UserDetailType,
  MovementChip,
  AIGeneratedTag,
  HumanGeneratedTag,
  usePageStatus,
  useTruncate,
} from "@potato/components";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ItemConfig } from "@potato/components/lib/components/MenuListItem/MenuListItem";
import clsx from 'clsx';
import { makeItemConfig } from './UserMenu';



export const UserFantasyMenu = ({user}: {user: UserDetailType}) => {
    const { pathname } = useLocation();
      const name = useCurrentBreakpointName();
      const isLarge = ["large", "xlarge"].includes(name);
  const fantasies =
  user.fantasies && user.fantasies.map(makeItemConfig(pathname, isLarge)) 
const fantasies_public =
  user.fantasies_public && user.fantasies_public.map(makeItemConfig(pathname, isLarge))
 
  const fantasyMenuItems = [
     (
        <div className="flex items-center px-6 pt-6 pr-3">
          <Typography
            className=" flex-1 text-primary font-bold uppercase text-base"
            href={"/fantasy"}
          >
            Fantasy
          </Typography>
          
        </div>
      ),
     {
        icon: "plus",
        label: "Create Fantasy",
        value: "/fantasy/create",
      },
      // ...(fantasies as ItemConfig[] || []),
      fantasies && (
        <Typography variant="h5" className="px-6 pt-3 pb-0.5">
          Private
        </Typography>
      ),
      ...((fantasies as ItemConfig[]) || []),
      fantasies_public && (
        <Typography variant="h5" className="px-6 pt-3 pb-0.5">
          Public
        </Typography>
      ),
      ...((fantasies_public as ItemConfig[]) || []),
    ]
  return <>
      <MenuList
        className="flex-1"
        isCard={false}
        items={fantasyMenuItems}
      />
  </>
}