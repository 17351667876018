import { BasketItemDetail, GlobalUISizesEnum, TagList, Typography, useCurrentBreakpointName } from "@potato/components";
import clsx from "clsx";
import React from "react";

export interface TagsSectionProps {
  item: BasketItemDetail
  className?:string
};

/**
 * Primary UI component for user interaction
 */
const TagsSection = ({
  item,
  className
}: TagsSectionProps) => {
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  if (item.is_queued_for_art || item.is_published) {
    return (
      <>
        {!!item.holdings.length && !!item.tags && !!item.tags.length && (
          <>
            <TagList size={isLarge ? GlobalUISizesEnum.MEDIUM : GlobalUISizesEnum.SMALL} items={item.tags} center={false} className={clsx(className)} />
          </>
        )}</>
    );
  } else {
    return <></>
  }
};

export default TagsSection;