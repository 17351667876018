import {
  CategoryType,
  EmojiTitle,
  useTruncate,
  Container,
  Typography,
  Breadcrumbs,
  TagList,
  BasketCardList,
  GlobalUISizesEnum,
  useCurrentBreakpointName,
  PageTop,
  PageMain,
  PageMainLeft,
  PageMainRight,
  Markdown
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import React from "react"
import {
  useQuery,
} from 'react-query'
import { useLocation } from "react-router-dom"
import { api_routes } from "../../_api/api-routes";
import { CategoryPageResponse } from "../../_api/pages/category";
import { LineIcon, Loading } from "@potato/components";
import WatchBasketPriceUpdate from '../../hoc/watch-basket-updates'
import { useEmojiFavicon } from "../../_utils/use-emoji-favicon"
import PageMeta from "../../components/page-meta"
import { useWatch } from "../../_utils/use-watch"

const Category: React.FC = () => {
  const api = useApi()

  const { watchFn } = useWatch()
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const location = useLocation()
  const uriParam = encodeURI(location.pathname)
  const getCategory = api.get({ url: api_routes.pages.category + uriParam, waitForAuth: false, redirect404: true })
  const _categoryResponse = useQuery(location.pathname, getCategory)
  const category = _categoryResponse.data as CategoryPageResponse

  useEmojiFavicon(category && category.emoji)
  const [truncateOtherCategoriesButton, truncatedOtherCategoriesItems] = useTruncate((category && category.other_categories) || [], isLarge ? 8 : 4)
  const [truncateBasketsButton, truncatedBasketsItems] = useTruncate((category && category.baskets) || [], isLarge ? 12 : 4)
  const isLoading = _categoryResponse.isLoading

  if (isLoading) {
    return <div className="relative z-10"><Loading className="mt-32" /></div>
  }
  if (!category) {
    return <div />
  }

  (window as any).prerenderReady = true;
  
  const items = [{
    label: 'Browse'
  }, {
    prefix: <LineIcon icon="library" />,
    label: 'Categories',
    value: '/categories'
  }];
  
  return (
    <Container>
      <PageMeta data={category.meta_tags} defaultData={{title:category.title + ' | Category'}}/>
      <PageTop>
        <Breadcrumbs items={items} divider="|" />
      </PageTop>
      <PageMain>
        <PageMainLeft>
          <EmojiTitle variant="h2" element="h1" emoji={category.emoji} title={category.title} wrapperClassName="mb-6" />
          {!!category.description && (
            <Markdown>{category.description}</Markdown>
          )}

          <Typography variant="h3">
            Tags
          </Typography>
          <TagList size={GlobalUISizesEnum.SMALL} center={false} items={category.tags || []} truncateCount={15} className="mt-5" />
          {category.baskets && !!category.baskets.length && (
            <>
              <div className="flex space-x-8 mt-8 lg:mt-10">
                <div className="flex-1">

                  <Typography variant="h3">
                    Related Baskets
                  </Typography>
                </div>
              </div>
              <BasketCardList onWatch={watchFn} itemWrapper={WatchBasketPriceUpdate} className="mt-5" itemClassName="sm:w-1/2 w-full" items={truncatedBasketsItems as any[]} />
              {truncateBasketsButton}
            </>
          )}
        </PageMainLeft>
        <PageMainRight>
          <Typography variant="h3" className="mb-6 mt-8 md:mt-0">
            Other Categories
          </Typography>
          {(truncatedOtherCategoriesItems as CategoryType[]).filter(c => c.id !== category.id).map(c => {
            return (<>
              <EmojiTitle variant="h4" element="a" className="cursor-pointer" slug={c.slug} emoji={c.emoji} title={c.title} />
              <Typography className="text-secondary line-clamp-2 mb-5">
                {c.tag_text}
              </Typography>
            </>)
          })}
          {truncateOtherCategoriesButton}
        </PageMainRight>
      </PageMain>
    </Container>
  )

}

export default Category