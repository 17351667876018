import { ButtonVariant, Container, FantasyCardList, FantasyWinners, LineIcon, Typography } from '@potato/components'
import { FantasiesSectionItem } from "../../../_api/pages/fantasies";

export interface NewsProps {
  item: FantasiesSectionItem
};

const PublicFantasies = ({
  item
}: NewsProps) => {
  if (!item.fantasies) {
    return <></>
  }
  return (

    <Container className="lg:py-12 py-6">
      <div className="flex items-center">
        <div className="flex-auto">
          <Typography  variant="h2" element="h2" className="my-8 mb-12">{item.title}</Typography>
        </div>
        {item.slug && (
          <div className="flex-initial">
            <ButtonVariant.Pill iconRight={<LineIcon className="block" icon="chevron-right" />} label="See all" href={item.slug} />
          </div>
        )}
      </div>
        <FantasyCardList top_right_info='duration' columnClasses='md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 ' items={item.fantasies} />
    </Container >
  );
};

export default PublicFantasies;