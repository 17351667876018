import {
  Container,
  Typography,
  PageMain,
  PageMainLeft,
  PageMainRight,
  Markdown,
  Avatar,
  GlobalUISizesEnum,
  Ticker as TickerChip,
  getPriceData,
  useCurrentBreakpointName,
  TickerDetail,
  Breakpoint,
  PriceUpdateData,
  PriceData,
  KeyStats,
  LazyLoadBackground,
  useThemeContext
} from "@potato/components"
import React from "react"
import clsx from 'clsx'
import { ValueOf } from '../../_types/value-of'

import { BreadcrumbsProps } from "@potato/components/lib/components/Breadcrumbs/Breadcrumbs"

import FeaturedIn from "./featured-in"
import RelevantTags from "./relevant-tags"
import RecentReturns from "../../components/recent-returns"
import News from '../../components/news'
import Meta from "./meta"
import { PriceMetrics } from "../../components/price-metrics/PriceMetrics"
import ChartSection from "./chart/ChartSection"

const TickerLayout: React.FC<{ item: TickerDetail & Partial<PriceUpdateData> & PriceData }> = ({ item }) => {
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const { theme } = useThemeContext()
  const items: ValueOf<Pick<BreadcrumbsProps, 'items'>> = []
  if (item.locale_description) {
    items.push({
      className: 'whitespace-nowrap',
      label: item.locale_description,
    })
  }

  if (item.exchange_description) {
    items.push({
      className: 'whitespace-nowrap',
      label: item.exchange_description,
    })
  }

  if (item.ceo) {
    items.push({
      className: 'whitespace-nowrap mr-3',
      prefix: item.ceo_picture ? <Avatar avatar={item.ceo_picture} alt={`${item.ceo}, ${item.title} CEO`} size={isLarge ? GlobalUISizesEnum.MEDIUM : GlobalUISizesEnum.SMALL}></Avatar> : undefined,
      label: `${item.ceo} (CEO)`,
    })
  }

  const price_data = getPriceData(item)

  return <>
    <Container>
      <PageMain className="mt-0 md:mt-4 lg:mt-14">
        <PageMainLeft>
          {!isLarge && item.picture && (
            <div className={clsx("z-0 bg-cover rounded-lg relative overflow-hidden w-full mt-0")} style={{ height: '53.3%', paddingTop: '53.3%' }}>
              <LazyLoadBackground
                src={theme === 'cyberpunk' ? item.picture_cyberpunk  || item.picture : item.picture}
                className={clsx("bg-cover absolute inset-x-0 inset-y-0 bg-no-repeat bg-center bg-pill-hover")}
              >
                <img className={clsx("block opacity-0")} src={item.picture} alt={item.title} loading="lazy" />
              </LazyLoadBackground>
            </div>
          )}

          <div className={clsx(item.picture && " -mt-4", "z-10 relative mx-auto lg:inline-block lg:mr-4 lg:float-left lg:mt-2 mb-8 lg:mb-3 text-center lg:text-left")}>
            <TickerChip
              className="ring-4 lg:ring-0 ring-white"
              size={GlobalUISizesEnum.LARGE}
              item={{ id: item.id, color: item.color, ticker: item.ticker, slug: item.slug }}
            />
          </div>
          <div className="inline text-center lg:text-left">
            <Typography variant="h1" >{item.title}</Typography>
          </div>
          <PriceMetrics className="mt-3 lg:justify-start justify-center " {...price_data} showPrice={true} />
          <Meta item={item} />
          {!!item.description && (
            <Markdown className="mt-4 lg:mt-8">{item.description}</Markdown>
          )}
          <ChartSection item={item}/>
          {/* <Performance id={item.id} type={'ticker'} chart_options={item.chart_options} /> */}
          <KeyStats holding_type_name={item.type} className="mt-8 grid grid-cols-2" key_stats={item.key_stats} price_data={price_data} />
          <Breakpoint medium down>
            <RecentReturns recent_returns={item.recent_returns} />
            <RelevantTags tags={item.tags} />
          </Breakpoint>
          <News news={item.news} showImages={true} />
          <FeaturedIn baskets={item.baskets} />
        </PageMainLeft>
        <PageMainRight>
          <Breakpoint large up>
            {item.picture && (
            <div className={clsx("bg-cover rounded-lg relative overflow-hidden w-full mt-12 mb-12")} style={{ height: '53.3%', paddingTop: '53.3%' }}>
              <LazyLoadBackground
                src={theme === 'cyberpunk' ? item.picture_cyberpunk  || item.picture : item.picture}
                className={clsx("bg-cover absolute inset-x-0 inset-y-0 bg-no-repeat bg-center bg-pill-hover")}
              >
                <img className={clsx("block opacity-0")} src={item.picture} alt={item.title} loading="lazy" />
              </LazyLoadBackground>
            </div>
            )}
            <RelevantTags tags={item.tags} />
            <RecentReturns recent_returns={item.recent_returns} />
          </Breakpoint>

        </PageMainRight>
      </PageMain>

    </Container>
  </>
}

export default TickerLayout