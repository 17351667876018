import React from "react"
import usePriceUpdateStore from "../../_contexts/price-update-store/usePriceUpdateStore"
import useBasketUpdateStore from "../basket-patch-store/useBasketUpdateStore"
import { manyPriceUpdatesMocker } from "./utils/mock-price-update";
import { manyBasketUpdatesMocker } from "../basket-patch-store/utils/mock-basket-update";
import { Typography } from "@potato/components";
import { WSEventEnum, TickerPriceUpdateMessage, BasketPriceUpdateMessage } from "../../_api/websocket";
type Props = { showButton: boolean }
const MockInitialData: React.FC<Props> = (props) => {
  const { basketIds, addBasketPriceUpdates, addTickerPriceUpdates, tickers } = usePriceUpdateStore()
  const { addBasketUpdates } = useBasketUpdateStore()
  const mockPriceUpdates = (event: React.MouseEvent) => {
    mockBasketPriceUpdates(basketIds)
    mockTickerPriceUpdates(tickers)
  }

  const mockBasketPriceUpdates = (ids?: string[]) => {
    const priceUpdates:BasketPriceUpdateMessage[] = manyPriceUpdatesMocker(ids || basketIds)().map(price_update => {
      return {
        event: WSEventEnum.PORTFOLIO_PRICE_UPDATE,
        body: price_update
      }
    })
    addBasketPriceUpdates(priceUpdates)
  }
  const mockTickerPriceUpdates = (ids?: string[]) => {
    const priceUpdates:TickerPriceUpdateMessage[] = manyPriceUpdatesMocker(ids || tickers)().map(price_update => {
      return {
        event: WSEventEnum.TICKER_PRICE_UPDATE,
        body: price_update
      }
    })
    addTickerPriceUpdates(priceUpdates)
  }

  const mockBasketUpdates = (ids?: string[]) => {
    const basketUpdates = manyBasketUpdatesMocker(ids || basketIds)()
    addBasketUpdates(basketUpdates)
  }

  (window as any)["mockBasketPriceUpdates"] = mockBasketUpdates;
  (window as any)["mockTickerPriceUpdates"] = mockTickerPriceUpdates;
  (window as any)["mockPriceUpdates"] = mockPriceUpdates;

  if (props.showButton) {
    return (
      <div className="display-block mt-4">
        <Typography onClick={mockPriceUpdates} variant="a">Trigger Price Update</Typography>
      </div>
    )
  }
  return <></>
}

export default MockInitialData