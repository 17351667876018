import { Typography, BasketCardListHorizontal, LineIcon, Container, BasketItem, Breakpoint, ButtonVariant, BasketCardList } from '@potato/components'
import { BasketsSectionItem } from "../../../_api/pages/home";
import { useHistory } from "react-router-dom";
import WatchBasketPriceUpdate from '../../../hoc/watch-basket-updates'
import { useWatch } from '../../../_utils/use-watch';

export interface BasketsProps {
  item: BasketsSectionItem
};

const Baskets = ({
  item
}: BasketsProps) => {
  const { watchFn } = useWatch()

  return (
    <Container className="lg:pt-12 pt-6 lg:pb-8 pb-4 ">
      <div className="flex items-center space-x-1">
       {item.title && <div className="flex-auto">
          <Typography variant="h3" element="h2" className="lg:my-8 my-4">{item.title}</Typography>
        </div>}
        {item.slug && (
          <>
         {item.slug && <div className="flex-initial">
            <ButtonVariant.Pill iconRight={<LineIcon className="block" icon="chevron-right" />} label="See more" href={item.slug} />
          </div>}
          </>
        )}
      </div>
      {item.description && <div className='md:w-2/3 mb-8 px-0.5'>
        <Typography variant="p">{item.description}</Typography></div>}
      <BasketCardList key_prefix={item.title} onWatch={watchFn} itemClassName="xl:w-1/4 md:w-1/3 sm:w-1/2 w-full" itemWrapper={WatchBasketPriceUpdate as any} items={item.baskets || [] as BasketItem[]} />
    </Container>
  );
};

export default Baskets;