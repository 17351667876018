import {
  Typography,
  ButtonVariant,
  UserType,
  Loading,
  UploadImage,
  Input, Textarea, LineIcon, Breakpoint
} from "@potato/components"
import React, { useRef, useState } from "react"
import { api_routes } from "../../_api/api-routes"
import Form from '../../components/form/Form'
import { useQueryClient } from "react-query";
type Props = {
  user?: UserType
}
const EditProfile: React.FC<Props> = ({ user, ...props }) => {
  const [resetImage, resetUploadImage] = useState<number>(0)
  const queryClient = useQueryClient()
  const formRef = useRef<HTMLFormElement>()
  if (!user) {
    return <div className="w-full relative"><Loading className="mt-32" /></div>
  }
  const clearForm = () => {
    if (formRef && formRef.current) {
      resetUploadImage(resetImage + 1)
      formRef.current.reset()
    }
  }

  const onSuccess = () => {
    queryClient.invalidateQueries('me')
    queryClient.invalidateQueries(user.slug)
  }

  return (<>
    <Typography variant="h3" className={"mb-8"}>Edit Profile</Typography>
    <Form ref={formRef} action={api_routes.actions.edit_profile} initialData={user} onSuccess={onSuccess}>
      {({ onchange_functions, data, status_text, error_message }) => {
        data = data as UserType
        const disabled = status_text !== 'Save Changes'
        const icon = status_text === 'Saving...' ?
          <LineIcon icon="loading" className="animate-spin block" /> :
          status_text === 'Saved' ?
            <LineIcon icon="check" className="block" /> :
            status_text === 'Failed to save' ?
              <LineIcon icon="warning" className="block" /> :
              undefined
        return (
          <>
            <Typography variant="p" className="my-2.5 font-semibold"><label htmlFor="profile-name">Username</label></Typography>
            <Input type="text" name="id" value={user.id} hidden style={{ maxWidth: '100%' }} id="profile-id" readOnly />
            <Input onChange={onchange_functions.input} type="text" name="username" value={data.username} className="w-72 mb-4" style={{ maxWidth: '100%' }} id="profile-username" />

            <Typography variant="p" className="my-2.5 font-semibold"><label htmlFor="profile-bio">Bio</label></Typography>
            <Textarea onChange={onchange_functions.input} name="bio" value={data.bio || undefined} style={{ maxWidth: '100%' }} id="profile-bio" />
            <Typography className="text-secondary my-2 mb-4">Your bio appears on your Profile and next to your stories. Max 160 characters.</Typography>

            <Typography variant="p" className="my-2.5 font-semibold"><label htmlFor="profile-photo">Photo</label></Typography>
            <UploadImage maxSize={5} resetImage={resetImage} name="picture" onChange={onchange_functions.file} className="mb-4" image={user.picture} />
            {error_message && <Typography className="text-red" variant="p">{error_message}</Typography>}
            <div className="flex space-x-2">
              <ButtonVariant.Pill className="my-4" label="Cancel" iconLeft={<LineIcon icon="cross" className="flex items-center" />} onClick={clearForm} />
              <ButtonVariant.Cta className="my-4 bg-black hover:black-light" disabled={disabled} label={status_text} submit={true} iconLeft={icon} />
            </div>
          </>
        )
      }
      }
    </Form>
  </>)
}

export default EditProfile