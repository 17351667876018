import React, { useEffect, useState } from 'react'

import { Redirect } from 'react-router-dom';

const LaunchMode = () => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    localStorage.setItem('potato_view_mode', 'launch')
    setReady(true)
  }, [])

  if(ready) {
    return <Redirect to="/"/>
  }
  return <></>
}

export default LaunchMode