import React from "react";
import {
  ButtonVariant,
  LineIcon,
  BasketItemDetail,
  EmojiTitle,
  GlobalUISizesEnum,
} from "@potato/components";
import Comments from "../../components/comments";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";

export interface CommentsProps {
  item: BasketItemDetail;
  width?: number;
  height?: number;
}

export const CommentsDialog = ({ item, width, height }: CommentsProps) => {
  const { setPageMode } = usePageMode();
  const emptyCommentText = (
    <>
      This is the very begining of this chat.
      <br /> Start the conversation, share your thoughts on these investment
      ideas.
    </>
  );
  return (
    <div className={"px-4 py-2 h-full flex-col"} style={{ height }}>
      <div className=" flex-0 flex items-center space-x-4 justify-end mb-2">
        <div className="flex-1">
          <EmojiTitle
            size={GlobalUISizesEnum.SMALL}
            title={item.title}
            emoji={item.emoji}
            className="text-secondary"
          />
        </div>
        <div className="flex-0">
          <ButtonVariant.Icon
            onClick={() => {
              setPageMode("main");
            }}
            label={<LineIcon icon="cross" className="text-lg" />}
            className="leading-none"
          />
        </div>
      </div>

      <div className="flex-1 flex-col">
        <Comments
          item={{ id: item.id }}
          type="basket"
          empty_comment={{ comment: emptyCommentText }}
        />
      </div>
    </div>
  );
};
