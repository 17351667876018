import React, { useEffect } from "react";
import { Container, Loading, PartnersPage, usePageStatus } from "@potato/components";
import PageMeta from "../../components/page-meta";


const Partners = () => {

    (window as any).prerenderReady = true;
    const { mainMenu } = usePageStatus()
    useEffect(() => {
        mainMenu.setMenuHidden(true)
        return () => {
            mainMenu.setMenuHidden(false)
        }
    }, [])
    return (<>
       <PageMeta data={{title: 'Potato Partners - AI platform for finance', description: 'Fantasy trading and content solutions.'}} />
            <PartnersPage/>
    </>
    );
};

export default Partners;