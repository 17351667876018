import { FilterTypeEnum, FilterOption as Option, HoldingType, Filter } from "@potato/components"
import { useEffect, useState } from "react"
import { useInfiniteQuery } from "react-query"
import { api_routes } from "../../_api/api-routes"
import useApi from "../../_contexts/api/useApi"
export type QueryFilterOption = { type: string, value: string }
type TickerFiltersValue = { filters: Option[], query: string, type: FilterTypeEnum }
type TickerFiltersQueryData = { query?: string, filters?: QueryFilterOption[]}

export const useTickerSearch = ({ type: _type, fixed_filters, filters_by_type }: {type: FilterTypeEnum, fixed_filters?: QueryFilterOption[], filters_by_type:Filter[]}) => {
  const [filters, setFilters] = useState<TickerFiltersQueryData>({filters: fixed_filters ? fixed_filters: []})
  const [type, setType] = useState<FilterTypeEnum>(_type)
  const api = useApi()
  
  const _setFilters = (ticker_filters: TickerFiltersValue) => {
    const query_filters: TickerFiltersQueryData = {
     
    }
    let t: FilterTypeEnum = type
    if (ticker_filters.query) {
      query_filters.query = ticker_filters.query
    }
    if (ticker_filters.type) {
      if (!query_filters.filters) { query_filters.filters = [] }
      query_filters.filters.push({
        type: 'type',
        value: ticker_filters.type
      });
      
      t = ticker_filters.type
    }

    if (ticker_filters.filters.length) {
      ticker_filters.filters.forEach(filter => {
        if (!query_filters.filters) { query_filters.filters = [] }
        query_filters.filters.push({
          type: filter.type!,
          value: filter.value
        })
      })
    }
    if(t !== type) {
      setType(t)
    }
    if(fixed_filters) {query_filters.filters = query_filters.filters ? query_filters.filters.concat(fixed_filters) : fixed_filters};
    setFilters(query_filters)
  }

  const getTickers = ({ pageParam = 0 }) => {
    return api.post({
      url: api_routes.search.tickers, data: {
        filters: filters.filters?.filter(f => {
          if(f.type === "type") { return true }
          const filter = filters_by_type.find(_f => _f.value === f.type)
          return filter && filter.types.includes(type)
        }),
        query: filters.query,
        cursor: pageParam
      }, waitForAuth: false
    })()
  }

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<any>(['tickers_search', type, filters], getTickers, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.next_cursor ? lastPage.next_cursor : undefined
    },
    enabled: !!filters.filters
  })


    let items: HoldingType[] = (data && data.pages.reduce((items, page) => {
      if (page.tickers) {
        items = items.concat(page.tickers)
      }
      return items
    }, [])) || []




  return {
    items,
    isLoading,
    fetchNextPage, 
    hasNextPage,
    setFilters: _setFilters,
    type
  }

}