import React, {
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import type { FC, ReactNode } from 'react';
import { HoldingType, TickerType } from '@potato/components';
import useEditorState from '../editor-state/editor-state-hook';
import { TICKER_HANDLE_REGEX } from '../../decorators/ticker-handle/PreTickerHandle'
import { useQuery } from 'react-query';
import useApi from '../../../../_contexts/api/useApi';
import { api_routes } from '../../../../_api/api-routes';
import { debounce } from 'lodash';
type TickerMap = { [x: string]: TickerType }

export interface TickerHandlesContextValue {
  tickers: TickerMap
  addTicker: (ticker: TickerType) => void
  handleAddTicker: (ticker: TickerType | string) => Promise<void>
  onTickerAdd: (item: HoldingType) => void
}

interface TickerHandlesProviderProps {
  children?: ReactNode,
  onTickerAdd: (item: HoldingType) => void
}
const TickerHandlesContext = createContext<TickerHandlesContextValue>({
  tickers: {},
  addTicker: () => { },
  handleAddTicker: () => new Promise(() => {}),
  onTickerAdd: () => { },
});

export const TickerHandlesProvider: FC<TickerHandlesProviderProps> = ({
  children,
  onTickerAdd
}) => {
  const [tickers, _setTickers] = useState<TickerMap>({})
  const [tickersMatched, setTickersMatched] = useState<string>('')
  const api = useApi()
  const { mdValue, loading, edited } = useEditorState()
  const fetchTickers = (queryString: string) => api.get({ url: `${api_routes.search.tickersExact}${queryString}`, waitForAuth: false })()
  const { data, isLoading } = useQuery([`get_${tickersMatched}`, tickersMatched], () => fetchTickers(tickersMatched), {
    enabled: !!tickersMatched.length && !loading
  })
  // when switching baskets, loading state from editor state context is set to true. 
  useEffect(() => {
    if (loading) {
      reset()
    }
  }, [loading])

  const reset = () => {
    setTickersMatched('')
    _setTickers({})
  }

  const setTickers = (_tickers: TickerMap) => {
    _setTickers(_tickers)
  }

  useEffect(() => {
    if (!isLoading) {
      if (data && data.matched && data.matched.length) {

        setTickers({
          ...tickers,
          ...(data.matched.reduce((acc: TickerMap, item: TickerType) => {
            if (item.ticker) {
              acc[item.ticker] = item
            }
            return acc
          }, {}))
        })
      }
    }
  }, [isLoading, data])



  const updateTickersFound = useCallback(debounce((_mdValue: string) => {
    const matches = _mdValue.length ? _mdValue.match(TICKER_HANDLE_REGEX) : []
    const tickersfound = matches ? matches.map(i => i.replace('$', '').toUpperCase()) : []

    setTickersMatched(tickersfound.sort().join(','))
  }, 300), [debounce, edited])

  useEffect(() => {
      updateTickersFound(mdValue)
  }, [mdValue, edited])

  const addTicker = (ticker: TickerType) => {
    if (!tickers[ticker.ticker.toLowerCase()]) {
      setTickers({
        ...tickers,
        [ticker.ticker]: ticker
      })
      onTickerAdd(ticker as HoldingType)
    }
  }
  const getTicker = async (ticker: string) => {
    const data = await fetchTickers(ticker)
    if (data && data.matched && data.matched.length) {
      addTicker(data.matched[0])
    }
  }

  const handleAddTicker = async(ticker:string|TickerType) => {
    if(typeof ticker === 'string') {
      await getTicker(ticker)
    } else {
      addTicker(ticker)
    }
  }

  return (
    <TickerHandlesContext.Provider
      value={{
        tickers,
        handleAddTicker,
        onTickerAdd,
        addTicker
      }}
    >
      {children}
    </TickerHandlesContext.Provider>
  );
};

export const TickerHandlerConsumer = TickerHandlesContext.Consumer;

export default TickerHandlesContext;
