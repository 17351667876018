import { ButtonRadioGroup, D3Chart, format, FormatEnum, TickerDetail, usePosition } from "@potato/components"
import React, { useRef, useState } from "react"
import { api_routes } from "../../../_api/api-routes"
import { useD3ChartData } from "../../../_utils/use-d3-chart-data"

type ChartSectionProps = {
    item: TickerDetail
}

export const ChartSection = ({ item }: ChartSectionProps) => {
    const ref = useRef()
    let { position: { width, height } } = usePosition(ref)
    const [range, setRange] = useState<string>(item.chart_options!.find(r => r.is_selected)!.range)

    const { active_view } = useD3ChartData({
        options: {
            id: item.id,
            range
        },
        key: 'range',
        active_item: range,
        full_item: range === '1d' ? '1y' : '5y',
        url: api_routes.charts.ticker
    })
    return <>
    <div className="flex">
       {width > 0 && !!active_view.data.length && !!active_view.data[0].data && <ButtonRadioGroup className="mt-12 flex-0 overflow-auto" onChange={(val) => setRange(val!)} value={range} options={item.chart_options.map((co) => ({ label: co.title, value: co.range })).reverse()} /> }
        <div className="flex-1"></div>
    </div>
    <div className='w-full max-w-full md:h-[350px] h-[250px] text-grey mt-4 md:mt-6' ref={ref as any}>
        <div className="relative h-full w-full" >
            {width > 0 && !!active_view.data.length && !!active_view.data[0].data ?
                <D3Chart
                    y_formatter={(y) => { return `$${y < 0.001 ? y.toPrecision(3) : format(y, FormatEnum.Currency)}` }}
                    series={active_view.data}
                    range={active_view.range}
                    options={{
                        width,
                        height,
                        duration: 500,
                        markers: {
                            picture: false,
                            outlined: false,
                            ping: false
                        }
                    }}
                />
                :
                <></>
            }
        </div>
    </div>
    </>
}
const propsAreEqual = (prev: ChartSectionProps, next: ChartSectionProps) => {
    return prev.item.id === next.item.id
}
export default React.memo(ChartSection, propsAreEqual);