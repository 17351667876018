import React, { useEffect } from "react";
import {
  SearchProvider,
  useNotify,
  Notify,
  PageWrapperSidebar,
  useCurrentBreakpointName,
} from "@potato/components";
import { HeaderProps } from "@potato/components/lib/components/Header/types";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useApi from "../../_contexts/api/useApi";
import Search from "../search";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import SidebarContent from "../../components/sidebar-content";
import { UserMenu } from "../../components/sidebar-content/UserMenu";
import NotificationsContent from "../../components/notifications-content/NotificationsContent";

export interface PageProps extends HeaderProps {
  children: JSX.Element;
}

const PageWrapper = ({ children, ...props }: PageProps) => {
  const { isLoading, logout } = useAuth0();
  const { user: potatoUser } = usePotatoUser();
  const { error, clearError } = useApi();
  const history = useHistory();
  const location = useLocation();
  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);
  // const devMode = localStorage.getItem("potato_view_mode") === "dev";

  useEffect(() => {
    if (error) {
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 401)
      ) {
        if (!location.pathname.includes("unsubscribe")) {
          localStorage.setItem("returnTo", location.pathname);
        }
        clearError();
        history.push("/login");
      }
    }
  }, [error, history, clearError, location.pathname]);

  const _logout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return (
    <>
      <Notify />
      <ScrollToTop />
      <SearchProvider>
        <Search />
        <PageWrapperSidebar
          mainMenuOpen={isLarge ? !!potatoUser : false}
          userMenuContent={
            potatoUser ? <UserMenu logout={_logout} user={potatoUser} /> : <></>
          }
          sidebarContent={<SidebarContent logout={_logout} user={potatoUser} />}
          notificationsContent={<NotificationsContent />}
          user={potatoUser || undefined}
          isLoading={isLoading}
          {...props}
        >
          {children}
        </PageWrapperSidebar>
      </SearchProvider>
    </>
  );
};

export default PageWrapper;

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
