import { Typography, CategoryList, Container, CategoryType, LineIcon, Breakpoint, ButtonVariant } from '@potato/components'
import { useHistory } from 'react-router-dom';
import { BasketsSectionItem } from "../../../_api/pages/home";

export interface CategoryProps {
  item: BasketsSectionItem
};

const Category = ({
  item
}: CategoryProps) => {

  return (

    <Container  className="lg:pt-6 pb-12 py-6">
      {/* <div className="flex items-center">
        <div className="flex-auto">
          <Typography variant="h3" element="h2" className="my-8">{item.title|| 'Categories'}</Typography>
        </div>
         {item.slug && (
           <div className="flex-initial">
            <ButtonVariant.Pill iconRight={<LineIcon className="block" icon="chevron-right" />} label="See all" href={item.slug} />
          </div>
           )} 
      </div> */}
      {/* <div className="flex flex-col md:flex-row md:space-x-6 "> */}
        <CategoryList columns={'md:grid-cols-3'} className='flex-1 mb-6 md:mb-0' items={item.categories as CategoryType[]} />
      {/* </div> */}
    </Container >
  );
};

export default Category;