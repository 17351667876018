import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { ButtonVariant, CustomIcon, GlobalUISizesEnum, HorizontalScrollList, FantasyLeagueCard, FantasyItemType } from '@potato/components'
import { useCurrentBreakpointName } from "react-socks";
// import {fantasies} from '../../_mocks/fantasies'
import { FantasiesSectionItem } from "../../_api/pages/fantasy-landing";
const rio = require("react-intersection-observer");

export interface LeaguesProps {
  item: FantasiesSectionItem
};
const textVariants = {
  visible: { opacity: 1, y: 0, transition: { ease: "easeInOut", duration: 1 } },
  hidden: { opacity: 0, y: 20 }
};

const container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.2,
    }
  }
}

const itemAnimation = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeInOut" } }
}

const Leagues = ({
  item
}: LeaguesProps) => {
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge', 'medium'].includes(name)
  const controls = useAnimation();
  const [ref, inView] = rio.useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);


  return (<div className="md:mt-40 mt-12 mb-12 md:mb-40 text-center">
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={textVariants}
      className="text-center"
    >
      <h3 className="md:text-4xl md:leading-10 leading-6 text-2xl font-bold mb-6">
   {item.title}
      </h3>
      <p className="md:text-xl text-md mx-auto block">Whether you’re into Crypto, watching stocks daily or only in it for long haul, <br className="md:block hidden" />
we have a league for you.</p>

    </motion.div>
    <Fantasies fantasies={item.fantasies} />
    <ButtonVariant.Cta className="inline-block mt-6 md:mt-12" size={GlobalUISizesEnum.LARGE} iconLeft={<CustomIcon icon={'plus-thin'} />} label="Discover Leagues" href="/fantasy" />
  </div>
  );
};

const Fantasies = ({
  fantasies
}: { fantasies: FantasyItemType[]}) => {
  const controls = useAnimation();
  const [ref, inView] = rio.useInView({
    threshold: 1,
  });
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge', 'medium'].includes(name)

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     if(inView) {
  //     const middleImage = document && document.getElementById("middle-image")
  //     if (middleImage) {
  //       middleImage.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  //     }
  //   }
  //   }, 1000)
  // }, [inView])

  return isLarge ? (
    <motion.ol
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={container}
      className="space-x-4 mt-12 flex max-w-6xl mx-auto"
    >
       {fantasies.map(item => {
        return  <motion.li variants={itemAnimation} className="flex-1"><FantasyLeagueCard item={item}/></motion.li>
      })}
    </motion.ol>
  ) :
    <>
      <motion.ol
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={textVariants}
        className="space-x-2 mt-6 flex"
      >

      </motion.ol>
      <HorizontalScrollList itemWidth={241} items={fantasies.map(item => {
        return <FantasyLeagueCard item={item}/>
      })}
      />
    </>
}

export default Leagues;

