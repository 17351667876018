import { useEffect, useState } from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { ButtonVariant, CustomIcon, GlobalUISizesEnum, LineIcon } from "@potato/components";
import usePotatoUser from "../_contexts/potato-user-store/potato-user-hook";

export const useFollow = (isFollowingProp:boolean, followerCountProp:number, id?:string) => {
  const api = useApi()
  const [is_followed, setFollowing] = useState<boolean>(isFollowingProp)
  const [follower_count, setFollowerCount] = useState<number>(followerCountProp)
  const { refetchUser } = usePotatoUser()

  useEffect(() => {
    setFollowing(isFollowingProp)
  }, [isFollowingProp])
  useEffect(() => {
    setFollowerCount(followerCountProp)
  }, [followerCountProp])
  const followFn = async (id: string, isFollowing: boolean) => {
    const result = await api.post({
      url: api_routes.actions.follow, data: {
        id: id,
        is_following: isFollowing
      }
    })()
    if (result) {
      setFollowing(result.is_following)
      setFollowerCount(result.follower_count)
      refetchUser()
    }
  }

  const onFollowToggleClick = (id: string, isFollowing: boolean) => () => {
    followFn(id, !isFollowing)
  }

  const followButton =  id ? <ButtonVariant.Outline className="block z-10 relative bg-white" onClick={onFollowToggleClick(id, is_followed)} iconLeft={<CustomIcon size={GlobalUISizesEnum.LARGE} withStroke={false} icon={is_followed ? "star-filled": "star"} className="mb-0.5 block" />} label={is_followed ? "Following" : "Follow"} /> : <></>

  return { followFn, onFollowToggleClick, isFollowing: is_followed, followerCount:follower_count, followButton };
}