import {
    ButtonVariant,
    Dialog,
    Loading,
    Markdown,
    useToggle,
  } from "@potato/components";
  import { useQuery } from "react-query";
  import { api_routes } from "../../_api/api-routes";
  import useApi from "../../_contexts/api/useApi";
import { ReleasesContent } from "../releases/Releases";
  
  export const useUpgradeVersionDialog = () => {
    const { apiLoading, auth0ApiReady, ...api } = useApi();
    const {
      selected: isOpen,
      onChange: toggleDialog,
    } = useToggle(false);
   
    const onCancel = () => {
      toggleDialog();
    };
    const getReleases = api.get({ url: api_routes.pages.releases });
    const _releasesResponse = useQuery("releases", getReleases, {
      enabled: !apiLoading,
    });

    const dialogFooter = (
        <div className={"mt-4 flex space-x-4"}>
          <div className="flex-1"></div>
          <div className="flex-0">
            <ButtonVariant.Cta
              fullWidth={true}
              className="w-full"
              onClick={toggleDialog}
              label="Close"
            />
          </div>
        </div>
      );
  
    return {
      toggleDialog,
      dialog: (
        <div>
          <Dialog
            onClose={onCancel}
            // onScroll={onScroll}
            footer={dialogFooter}
            height={700}
            width={600}
            closeButton={false}
            open={isOpen}
          >
            <div className="prose">
             <h1 className="mt-8 text-4xl">What's New</h1>
            <ReleasesContent/>
            </div>

            {/* <Markdown className="mt-4">
              {_releasesResponse &&
                _releasesResponse.data &&
                _releasesResponse.data.body}
            </Markdown> */}
          </Dialog>
        </div>
      ),
    };
  };
  