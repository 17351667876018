import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loading } from '@potato/components';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const location = useLocation() 
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <Loading className="mt-32" />
  }
  if (!isAuthenticated) {
    localStorage.setItem('returnTo', location.pathname)
    return <Redirect to="/login" />;
  }
 
  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
