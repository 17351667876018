import { ResponseStatus } from "../response-status"
import { MetaTags } from "../page-meta"
import { FantasyItemType } from "@potato/components"
export enum FantasyLandingSectionType {
  PublicFantasies = "public-fantasies",
}
export type FantasiesSectionItem = {
  section_type: FantasyLandingSectionType,
  slug: string
  title: string
  fantasies:  FantasyItemType[]
}
export type FantasyLandingPageResponse = ResponseStatus & MetaTags & {
  sections: FantasiesSectionItem[]
}