import {
  Container,
  Typography,
  PageMain,
  Navigation,
  Tabs,
  useSwitch,
  Breakpoint,
  useThemeContext,
  Divider,
  ButtonVariant,
  LineIcon,
} from "@potato/components"
import clsx from 'clsx'
import React, { useRef } from "react"
import { useSectionParam } from "../../_utils/use-section-param"
import EditProfile from './EditProfile'
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook"
import { Notifications } from "./Notifications"
import PageMeta from "../../components/page-meta"

const Settings: React.FC = () => {
  const sections = ['profile', 'notifications']
  const {
    user: _user
  } = usePotatoUser()
  const { selectComponent } = useThemeContext();

  (window as any).prerenderReady = true;

  const edit_profile_ref = useRef<any>()
  const email_settings_ref = useRef<any>()
  const appearance_settings_ref = useRef<any>()
  const billing_ref = useRef<any>()

  const editProfile =  <EditProfile user={_user || undefined} />
  const notification_settings = <Notifications user={_user || undefined}
  />
  const appearance_settings = <>
    <Typography variant="h3" className="lg:mb-8 mb-6">Appearance</Typography>
    <div className="flex flex-col">
      <div className="flex space-x-4 items-start">
        <div className="flex-0 pt-2"><Typography>Select theme</Typography></div>
        <div>
          {selectComponent}
        </div>
      </div>
    </div>
  </>
  const billing_settings =  <>
    <Typography variant="h3" className="lg:mb-8 mb-6">Billing</Typography>
    <ButtonVariant.Outline externalLink={true} href={_user?.billing_portal_link} label="Open Billing Portal"   iconLeft={<LineIcon className="block text-md" icon="credit-card"/>}/>
  </>

  return (
    <Container>
      <PageMeta data={{ title: "Settings | Potato", canonical: 'https://potato.trade/settings' }} />
      <PageMain>
          <div className={clsx("flex-0 md:block hidden")}>
            <div className="sticky top-4">
              <Typography variant="h5" className="mb-1">Settings</Typography>
              <Typography variant="a" className="block my-4" onClick={() => { console.log(edit_profile_ref); edit_profile_ref.current.scrollIntoView() }}>Edit Profile</Typography>
              <Typography variant="a" className="block my-4"onClick={() => { console.log(email_settings_ref); email_settings_ref.current.scrollIntoView() }}>Notifications</Typography>
              <Typography variant="a" className="block my-4"onClick={() => { console.log(appearance_settings_ref); appearance_settings_ref.current.scrollIntoView() }}>Appearance</Typography>
            </div>
          </div>
          <div className={clsx("flex-1")}>
            <div ref={edit_profile_ref} className="my-6 mb-12">
              {editProfile}
            </div>
            <Divider className="lg:mb-12 mb-8" />
            <div ref={email_settings_ref} className="my-6 mb-12">
              {notification_settings}
            </div>
            <Divider className="lg:mb-12 mb-8" />
            <div ref={appearance_settings_ref} className="my-6 mb-12">
              {appearance_settings}
            </div>
            <Divider className="mb-8" />
          </div>

      
      </PageMain>
    </Container>
  )
}

export default Settings