
import { getRandomInt } from '@potato/components';
import { BasketUpdate } from '../BasketPatchStoreContext'
export const basketUpdateMocker = (ids: string[]) => (): BasketUpdate => {
  const id = ids[Math.floor(Math.random() * ids.length)];
  const watches = getRandomInt(0, 6000)

  return {
    id,
    watch_count: watches,
    is_watched: [true, false][Math.floor(Math.random() * 2)]
  }
}

export const manyBasketUpdatesMocker = (ids: string[]) => (): BasketUpdate[] => {
  const length = Math.floor(Math.random() * ids.length)
  const basketUpdates: BasketUpdate[] = []
  for (let i = 0; i < length; i++) {
    const update = basketUpdateMocker(ids)()
    basketUpdates.push(update)
  }
  return basketUpdates
}