import React, { createContext, useCallback, useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import useApi from "../api/useApi";
import { useQuery, useQueryClient } from "react-query";
import { NotificationType, UserDetailType } from "@potato/components";
import { api_routes } from "../../_api/api-routes";

// what gets passed to consumer  const { loaded, someMethod } = usePotatoUserContext()
export interface PotatoUserContextValue {
  user: UserDetailType | null;
  notifications: NotificationType[];
  refetchUser: () => void;
  clearNotifications: () => void
  isLoading: boolean;
  notifications_loading: boolean;
}
// what passed to provider
interface PotatoUserProviderProps {
  children?: ReactNode;
}
// an initial value for the consumer
const PotatoUserContext = createContext<PotatoUserContextValue>({
  user: null,
  notifications: [],
  notifications_loading: true,
  isLoading: true,
  refetchUser: () => {},
  clearNotifications: () => {}
});

export const PotatoUserProvider: FC<PotatoUserProviderProps> = ({
  children,
}) => {
  const { apiLoading, ...api } = useApi();
  const [clear_counter, setClearCounter] = useState(0);
  const getUser = api.get({ url: api_routes.get_me });
  const getNotifications = api.post({ url: api_routes.notifications });
  const clearNotifications = api.post({ url: api_routes.clear_notifications });

  const _user = useQuery("me", getUser, {
    enabled: !apiLoading,
  });

  const user: UserDetailType | null =
    _user.data && _user.data.status === "success"
      ? ({
          ..._user.data,
        } as UserDetailType)
      : null;

  const _notifications = useQuery("notifications", getNotifications, {
    enabled: !!user,
    refetchInterval: 60000,
  });

  let timeout: NodeJS.Timeout;
  const _clearNotifications = () => {
    timeout = setTimeout(async() => {
      const result = await clearNotifications();
      if (result && result.status === "success") {
        setClearCounter(clear_counter + 1);
      }
    }, 2000);
  };

  const notifications =
    _notifications.data && _notifications.data.notifications
      ? _notifications.data.notifications
      : ([] as NotificationType[]);

  const queryClient = useQueryClient();
  const refetchUser = useCallback(() => {
    user && queryClient.invalidateQueries("me");
    user && queryClient.invalidateQueries(user.slug);
  }, [user]);

  useEffect(() => {
    if(clear_counter) { 
      queryClient.refetchQueries("notifications");
    }
  }, [clear_counter]);

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [])

  return (
    <PotatoUserContext.Provider
      value={{
        user,
        refetchUser,
        notifications,
        notifications_loading: _notifications.isLoading,
        clearNotifications: _clearNotifications,
        isLoading: _user.isLoading,
      }}
    >
      {children}
    </PotatoUserContext.Provider>
  );
};

export const PotatoUserConsumer = PotatoUserContext.Consumer;

export default PotatoUserContext;
