import { useState } from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

export const useLeaveFantasy = () => {
  const [leaveing, setLeaveing] = useState<boolean>(false)
  const api = useApi()
  const history = useHistory()
  const queryClient = useQueryClient()

  const leaveFantasy = async (data: { id?: string, slug: string }) => {
    setLeaveing(true)
    await queryClient.invalidateQueries(data.id, { refetchActive:false })
    const response = await api.post({
      url: api_routes.actions.leave_fantasy,
      data
    })()
    if(response.status === "success") {
      queryClient.invalidateQueries('me')
      queryClient.invalidateQueries('fantasy_index')
    }
    setLeaveing(false)
  }

  return { leaveFantasy, leaveing };
}