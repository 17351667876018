import {
  ButtonVariant,
  Dialog,
  Loading,
  Markdown,
  useToggle,
} from "@potato/components";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { api_routes } from "../_api/api-routes";
import useApi from "../_contexts/api/useApi";
import usePotatoUser from "../_contexts/potato-user-store/potato-user-hook";

export const useGuidelinesDialog = () => {
  const [top, setTop] = useState<number>(0);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const queryClient = useQueryClient()
  const { user } = usePotatoUser()
  const { apiLoading, auth0ApiReady, ...api } = useApi();
  const {
    selected: isOpen,
    onChange: toggleDialog,
  } = useToggle(false);

  useEffect(() => {
    if(isAccepted && user && user.is_ai_guidelines_accepted) {
      setIsReady(true)
    }
  }, [user, isAccepted])

  const onCancel = () => {
    toggleDialog();
  };
  const getHome = api.get({ url: api_routes.pages.basketIndex });
  const _basketIndexResponse = useQuery("basketIndex", getHome, {
    enabled: !apiLoading,
    refetchInterval: 30000,
  });

  const onAcceptGuidelines = async () => {
    const result = await api.post({ url: api_routes.actions.accept_terms })();
    if (result && result.status === "success") {
        queryClient.refetchQueries('me')
        setIsAccepted(true)
        toggleDialog()
    }
  };
  const dialogFooter = (
    <div className={"mt-4 flex space-x-4"}>
      <div className="flex-1"></div>
      <div className="flex-0">
        <ButtonVariant.Cta
          disabled={top < 0.9}
          fullWidth={true}
          className="w-full"
          onClick={onAcceptGuidelines}
          label="Accept"
        />
      </div>
    </div>
  );

  const onScroll = (top: number) => {
    setTop(top);
  };
  return {
    toggleDialog,
    isAccepted: isAccepted && isReady,
    dialog: (
      <div>
        <Dialog
          onClose={onCancel}
          onScroll={onScroll}
          height={700}
          width={600}
          closeButton={false}
          open={isOpen}
          footer={dialogFooter}
        >
          <Markdown className="mt-4">
            {_basketIndexResponse &&
              _basketIndexResponse.data &&
              _basketIndexResponse.data.terms_of_use}
          </Markdown>
        </Dialog>
      </div>
    ),
  };
};
