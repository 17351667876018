import { ChartPlaceholder, D3Chart, FantasyDetailItemType, RankingType, RoundType, useCurrentBreakpointName, usePosition } from "@potato/components"
import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"
import { api_routes } from "../../../_api/api-routes"
import { useD3ChartData } from "../../../_utils/use-d3-chart-data"
import RoundCountdown from "../RoundCountdown"
import LiveButton from "../LiveButton"

type ChartSectionProps = {
    item: FantasyDetailItemType
}

const ChartSection = ({ item }: ChartSectionProps) => {
    const [view, setView] = useState('full')

    let round: RoundType = item.selected_round

    return (
        <div>
            <div className={clsx("flex items-center space-x-4 justify-between")}>
           
                <div className={clsx("flex-1")}>
                    {round && <RoundCountdown start_at={round.start_at} end_at={round.end_at} />}
                </div>
                {round && round.is_live && <div className="flex-0">
                        <LiveButton onClick={() => {
                            setView(view === 'full' ? 'closeup' : 'full');
                        }} className="float-right" on={view === 'closeup'} />
                    </div>}
            </div>
            <RoundChart view={view} setView={setView} item={item} />
        </div>
    );
}

type RoundChartProps = {
    view: string,
    setView: (x: string) => void
    item:FantasyDetailItemType
}

const RoundChart = ({ item, setView, view }: RoundChartProps) => {
    const ref = useRef()
    let { position: { width, height } } = usePosition(ref)
    const ranking = item.rankings && item.rankings.find(r => r.is_mine)
    let round: RoundType = item.selected_round

    const [initialZoom, setInitialZoom] = useState(false)

    let timeout: any = useRef(null)

    const { active_view } = useD3ChartData({
        options: {
            id: item.id,
            view,
            round: round.round_number.toString()
        },
        key: 'view',
        active_item: view,
        full_item: 'full',
        latest_item: 'latest',
        fetch_updates_on: round.is_live ? 'closeup' : undefined,
        url: api_routes.charts.fantasy
    })

    useEffect(() => {
        setView('full')
        if (!initialZoom && round && round.is_live && active_view.data.length) {
            if (!timeout.current) {
                timeout.current = setTimeout(() => {
                    setView('closeup')
                    setInitialZoom(true)
                }, 3000)
            }
        }
        return () => {
            if (timeout.current) clearTimeout(timeout.current)
        }
    }, [active_view.data.length])

    return <div className='w-full max-w-full md:h-[350px] h-[250px] text-grey mt-4 md:mt-6' ref={ref as any}>
        <div className="relative h-full w-full" >
            {width > 0 && active_view.data.length ?
                <D3Chart
                    y_formatter={(y) => { return `${y.toPrecision(3)}%` }}
                    series={active_view.data}
                    range={active_view.range}
                    options={{
                        width,
                        height,
                        shift_x: round.is_live && view === 'closeup',
                        markers: {
                            picture: true,
                            ping: round.is_live && view === 'closeup',
                            outlined:view !== 'closeup'
                        },
                        duration: 500
                    }}
                />
                : width > 0 && 
                <ChartPlaceholder width={width} height={height} rankings={item.rankings || []} />
            }
        </div>
    </div>
}
const propsAreEqual = (prev: ChartSectionProps, next: ChartSectionProps) => {
    return prev.item.id === next.item.id && prev.item.selected_round.round_number === next.item.selected_round.round_number
}
export default React.memo(ChartSection, propsAreEqual);