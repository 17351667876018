import { Container, EmojiTitle, Logo } from '@potato/components';
import React from 'react';

type Props = {}
class ErrorBoundary extends React.Component<Props, {hasError: boolean}> {
  constructor(props:Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error:any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error:any, errorInfo:any) {
    // You can also log the error to an error reporting service
    if(process.env.NODE_ENV === 'development') { 
      console.error(error, errorInfo)
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<div>
        <Container className="max-w-78">
          <Logo showName={true} className=" w-32 my-12"/>
          <EmojiTitle emoji="🦙" className="w-auto" title="Oops, something went wrong..." variant="h2"/>
        </Container>
      </div>)
    }

    return this.props.children; 
  }
}

export default ErrorBoundary