import {
  AIGeneratedTag,
  AnimatedText,
  ButtonVariant,
  CreateBasketInput,
  EmojiTitleAnimated,
  GlobalUISizesEnum,
  HoldingsList,
  Typography,
} from "@potato/components";
import { motion } from "framer-motion";
import React, {
  useCallback,
  useEffect,
  useState,
  FC,
  ReactNode,
  createContext,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { api_routes } from "../../_api/api-routes";
import { BasketPageResponse } from "../../_api/pages/basket";
import useApi from "../../_contexts/api/useApi";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import { useGuidelinesDialog } from "../../_utils/use-guidelines-dialog";

// what gets passed to consumer  const { loaded, someMethod } = useGenerateBasketContext()
export interface GenerateBasketContextValue {
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  setCreateLayout: React.Dispatch<React.SetStateAction<boolean>>;
  create_layout: boolean;
  data_layout: boolean;
  completed_layout: boolean;
  // completed_cta,
  create_input: ReactNode;
  dialog: ReactNode;
  error_message_component: ReactNode;
  cancel_button: ReactNode;
  is_regenerating:boolean
  ai_basket_layout: ReactNode;
  ai_basket: BasketPageResponse;
}
// what passed to provider
interface GenerateBasketProviderProps {
  private_basket_id?: string;
  prompt?: string;
  children?: ReactNode;
}
// an initial value for the consumer
const GenerateBasketContext = createContext<GenerateBasketContextValue>({
  setPrompt: () => {},
  setCreateLayout: () => {},
  create_layout: false,
  data_layout: false,
  completed_layout: false,
  is_regenerating: false,
  create_input: <></>,
  dialog: <></>,
  // completed_cta,
  error_message_component: <></>,
  cancel_button: <></>,
  ai_basket_layout: <></>,
  ai_basket: {} as BasketPageResponse,
});

export const GenerateBasketProvider: FC<GenerateBasketProviderProps> = ({
  children,
  private_basket_id,
  prompt: _provided_prompt
}) => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const { user } = usePotatoUser();
  const queryClient = useQueryClient();
  const [triggerSubmit, setTriggerSubmit] = useState<number>(0);
  const [local_storage_prompt, setLocalStoragePrompt] = useState<string>(_provided_prompt || "");
  const [prompt, setPrompt] = useState<string>("");
  const [error_message, setErrorMessage] = useState<string | null>(null);
  const [basket_slug, setBasketSlug] = useState<string | null>(null);
  const [completed_layout, setCompletedLayout] = useState<boolean>(false);
  const [show_description, setShowDescription] = useState<boolean>(false);
  const [create_layout, setCreateLayout] = useState<boolean>(!!private_basket_id);
  const [data_layout, setDataLayout] = useState<boolean>(false);

  const { dialog, toggleDialog, isAccepted } = useGuidelinesDialog();

  const getSubmitMethod = () => {
    if (!user) {
      return redirectToLogin;
    }
    if (!user.is_ai_guidelines_accepted) {
      return toggleDialog;
    }
    const out_of_creadits = !!(
      user.ai_basket_count_remaining && user.ai_basket_count_remaining <= 0
    );
    if (out_of_creadits) {
      return !user.is_potato_plus ? redirectToPricing : () => {};
    }

    return onCreateSubmit;
  };

  useEffect(() => {
    if (isAccepted) {
      setTriggerSubmit(triggerSubmit + 1);
    }
  }, [isAccepted]);

  useEffect(() => {
    const _prompt = localStorage.getItem("ai_prompt");
    if (_prompt) {
      setLocalStoragePrompt(_prompt);
      localStorage.removeItem("ai_prompt");
    }
  }, []);

  const getBasket = (_basket_slug: string | null) => {
   return api.get({
      url: api_routes.pages.basket + _basket_slug,
      redirect404: true,
    });
  }
  const createAIBasket = (data: { prompt?: string, private_basket_id?:string }) => {
    return api.post({
      url: api_routes.actions.create_ai_basket,
      data,
    });
  };

  
  const { data: ai_basket, isLoading: ai_basket_is_loading } = useQuery(
    ["get_ai_basket", basket_slug],
    getBasket(basket_slug),
    { enabled: !!basket_slug, refetchInterval: 1000 }
    );
    
    const { data, isLoading } = useQuery(
      ["create_ai_basket", [private_basket_id || prompt]],
      createAIBasket(private_basket_id ? { private_basket_id } : { prompt }),
      { enabled: private_basket_id ? (!ai_basket && !!private_basket_id) : (!ai_basket && !!prompt)}
    );
    
  useEffect(() => {
    if (ai_basket && !ai_basket_is_loading) {
      setDataLayout(true);
    }
    if (ai_basket && ai_basket.is_ai_generation_failed) {
      setCreateLayout(false);
      setDataLayout(false);
      setBasketSlug(null);
      setPrompt("");
      setLocalStoragePrompt("");
      setErrorMessage(
        ai_basket.ai_generation_error_message ||
          "Unable to generate ETF based on your prompt. Please try another phrase."
      );
    }
  }, [ai_basket, ai_basket_is_loading]);

  useEffect(() => {
    if (data && !isLoading && !data.error_message) {
      setBasketSlug(data.slug);
    }
  }, [isLoading]);

  useEffect(() => {
    if (basket_slug) {
      setCreateLayout(true);
    }
  }, [basket_slug]);

  let timeout: NodeJS.Timeout;
  let timeout2: NodeJS.Timeout;

  useEffect(() => {
    if (ai_basket_is_loading) {
      setDataLayout(true);
    }
    if (ai_basket && ai_basket.is_ai_generation_success) {
      setCompletedLayout(true);
      queryClient.refetchQueries("me");
    }
    if (ai_basket && ai_basket.title && ai_basket.emoji) {
      timeout = setTimeout(() => {
        setShowDescription(true);
      }, 2000);
    }
  }, [ai_basket, ai_basket_is_loading]);

  useEffect(() => {
    if (ai_basket && completed_layout) {
      timeout2 = setTimeout(() => {
        private_basket_id ? history.replace(ai_basket.slug) : history.push(ai_basket.slug);
      }, 13000);
    }
  }, [completed_layout]);

  const reset = () => {
    setDataLayout(false);
    setCreateLayout(false);
    setCompletedLayout(false);
    setPrompt("");
    setLocalStoragePrompt("");
    setErrorMessage(null);
    setBasketSlug(null);
    if (timeout) {
      clearTimeout(timeout);
    }
    if (timeout2) {
      clearTimeout(timeout2);
    }
  };

  const redirectToPricing = useCallback((val: string) => {
    localStorage.setItem("ai_prompt", val);
    return history.replace("/pricing");
  }, []);

  const redirectToLogin = useCallback((val: string) => {
    localStorage.setItem("ai_prompt", val);
    localStorage.setItem("returnTo", location.pathname);
    return history.replace("/login");
  }, []);

  const onCreateSubmit = useCallback(
    (val: string) => {
      if (user && user.ai_basket_count_remaining === 0 && user.is_potato_plus) {
        setErrorMessage("You have reached your maximum number of requests");
      } else {
        setErrorMessage(null);
        setPrompt(val);
      }
    },
    [user]
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const create_input = (
    <CreateBasketInput
      triggerSubmit={triggerSubmit}
      onSubmit={getSubmitMethod()}
      label={!!private_basket_id ? 'Upgrade' : 'Create'}
      loading_label={!!private_basket_id ? 'Upgrading...' : 'Creating...'}
      loading={isLoading || create_layout}
      value={local_storage_prompt}
      placeholder=""
      id="ai-basket-prompt"
    />
  );

  const error_message_component = (
    <motion.div
      className="overflow-hidden px-4 rounded-full flex-items-center justify-center bg-red-200"
      initial={"hidden"}
      animate={error_message ? "visible" : "hidden"}
      variants={{
        visible: { height: 60 },
        hidden: { height: 0 },
      }}
    >
      <Typography className="text-red" variant="p">
        {error_message}
      </Typography>
    </motion.div>
  );

  //   const completed_cta = ai_basket && (
  //     <div className="flex items-center">
  //       <Typography className="pr-6 py-5 text-lg">Your basket has been <span className="font-semibold">created!</span> Save to start tracking.</Typography>
  //       <ButtonVariant.Cta
  //       size={GlobalUISizesEnum.LARGE}
  //       href={ai_basket.slug}
  //       label="Save and edit"
  //       iconRight={<LineIcon icon="arrow-right" />} />
  //     </div>
  //   );

  const cancel_button = (
    <ButtonVariant.Text
      onClick={reset}
      disabled={!!private_basket_id}
      size={GlobalUISizesEnum.LARGE}
      label="Cancel"
      className="underline ml-4"
    />
  );

  const ai_basket_layout = ai_basket && (
    <motion.div className="">
      {ai_basket.title && (
        <motion.div
          className=""
          initial="out"
          variants={{
            out: { marginLeft: "100px", opacity: 0 },
            in: { marginLeft: "0px", opacity: 1 },
          }}
          animate="in"
          exit="out"
          transition={{ duration: 0.4, type: "spring", stiffness: 200 }}
        >
          <AIGeneratedTag className="mb-6 my-4" />
        </motion.div>
      )}
      <EmojiTitleAnimated emoji={ai_basket.emoji} title={ai_basket.title} />
      {show_description && (
        <div className="mt-6 w-full min-h-[100px]">
          <AnimatedText
            value={ai_basket.description}
            speed={99}
            pause_count={100}
            variant="p"
          />
        </div>
      )}
      {ai_basket.holdings && !!ai_basket.holdings.length && (
        <>
          <Typography variant="h3" className="mt-6">
            Holdings
          </Typography>
          <HoldingsList items={ai_basket.holdings} />
        </>
      )}
    </motion.div>
  );
  return (
    <GenerateBasketContext.Provider
      value={{
        create_layout,
        data_layout,
        create_input,
        completed_layout,
        dialog,
        setCreateLayout,
        setPrompt: setLocalStoragePrompt,
        // completed_cta,
        error_message_component,
        is_regenerating: !!private_basket_id,
        cancel_button,
        ai_basket_layout,
        ai_basket: ai_basket as BasketPageResponse,
      }}
    >
      {children}
    </GenerateBasketContext.Provider>
  );
};

export const GenerateBasketConsumer = GenerateBasketContext.Consumer;

export default GenerateBasketContext;
