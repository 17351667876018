import useApi from '../../_contexts/api/useApi'
import React from "react"
import {
  useQuery,
} from 'react-query'
import { useLocation } from "react-router-dom"
import { TickerPageResponse } from "../../_api/pages/ticker";
import { api_routes } from "../../_api/api-routes"
import TickerLayout from './TickerLayout'
import WatchHoldingsUpdate from '../../hoc/watch-holdings-updates/WatchHoldingsUpdate'
import { Loading } from '@potato/components';
import PageMeta from "../../components/page-meta";

const Ticker: React.FC = () => {
  const { apiLoading, ...api } = useApi()

  const location = useLocation()
  const uriParam = encodeURI(location.pathname)

  const getTicker = api.get({ url: api_routes.pages.ticker + uriParam, redirect404: true })
  const _tagsResponse = useQuery<TickerPageResponse>(location.pathname, getTicker, {
    enabled: !apiLoading,
    refetchInterval: 10000
  })
  const ticker = (_tagsResponse.data && _tagsResponse.data)

  const isLoading = _tagsResponse.isLoading
  if (isLoading) {
    return <div className="relative z-10"><Loading className="mt-32" /></div>
  }

  if (!ticker) {
    return <div />
  };
  
  (window as any).prerenderReady = true;

  return (
    <>

      <PageMeta data={ticker.meta_tags} defaultData={{ title: ticker.title + ' | ' + ticker.type }} />
      <WatchHoldingsUpdate item={ticker}>
        <TickerLayout item={ticker} />
      </WatchHoldingsUpdate>
    </>
  )

}

export default Ticker