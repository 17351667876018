import React from "react";
import {
  ButtonVariant as Button,
  LineIcon,
  MenuList,
  Typography,
  UserAvatar,
  BasketLink,
  useCurrentBreakpointName,
  GlobalUISizesEnum,
  FantasyLink,
  UserDetailType,
  MovementChip,
  AIGeneratedTag,
  HumanGeneratedTag,
  usePageStatus,
  useTruncate,
} from "@potato/components";
import { Link, useLocation, useHistory } from "react-router-dom";
import clsx from "clsx";
import { UserFantasyMenu } from "./UserFantasyMenu";

const isBasketLink = (x: BasketLink | FantasyLink): x is BasketLink => {
  return !!x.hasOwnProperty("movement_7d");
};
const isFantasyLink = (x: BasketLink | FantasyLink): x is FantasyLink => {
  return !!x.hasOwnProperty("rank");
};

export const makeItemConfig = (pathname: string, isLarge: boolean) => (
  item: BasketLink | FantasyLink
) => ({
  value: item.slug,
  emoji: item.emoji,
  label: item.title,
  prompt: isBasketLink(item) ? item.ai_user_input : undefined,
  active: item.slug == pathname,
  closeOnClick: !isLarge,
  badge: isBasketLink(item) ? (
    <MovementChip
      size={GlobalUISizesEnum.XSMALL}
      arrow={true}
      movement={item.movement_7d}
    />
  ) : isFantasyLink(item) && item.rank ? (
    <div className="text-xs font-semibold rounded-full px-2 py-0.5 pb-0 bg-black text-white">
      {item.rank}
    </div>
  ) : undefined,
});

export const UserMenu = ({ user, logout }: { 
  user: UserDetailType
  logout: () => void; }) => {
  const { scrollToTop } = usePageStatus();
  const { pathname } = useLocation();
  const name = useCurrentBreakpointName();
  const fantasyMode = localStorage.getItem("potato_fantasy_mode") === "fantasy";
  const isLarge = ["large", "xlarge"].includes(name);
  const history = useHistory();
  const [
    _pu,
    baskets_published,
    published_truncate_text,
    published_on_toggle,
    published_is_expanded,
    published_should_truncate,
  ] = useTruncate(user.baskets_published || [], 12);
  const [
    _pr,
    baskets_private,
    private_truncate_text,
    private_on_toggle,
    private_is_expanded,
    private_should_truncate,
  ] = useTruncate(user.baskets_private || [], 6);

  const baskets_published_expand_menu_item = published_should_truncate
    ? {
        label: published_is_expanded ? "Show less" : published_truncate_text,
        icon: published_is_expanded ? "chevron-up" : "chevron-down",
        onClick: published_on_toggle,
      }
    : undefined;
  const baskets_private_expand_menu_item = private_should_truncate
    ? {
        label: private_is_expanded ? "Show less" : private_truncate_text,
        icon: private_is_expanded ? "chevron-up" : "chevron-down",
        onClick: private_on_toggle,
      }
    : undefined;

  const baskets_published_menu_items = baskets_published.map(
    makeItemConfig(pathname, isLarge)
  );
  const baskets_private_menu_items = baskets_private.map(
    makeItemConfig(pathname, isLarge)
  );
  if (!user) {
    return <></>;
  }
  return (
    <>
      <div
        className={clsx(
          pathname === user.slug && "bg-lifted-highlight light:bg-pill",
          "px-6 py-3 flex-0 transition ease-in-out hover:bg-lifted-highlight  cursor-pointer"
        )}
      >
        <UserAvatar bold={true} item={user} bio={false} />
      </div>
      {fantasyMode && <UserFantasyMenu user={user} />}
      <MenuList
        className="flex-1"
        isCard={false}
        items={[
          <div className="flex items-center px-6 pt-6 pr-3">
            <Typography
              className=" flex-1 text-primary font-bold uppercase text-base"
              href={"/"}
            >
              Baskets
            </Typography>
          </div>,
          <AIGeneratedTag
            size={GlobalUISizesEnum.SMALL}
            className="ml-6 mt-4 mb-2"
          />,

          {
            icon: "plus",
            label: "Generate AI Basket",
            onClick: () => {
              if (pathname == "/") {
                scrollToTop();
                const input = document.getElementById("ai-basket-prompt");
                if (input) {
                  input.focus();
                }
              } else {
                history.push("/");
                setTimeout(() => {
                  const input = document.getElementById("ai-basket-prompt");
                  if (input) {
                    input.focus();
                  }
                }, 1000);
              }
            },
          },
          ...baskets_published_menu_items,
          baskets_published_expand_menu_item,
          !isLarge && (<div className="px-6 pb-3 mt-8">
         {" "}
          <Button.Outline
            iconLeft={<LineIcon className="block" icon="exit-right" />}
            className="w-full text-left mt-1"
            label="Log Out "
            onClick={logout}
          />
        </div>),
        ]}
      />
    </>
  );
};
