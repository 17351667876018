import { Typography, NewsList, Container, NewsItemType, LineIcon, Breakpoint, ButtonVariant } from '@potato/components'
import { useHistory } from 'react-router-dom';
import { BasketsSectionItem } from "../../../_api/pages/home";

export interface NewsProps {
  item: BasketsSectionItem
};

const News = ({
  item
}: NewsProps) => {

  return (

    <Container  className="lg:py-12 py-6">
      <div className="flex items-center">
        <div className="flex-auto">
          <Typography variant="h3" element="h2" className="my-8">Latest News</Typography>
        </div>
        {/* <Breakpoint large up> */}
         {item.slug && (
           <div className="flex-initial">
            <ButtonVariant.Pill iconRight={<LineIcon className="block" icon="chevron-right" />} label="See more" href={item.slug} />
          </div>
           )} 
        {/* </Breakpoint> */}
      </div>
      <div className="flex flex-col md:flex-row md:space-x-6 ">
        <NewsList className='flex-1 mb-6 md:mb-0' items={item.news_left as NewsItemType[]} />
        <NewsList className='flex-1'  items={item.news_right as NewsItemType[]} />
      </div>
    </Container >
  );
};

export default News;