import { ResponseStatus } from "../response-status"
import { MetaTags } from "../page-meta"
import { FantasyItemType, TradeActivityType, TradeActivityTypeFlat } from "@potato/components"

export enum FantasiesSectionType {
  PublicFantasies = "public-fantasies",
  Fantasies = "fantasies",
  LatestWinners = "latest-winners",
  TradeActivity = "trade-activity",
}

export type FantasiesSectionItem = {
  section_type: FantasiesSectionType,
  slug?: string
  title?: string
  fantasies?: FantasyItemType[] | null,
  trades?: TradeActivityTypeFlat[] | null,
}

export type FantasiesPageResponse = ResponseStatus & MetaTags & {
  sections: FantasiesSectionItem[]
}