import React, { Suspense, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Websocket from "../hoc/websocket/Websocket";

export type Routes = {
  exact?: boolean;
  path?: string | string[];
  websocket?: boolean;
  guard?: any;
  layout?: any;
  layoutProps?: any;
  component?: any;
  routes?: Routes;
}[];

const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<div>Loading....</div>}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout {...(route.layoutProps || {})}>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                  {route.websocket && <Websocket />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default renderRoutes;
