import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import useBasketUpdateStore from "../_contexts/basket-patch-store/useBasketUpdateStore";
import { useQueryClient } from 'react-query';
import usePotatoUser from '../_contexts/potato-user-store/potato-user-hook';

export const useWatch = () => {
  const api = useApi()
  const { addBasketUpdates } = useBasketUpdateStore()
  const { user: potatoUser } = usePotatoUser()
  const queryClient = useQueryClient()
  const watchFn = async (id: string, isWatched: boolean) => {
    const result = await api.post({
      url: api_routes.actions.watch, data: {
        id: id,
        is_watched: isWatched
      }
    })()
    if (result) {
      addBasketUpdates([{
        id: id,
        watch_count: result.watch_count,
        is_watched: result.is_watched
      }])
      queryClient.invalidateQueries('me')
      potatoUser && queryClient.invalidateQueries(potatoUser.slug)
    }
  }

  const onWatchToggleClick = (id: string, isWatched: boolean) => () => {
    watchFn(id, !isWatched)
  }
  const onWatchClick = (id: string) => (isWatched: boolean) => {
    watchFn(id, !isWatched)
  }

  return { watchFn, onWatchToggleClick, onWatchClick };
}