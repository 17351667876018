import { FormProvider, getRandomEmoji } from "@potato/components";
import React, { useCallback } from "react";
import PageMeta from "../../components/page-meta";
import CreateLayout from "./CreateLayout";
import { useCreateFantasy } from "../../_utils/use-create-fantasy";
import useApi from "../../_contexts/api/useApi";
import { api_routes } from "../../_api/api-routes";
import { useQuery } from "react-query";
import { CreateFantasyPageResponse } from "../../_api/pages/create-fantasy";

export interface CreateProps { };

const Create = ({

}: CreateProps) => {
  const { apiLoading, ...api } = useApi()
  const { createFantasy } = useCreateFantasy()
  const getCreatePage = api.get({ url: api_routes.pages.create_fantasy, waitForAuth: true })
  const { data } = useQuery<unknown, unknown, CreateFantasyPageResponse>('create-fantasy', getCreatePage, {
    enabled: !apiLoading,
  })
  const onSubmit: ((e: React.FormEvent<HTMLFormElement>, data: any) => void) | undefined = async (e, data) => {
    e.preventDefault()
    await createFantasy({
      ...data,
      start_immediately: Number(eval(data.start_immediately)),
      repeat: Number(eval(data.repeat)),
    })
  }

  const useLocalStorage = useCallback((data: any) => {
    let json_string_data = ''
    if (data.title) {
      try {
        json_string_data = JSON.stringify(data)
      } catch (err) {
        console.warn(err)
      }
      if (json_string_data.length) {
        localStorage.setItem('create-fantasy', json_string_data)
      }
    }
  }, [])

  const default_initial_data = {
    emoji: '',
    title: '',
    description: '',
    duration: 'weekly',
    repeat: 'true',
    stack: 'any',
    market_cap: 'Any',
    start_immediately: 'true'
  }
  const getInitialData = useCallback(() => {
    let local_data_obj = default_initial_data;
    const local_data_string = localStorage.getItem('create-fantasy')
    if (local_data_string) {

      try {
        local_data_obj = JSON.parse(local_data_string)
      } catch (err) {
        console.error(err)
      }

    }
    return local_data_obj

  }, [])


  if (!data) {
    return <></>
  }
  return (
    <>
      <PageMeta data={data.meta_tags} defaultData={{ title: "Create Fantasy | Potato", description: "Challenge your friends in a fantasy trading competition. Include famous investors such as Warren Buffet, Cathy Wood and more." }} />
      <FormProvider
        onFormChange={useLocalStorage}
        onSubmit={onSubmit}
        initialData={getInitialData()}
        readonly={false}>
        <CreateLayout market_caps={data.market_caps} benchmarks={data.benchmarks} durations={data.durations} />
      </FormProvider>
    </>
  );
};

export default Create;