import { GlobalUISizesEnum, TagList, TagType, Typography, useTruncate } from "@potato/components"

type Props = {
  tags?: TagType[] | null
}
const RelevantTags = ({tags}: Props) => {
  const [truncateTagsButton, truncatedTagsItems] = useTruncate(tags || [], 12)
  return !!tags && !!tags.length ? (
    <div className="mb-12">
    <Typography variant="h3" className="mb-6">
      Relevant Tags
    </Typography>

    <TagList center={false} size={GlobalUISizesEnum.SMALL} items={truncatedTagsItems as TagType[]} className="mt-5" />
    {truncateTagsButton}
  </div>
  ) : <></>
}

export default RelevantTags