import React from "react"
import {
  Loading,
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import { PageModeProvider } from "../../_contexts/page-mode/PageModeContext";
import {
  useQuery,
} from 'react-query'
import { api_routes } from "../../_api/api-routes";
import { UserPageResponse } from "../../_api/pages/user";
import { useSectionParam } from '../../_utils/use-section-param'
import UserLayout from "./UserLayout";

const sections = ['published_baskets', 'private_baskets', 'watching']

const User: React.FC = () => {
  const { apiLoading, ...api } = useApi()
  const { uriParam } = useSectionParam(sections, 'published_baskets')
  const getUser = api.get({ url: api_routes.pages.user + uriParam, redirect404: true })
  const _userResponse = useQuery(uriParam, getUser, {
    enabled: !apiLoading,
  })
  const user = _userResponse.data as UserPageResponse


  const isLoading = _userResponse.isLoading

  if (isLoading) {
    return <div className="relative z-10"><Loading className="mt-32" /></div>
  }
  if (!user) {
    return <div />
  };

  (window as any).prerenderReady = true;


  return (
    <PageModeProvider initialMode="main">
      <UserLayout item={user} />
    </PageModeProvider>
  )

}

export default User