
export const api_routes = {
  stealth: {
    pages: {
      terms: '/pages/terms',
      faq: '/pages/faq',
      privacy: '/pages/privacy',
      about: '/pages/about',
      media: '/pages/media',
      careers: '/pages/careers',
      job: '/pages/careers?slug='
    }
  },
  tags: 'tags',
  categories: 'categories',
  comments: 'comments',
  search: {
    basket: 'search/basket?query=',
    users: 'search/user?query=',
    tickers: 'search/tickers',
    tickersExact: 'search/tickers/exact?query=',
    tags: 'search/tags?query='
  },
  get_me: 'me',
  notifications: 'user/notifications/getLatest',
  clear_notifications: 'user/notifications/clear',
  basket: {
    history: '/basket/history?id='
  },
  pages: {
    releases: '/pages/releases',
    pricing: '/pages/pricing',
    rankings: '/pages/rankings',
    basketIndex: 'pages/home',
    baskets: 'pages/baskets',
    news: 'pages/news?slug=',
    news_index: 'pages/newsIndex',
    category: 'pages/category?slug=',
    tag: 'pages/tag?slug=',
    topic: 'pages/topic?slug=',
    basket: 'pages/basket?slug=',
    create_fantasy: 'pages/create-fantasy',
    fantasy: 'pages/fantasy?slug=',
    fantasy_index: 'pages/fantasyIndex',
    fantasy_landing: 'pages/homeV2',
    basket_embed: 'pages/basket/embed?slug=',
    ticker: 'pages/ticker?slug=',
    tickers: 'pages/tickers',
    user: 'pages/user?slug=',
    categories: 'pages/categories',
    about: 'pages/about',
    billing: 'pages/billing',
    docs: 'pages/partners/docs',
    chat: 'pages/chat?slug=/chat'
  },
  charts: {
    ticker: `/charts/ticker?`,
    basket: `/charts/basket?`,
    rankings: `/charts/rankings?`,
    fantasy: `/charts/fantasy?`
  },
  actions: {
    accept_terms: '/user/termsAccepted',
    watch: '/basket/watch',
    like: '/comment/like',
    report: '/report',
    make_claim: '/basket/claim',
    post_comment: '/comment',
    follow: '/user/follow',
    add_holdings: '/basket/holdings',
    edit_profile: '/user',
    create_basket: '/basket',
    create_ai_basket: '/basket/ai',
    copy_basket: '/basket/copy',
    update_basket: '/basket',
    publish_basket: '/basket/publish',
    unpublish_basket: '/basket/unpublish',
    delete_basket: '/basket',
    create_fantasy: '/fantasy',
    delete_fantasy: '/fantasy',
    join_fantasy: '/fantasy/join',
    payment_link: '/vendor/stripe/payment',
    leave_fantasy: '/fantasy/leave',
    update_notifications: 'user/notifications',
    cancel_subscription: '/billing/cancelSubscription'
  }
}