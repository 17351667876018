import { Container, Typography, InvoiceList, SubscriptionList, Subscription, ButtonVariant, LineIcon, GlobalUISizesEnum } from "@potato/components"
import { useEffect, useState } from "react"
import { BillingType } from "../../_api/pages/billing"
import { useConfirmCancelSubscription } from "./use-confirm-cancel-subscription"

export interface BillingLayoutProps {
    billing: BillingType
};

const BillingLayout = ({
    billing
}: BillingLayoutProps) => {
    const [item, setItem] = useState<{description:string, id:string}>({ description: "", id:""})
    const { dialog: leave_dialog, toggleDialog } = useConfirmCancelSubscription({ item })
    useEffect(() => {
        if (item.id) {
            toggleDialog()
        }
    }, [item])

    const has_subscriptions = !!billing.subscriptions && !!billing.subscriptions.length
    const has_invoices = !!billing.invoices && !!billing.invoices.length
    return <Container className="pt-8">
        {leave_dialog}
        <div className="md:flex md:text-left text-center">
            <div className="max-w-2xl">
                <Typography variant="h1" className="mb-4">Billing</Typography>
                {(has_subscriptions || has_invoices) && <div className="flex items-center md:items-start flex-col">
                    <Typography className="mb-4 md:mb-0 mt-4 text-lg">Manage your funding source via the stripe <a target="_blank" href={billing.billing_portal_link} className="underline">billing portal.</a></Typography>
                    <ButtonVariant.Outline size={GlobalUISizesEnum.MEDIUM} className="mt-4" iconLeft={<LineIcon icon="launch" />} externalLink={true} href={billing.billing_portal_link} label="Billing Portal" />
                </div>}
            </div>
        </div>
        <div className="mb-16">
            <Typography variant="h3" className="mt-16 mb-4">Subscriptions</Typography>
            {has_subscriptions ? <SubscriptionList onCancel={(item: Subscription) => { setItem({ description: item.description, id:item.id }) }} items={billing.subscriptions} />
                :
                <><Typography variant="p" className="text-secondary inline">No subscriptions yet. Upgrade to Potato Plus </Typography><Typography variant="p" className="inline text-primary underline" href="pricing">here</Typography></>
            }
        </div>
       <div className="mb-16">
            <Typography variant="h3" className="mt-16 mb-4">Invoices</Typography>
            {has_invoices ? <InvoiceList items={billing.invoices} />
        :    <Typography variant="p" className="text-secondary">This account has no payments yet. </Typography>
        }
        </div>
    </Container>
}

export default BillingLayout