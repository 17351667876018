import { CompositeDecorator } from "draft-js";
import { PreTickerHandle, preTickerHandleStrategy } from "./ticker-handle/PreTickerHandle";
// import { TickerHandle, tickerHandleStrategy } from "./ticker-handle/TickerHandle";
import { RichTextUrl, urlStrategy } from "./url/url";



export const compositeDecorator = new CompositeDecorator([
  {
    strategy: urlStrategy,
    component: RichTextUrl,
  },
  // {
  //   strategy: tickerHandleStrategy,
  //   component: TickerHandle,
  // },
  {
    strategy: preTickerHandleStrategy,
    component: PreTickerHandle,
  },
]);