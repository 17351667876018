import { Typography, Loading, Container, ButtonVariant, GlobalUISizesEnum, CustomIcon } from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import { FantasiesPageResponse, FantasiesSectionType } from "../../_api/pages/fantasies";
import React from "react"
import {
  useQuery,
} from 'react-query'
import { fantasies } from "../../_mocks/fantasies";
import PageMeta from "../../components/page-meta";
import clsx from "clsx";
import Section from "./sections/Section";
import { api_routes } from "../../_api/api-routes";

const Fantasies: React.FC = () => {
  const { apiLoading, ...api } = useApi()

  const getFantasies = api.get({ url: api_routes.pages.fantasy_index })
  const _fantasyResponse = useQuery('fantasy_index', getFantasies, {
    enabled: !apiLoading
  })
  const _data = _fantasyResponse.data as FantasiesPageResponse
  if (_fantasyResponse.isLoading) {
    return <Loading className="mt-32" />
  }


  const data = _data;
  (window as any).prerenderReady = true;
  return (
    <>
      <PageMeta data={data && data.meta_tags} />
      <Container className="z-10 mb-16 pt-8 relative">
        <div className="md:flex md:text-left text-center">
          <div className="max-w-2xl">
            <Typography variant="h1">Fantasy</Typography>
            <Typography className="mt-4 text-lg">Create your own fantasy trading league with your friends or enter a public league.</Typography>
          </div>
          <div className="flex-1 md:text-right md:mt-0 mt-8">
            <ButtonVariant.Cta size={GlobalUISizesEnum.LARGE} iconLeft={<CustomIcon icon="plus" />} href={'/fantasy/create'} label="Create League" />
          </div>
        </div>
      </Container>
      <div className={clsx(!_fantasyResponse || _fantasyResponse.isLoading ? 'opacity-0' : 'opacity-100', "transition-opacity relative z-10")}>
        {data && data.sections && data.sections.map((section => (
          <Section key={section.section_type} item={section} />
        )))}
      </div>
    </>
  )
}

export default Fantasies