import {
  CommentBox,
  CommentsPage,
  CommentType,
  CommentsFeed,
  useCurrentBreakpointName,
  CopyToClipboard,
  FantasyDetailItemType,
  BasketItemDetail,
  BullishBearish,
} from "@potato/components";
import React, { useCallback, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { api_routes } from "../../_api/api-routes";
import useApi from "../../_contexts/api/useApi";
import clsx from "clsx";
import "./styles.scss";
import { useLike } from "./use-like";
import { useComments } from "./use-comment";
import { is_member } from "../../views/fantasy/PaymentGuard";
import useShakeContext from "../../_contexts/shaker/shake-context-hook";

export interface CommentsProps {
  item: { id: string } | FantasyDetailItemType | BasketItemDetail;
  type: "fantasy" | "basket" | "chat";
  empty_comment?: Partial<CommentType>;
  readonly?: boolean;
  show_bot_typing?: boolean;
}

const Comments = ({
  item,
  type,
  readonly,
  show_bot_typing = true,
  empty_comment,
}: CommentsProps) => {
  const [items, setItems] = useState<CommentType[]>([]);
  const [bot_typing, setBotTyping] = useState(false);
  const api = useApi();
  const { onSubmit, reset, onDelete, onReport } = useComments({
    id: item.id,
    type: type,
  });
  const { onLikeClick } = useLike();
  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);
  const { shakeit } = useShakeContext();

  const getComments = ({ pageParam = 0 }) =>
    api.get({
      url: `${api_routes.comments}?reference_id=${item.id}&reference_type=${type}&cursor=${pageParam}&limit=40`,
    })();

  const {
    isLoading,
    isFetchingNextPage,
    data,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<CommentsPage>(
    `${item.id}-comments-dialog`,
    getComments,
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage && lastPage.next_cursor
          ? lastPage.next_cursor
          : undefined;
      },
      refetchInterval: 2000,
      select: (data) => ({
        pages: [...data.pages].reverse(),
        pageParams: [...data.pageParams].reverse(),
      }),
    }
  );

  useEffect(() => {
    if (data && data.pages) {
      let _items: CommentType[] =
        data.pages.reduce((items: CommentType[], page) => {
          if (page && page.comments) {
            items = items.concat(page.comments);
          }
          return items;
        }, []) || [];
      setItems(_items);
    } else {
      setItems([]);
    }
  }, [data]);

  useEffect(() => {
    if (items.length) {
      setBotTyping(!items[items.length - 1].is_bot);
    }
  }, [items]);

  let timeout: NodeJS.Timeout;
  useEffect(() => {
    if (bot_typing) {
      timeout = setTimeout(() => {
        setBotTyping(false);
      }, 14000);
    } else if (timeout) {
      clearTimeout(timeout);
    }
  }, [bot_typing]);

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [])

  if (isLoading || !data) {
    return <div className="text-center relative"></div>;
  }

  const empty_state_comment = {
    user_picture:
      "https://s3.amazonaws.com/static.potato.trade/component-assets/color-logo-potato.png",
    user_username: "potato",
    official: 0,
    id: "1",
    reply_count: 0,
    is_author: 0,
    is_bearish: 0,
    is_bullish: 0,
    like_count: 0,
    like_enabled: false,
    created_at: item.hasOwnProperty("published_at")
      ? (item as any).published_at
      : "2023-04-20T07:01:00Z",
    comment: (
      <>
        This is the very begining of this chat.
        <br /> Invite others to join the conversation
      </>
    ),
  };
  const hasSentiment =
    isBasket(item) && (item.bullish_count || item.bearish_count);
  return (
    <div
      className={clsx(
        isLarge ? "view-height-large" : "view-height-small",
        "flex-0 flex flex-col"
      )}
      style={{
        top: isLarge ? 0 : undefined,
        height: isLarge ? `calc(100vh - 160px)` : `calc(100vh - 54px)`,
        position: "sticky",
      }}
    >
      <div className="flex-1 flex flex-col bg-lifted light:bg-lifted-highlight rounded-lg w-full overflow-hidden">
        {isBasket(item) && (
          <BullishBearish
            className={clsx(
              hasSentiment ? "mt-1 mb-4" : "mt-0 mb-0",
              " px-4 transition-opacity flex-0"
            )}
            is_bearish={!!item.is_bearish}
            is_bullish={!!item.is_bullish}
            bearish_count={item.bearish_count || 0}
            bullish_count={item.bullish_count || 0}
            bullish_users={item.bullish_users || []}
            bearish_users={item.bearish_users || []}
          />
        )}
        <CommentsFeed
          readonly={readonly}
          boxed={true}
          empty_state_comment={
            empty_comment
              ? { ...empty_state_comment, ...empty_comment }
              : empty_state_comment
          }
          measureCounter={reset}
          onLike={onLikeClick}
          items={items}
          onDelete={onDelete}
          onReport={onReport}
          isLoading={isFetchingNextPage}
          bot_typing={show_bot_typing ? bot_typing : false}
          hasNextPage={!!hasNextPage}
          atTop={fetchNextPage}
        />
      </div>
      <div className="flex-0">
        <div
          onClick={
            !isBasket(item) &&
            item.hasOwnProperty("is_member") &&
            !is_member(item as FantasyDetailItemType)
              ? shakeit
              : undefined
          }
        >
          <CommentBox
            disabled={readonly}
            placeholder={"Send a message"}
            reset={reset}
            onSubmit={onSubmit}
            sentiment_enabled={
              !!data && data.pages[0] && data.pages[0].sentiment_enabled
            }
            className="md:mt-1 mt-1"
          />
        </div>
      </div>
    </div>
  );
};

const isBasket = (
  x: FantasyDetailItemType | BasketItemDetail | { id: string }
): x is BasketItemDetail => {
  return !x.hasOwnProperty("duration");
};

export default Comments;
