import React from 'react'
import { HoldingType, Typography } from "@potato/components"

type Props = {
  selectable?: boolean,
  height?: number,
  hideProperties: Array<keyof HoldingType>
}

export const HeaderRow = ({ selectable, hideProperties, height = 46 }: Props) => {
  return (
    <div>
      <div className="flex items-center md:mt-2 border-b border-divider text-secondary w-full" style={{ height }}>
        <div className=" flex-1">
          <Typography>Ticker</Typography>
        </div>
        {!hideProperties.includes('exchange_description') && (
          <div className="text-left flex-initial px-1 md:block hidden">
            <div className="line-clamp-1" style={{ minWidth: 180 }}>
              <Typography>Exchange</Typography>
            </div>

          </div>)
        }
        {!hideProperties.includes('ticker_type') && (
          <div className="text-left flex-initial px-1 md:block hidden">
            <div className="line-clamp-1" style={{ minWidth: 90 }}>
              <Typography>Type</Typography>
            </div>
          </div>)
        }
        {!hideProperties.includes('sector') && (
          <div className="text-left flex-initial px-1 md:block hidden">
            <div className="line-clamp-1" style={{ minWidth: 180 }}>
              <Typography>Sector</Typography>
            </div>

          </div>)
        }
        {!hideProperties.includes('market_cap') && (
          <div className="text-left flex-initial px-1 md:block hidden">
            <div style={{ minWidth: 120 }}>
              <Typography>Market Cap</Typography>
            </div>
          </div>)
        }
        {!hideProperties.includes('price') && (
          <div className="text-right flex-initial px-1">
            <div style={{ minWidth: 67 }}>
              <Typography>Price</Typography>
            </div>
          </div>)}
        {!hideProperties.includes('movement_7d') && (
          <div className="text-right flex-initial px-1">
            <div style={{ minWidth: 67 }}>
              <Typography>7d</Typography>
            </div>
          </div>)}
        {!hideProperties.includes('movement_1y') && (
          <div className="text-right flex-initial pl-1 md:block hidden">
            <div style={{ minWidth: 67 }}>
              <Typography>1y</Typography>
            </div>
          </div>
        )}
        {selectable && (
          <div className="text-right flex-initial pl-1">
            <div style={{ minWidth: 87 }}>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}