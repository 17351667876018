import React, { useEffect, useRef, useState } from "react";
import {
  useConfetti,
} from "@potato/components";
import { useHistory, useLocation } from "react-router";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import { useQueryParams } from "../../_utils/use-search-params";
const { gtag } = require("ga-gtag");

export interface NewPotatoPlusMemberProps {
};
const NewPotatoPlusMember = ({
 
}: NewPotatoPlusMemberProps) => {
    const { user, refetchUser } = usePotatoUser()
    const { element, fire } = useConfetti()
    const history = useHistory()
    const location = useLocation()
    const [purchasedTriggered, setPurchasedTriggered] = useState<boolean>(false)
    let queryParams = useQueryParams()
    const potatoPlus = queryParams.get("potato_plus") || undefined;
    let timeout: any = useRef(null)
    useEffect(() => {
      if (potatoPlus) {
        refetchUser()
      }
      return () => { 
        if (timeout.current) clearTimeout(timeout.current); 
      }
    }, []);
  
    useEffect(() => {
      if (potatoPlus) {
        if (!timeout.current) {
          timeout.current = setTimeout(() => {
            let slug = location.pathname.split("?")[0];
            history.replace(slug);
          }, 2000);
        }
      }
    }, [potatoPlus]);
  
    useEffect(() => {
      if (user && user.is_potato_plus && potatoPlus && !purchasedTriggered) {
        setPurchasedTriggered(true)
        gtag("event", "potato_plus", { method: "Google", value:"9", currency:"USD" });
        fire(["#D170FF", "#FFF1D6"]);
      }
    }, [user, potatoPlus,purchasedTriggered]);
  return (
   <div className="element">{element}</div>
  );
};

export default NewPotatoPlusMember;