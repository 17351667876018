import React from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import usePotatoUser from "../_contexts/potato-user-store/potato-user-hook";

export const useCreateBasket = () => {
  const api = useApi()
  const history = useHistory()
  const { refetchUser } = usePotatoUser()
  const queryClient = useQueryClient()

  const createBasket = async () => {
    const result = await api.post({
      url: api_routes.actions.create_basket,
    })()
    if(result) {
      if(result && result.status === "success") {
        if ((window as any).ttq) {
          (window as any).ttq.track('SubmitForm', {
            content_id: result.id,
            content_type: 'product',
            value: 1,
            currency: 'USD'
          })
        }
      }
      history.replace(result.slug)
      refetchUser()
    }
  }

  return { createBasket };
}