import { ButtonVariant, Container, CustomIcon, GlobalUISizesEnum, LazyLoadBackground, Markdown, PageMain, Typography } from "@potato/components";
import clsx from "clsx";
import PageMeta from "../../components/page-meta";

export interface LandingProps {
};

const Landing = ({

}: LandingProps) => {

  return (
    <Container>
      <PageMeta data={{
        title: '2050 Investment Ideas Competition | Potato',
        description: 'Share your 2050 basket and win a pair of bullish and bearish socks!',
        canonical: 'https://potato.trade/landing/in-2050'
      }}/>
      <div className="max-w-md mx-auto">
        <div className="flex items-center justify-center space-x-3 text-secondary mb-1">
          <div>🏆</div>
          <div> <Typography variant="p">
            This weeks competition
          </Typography></div>
        </div>
        <Typography variant="h1" className="mb-8 text-center">
          It’s the year 2050...
        </Typography>
      </div>
      <div className=" max-w-2xl mx-auto mb-4">
        <div className={clsx(" mt-4lg:mt-8 bg-cover rounded-md relative overflow-hidden")} style={{ height: '53.3%', paddingTop: '53.3%' }}>
          <LazyLoadBackground
            src={src}
            className={clsx("bg-cover absolute  inset-x-0 inset-y-0 bg-no-repeat bg-center bg-pill ")}
            style={{ backgroundImage: `url('${src}')` }}>
            <img className={clsx("block opacity-0")} src={src} alt={src_title} loading="lazy" />
          </LazyLoadBackground>
        </div>
      </div>

      <Markdown className="max-w-md mx-auto mb-6">{md}</Markdown>
      <div className="max-w-md mx-auto ">
        <ButtonVariant.Cta label="Create Basket" size={GlobalUISizesEnum.XLARGE} className="inline-block mb-12" iconLeft={<CustomIcon icon="plus" />} href="/basket/create" />
      </div>
    </Container>
  );
};

export default Landing;

const md = `
...Humanity is at a crossroads, nearly half of the Amazon rainforest has been deforested,  Hi-tech, intelligent buildings are revolutionising the urban landscape. Major advances in air travel comfort. 

What is your take? What investments would you have wished you made back in 2022?

- Create your basket before **1 July**, 
- **Tag it with 2050** and be in to win a sweet pair of bullish and bearish socks.

![Bullish and Bearish socks](https://s3.amazonaws.com/static.potato.trade/email-template-assets/bullish-bearish-socks.png)

📣 🥇 Winners announced on **Friday**

`

// const src = 'https://s3.amazonaws.com/static.potato.trade/art/hover-cars-racing-cafbr4673jrsrdquehpg.jpg'
const src = 'https://s3.amazonaws.com/static.potato.trade/art/futuristic-cities-with-flying-cars-cag0tpm73jrohu7hfbl0.jpg'

const src_title = '2050 investment ideas'