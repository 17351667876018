import React from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useHistory } from "react-router-dom";

export const useCopyBasket = () => {
  const api = useApi()
  const history = useHistory()

  const copyBasket = async (id:string) => {
    const result = await api.post({
      url: api_routes.actions.copy_basket,
      data: { id }
    })()
    if(result) {
      history.replace(result.slug)
    }
  }

  return { copyBasket };
}