import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useQueryClient } from 'react-query';
import { useConfetti, useThemeContext } from '@potato/components';
export type Claim = {
  id: string
  is_bearish: number
  is_bullish: number
}
export const useClaim = () => {
  const api = useApi()
  const queryClient = useQueryClient()
  const { fire, element } = useConfetti()
  const  { colors: { confettiColors } } = useThemeContext()
  const claimFn = async (data: Claim) => {
    if(api.auth0ApiReady) {
      if (data.is_bearish) {
        fire(confettiColors.bearish)
      } else if (data.is_bullish) {
        fire(confettiColors.bullish)
      }
    }
    const result = await api.post({
      url: api_routes.actions.make_claim, data
    })()
    if (result) {
     
      queryClient.invalidateQueries(data.id)
    }
  }


  return { claimFn, element };
}