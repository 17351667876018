import React from "react";

import { useParams } from "react-router-dom";
import { GenerateBasketProvider } from "../../_contexts/basket-generator/GenerateBasketContext";
import GenerateBasket from "../../components/generate-basket/GenerateBasket";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import { useAuth0 } from "@auth0/auth0-react";
import useApi from "../../_contexts/api/useApi";
import { Loading } from "@potato/components";

type BasketProps = {};
const UpgradeBasket: React.FC<BasketProps> = () => {
    const { isLoading: userIsLoading } = usePotatoUser();
    const { isLoading } = useAuth0();
    const params = useParams<{ private_basket_id: string, prompt: string }>();
    const { apiLoading } = useApi();
    if (isLoading || apiLoading || userIsLoading) {
      return <Loading className="mt-32" />;
    }
    (window as any).prerenderReady = true;

  return params.private_basket_id ? (
    <GenerateBasketProvider prompt={params.prompt} private_basket_id={params.private_basket_id}>
      <GenerateBasket />
    </GenerateBasketProvider>
  ) : (
    <></>
  );
};

export default UpgradeBasket;
