import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import usePotatoUser from "../_contexts/potato-user-store/potato-user-hook";
import { useQueryParams } from './use-search-params';

export const useJoinFantasy = () => {
  const api = useApi()
  const history = useHistory()
  const { user: potatoUser } = usePotatoUser()
  const queryClient = useQueryClient()
  let queryParams = useQueryParams();

  const joinFantasy = async(data: { private_slug: string}) => {
    const result = await api.post({
      url: api_routes.actions.join_fantasy,
      data
    })()
 
    potatoUser && queryClient.invalidateQueries('me')
    if(result) { 
      queryClient.invalidateQueries(result.id) 
    }

    if(result && result.slug) {
      queryParams.set("newbie", "true");
      history.replace(`${result.slug}?${queryParams.toString()}`)
    }
  }
  return { joinFantasy }
}