import { BasketCardList, BasketItem, Typography, useTruncate } from "@potato/components"
import WatchBasketPriceUpdate from '../../../hoc/watch-basket-updates'
import { useWatch } from "../../../_utils/use-watch"

type Props = {
  baskets?: BasketItem[] | null
}
const FeaturedIn = ({baskets}: Props) => {

  const { watchFn } = useWatch()
  const [truncateBasketsButton, truncatedBasketsItems] = useTruncate(baskets || [], 12)
  return baskets && baskets.length ? (
    <div  className=" mb-12" >
      <div className="flex space-x-8 mt-10 mb-6">
        <div className="flex-1">

          <Typography variant="h3">
            Featured In
          </Typography>
        </div>
      </div>
      <BasketCardList itemClassName="sm:w-1/2 w-full" onWatch={watchFn} itemWrapper={WatchBasketPriceUpdate} items={truncatedBasketsItems as any[]} />
      {truncateBasketsButton}
    </div>
  ) : <></>
}

export default FeaturedIn