import React, {
  createContext,
} from 'react';
import type { FC, ReactNode } from 'react';
import { EditorState, Modifier, SelectionState } from 'draft-js';

import useEditorState from '../editor-state/editor-state-hook';
import useTickerHandlesStore from '../ticker-handles-store/ticker-handles-hook';
import { TickerType } from '@potato/components';
import useApi from '../../../../_contexts/api/useApi';
import { api_routes } from '../../../../_api/api-routes';
import { useQuery } from 'react-query';

export interface TickerAutocompleteContextValue {
  loading: boolean
  confirmTicker: (x: TickerType) => void
  tickers: TickerType[]
}

interface TickerAutocompleteProviderProps {
  children?: ReactNode
}
const TickerAutocompleteContext = createContext<TickerAutocompleteContextValue>({
  loading: false,
  confirmTicker: () => { },
  tickers: []
});

export const TickerAutocompleteProvider: FC<TickerAutocompleteProviderProps> = ({
  children,
}) => {
  const api = useApi()
  const { editorState, setEditorState, blockInfo, activeText } = useEditorState()
  const getTickers = (_activeText: string) => api.get({ url: `${api_routes.search.tickers}?query=${_activeText.replace('$', '')}`, waitForAuth: false })()
  const { data, isLoading } = useQuery([`$${activeText}`, activeText], () => getTickers(activeText!), {
    keepPreviousData: false,
    enabled: !!activeText
  })

  const { addTicker } = useTickerHandlesStore()

  const confirmTicker = (item: TickerType) => {
    if (!blockInfo) {
      return
    }
    let contentState = editorState.getCurrentContent();
    const textSelection = SelectionState.createEmpty(blockInfo.key).merge({
      anchorOffset: blockInfo.start,
      focusOffset: blockInfo.end
    })

    const tickerText = `$${item.ticker.toLowerCase()}`
    contentState = Modifier.replaceText(
      contentState,
      textSelection,
      tickerText,
    )

    const atEnd = contentState.getBlockForKey(blockInfo.key).getLength() == blockInfo.start + tickerText.length
    if (atEnd) {
      contentState = Modifier.replaceText(
        contentState,
        textSelection.merge({
          anchorOffset: blockInfo.start + tickerText.length,
          focusOffset: blockInfo.start + tickerText.length
        }),
        ' ',
      )
    }
    let newEditorState = EditorState.set(editorState, { currentContent: contentState });
    const newSelection = textSelection.merge({ anchorOffset: blockInfo.start + tickerText.length + 1, focusOffset: blockInfo.start + tickerText.length + 1 })
    newEditorState = EditorState.forceSelection(newEditorState, newSelection)
    setEditorState(newEditorState);
    addTicker(item)
  }

  return (
    <TickerAutocompleteContext.Provider
      value={{
        loading: isLoading,
        confirmTicker,
        tickers: data?.tickers || [],
      }}
    >
      {children}
    </TickerAutocompleteContext.Provider>
  );
};

export const TickerHandlerConsumer = TickerAutocompleteContext.Consumer;

export default TickerAutocompleteContext;
