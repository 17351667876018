
import React from "react";
import {
  Container,
  EmojiTitle,
  useCurrentBreakpointName,
  PageMain,
  PageMainLeft,
  Typography,
  PageMainRight,
  useToggle,
  ButtonRadioGroup,
  ButtonVariant,
  LineIcon,
  GlobalUISizesEnum,
  FantasyDetailItemType,
  TradesActivityTable,
  RankingsTable,
  StackOptionsEnum,
  FilterTypeEnum,
  DialogVariant,
  Breakpoint,
  Loading,
  Avatar,
  MovementChip,
} from "@potato/components";
import { useInfiniteQuery } from "react-query";
import { useComments } from "../../../components/comments/use-comment";
import { useLike } from "../../../components/comments/use-like";
import { api_routes } from "../../../_api/api-routes";
import useApi from "../../../_contexts/api/useApi";
import clsx from "clsx";
import Comments from "../../../components/comments";
import './styles.scss'
import {
    is_member,
    MemberGuard,
    NonMemberGuard,
    PublicOrMemberGuard,
  } from "../PaymentGuard";
import usePageMode from "../../../_contexts/page-mode/page-mode-hook";
import { EnterCtaButton } from "../CTA";
export interface CommentsProps {
  item: FantasyDetailItemType
  width?: number
  height?:number
};


export const CommentsDialog = ({
  item,
  width,
  height
}: CommentsProps) => {
    const { setPageMode } = usePageMode();
    return <div className={"px-4 py-2 h-full flex-col"} style={{height}}>
    <div className=" flex-0 flex items-center space-x-4 justify-end mb-2">
      <div className="flex-1">
        <EmojiTitle
          size={GlobalUISizesEnum.SMALL}
          title={item.title}
          emoji={item.emoji}
          className="text-secondary"
        />
      </div>
      <div className="flex-0">
        <NonMemberGuard item={item}>
          <EnterCtaButton
            item={item}
            size={GlobalUISizesEnum.SMALL}
          />
        </NonMemberGuard>
      </div>
      <div className="flex-0">
        <ButtonVariant.Icon
          onClick={() => {
            setPageMode("main");
          }}
          label={<LineIcon icon="cross" className="text-lg" />}
          className="leading-none"
        />
      </div>
    </div>

    <div className="flex-1 flex-col" >
      <Comments
        readonly={!is_member(item)}
        item={item}
        type="fantasy"
      />
    </div>
  </div>

        }