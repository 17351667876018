import React from "react";
import {
  Loading,
} from "@potato/components";
import { motion } from "framer-motion";
import clsx from "clsx";
import useApi from "../../_contexts/api/useApi";
import { api_routes } from "../../_api/api-routes";
import Section from "./sections/Section";
import { BasketsPageResponse } from "../../_api/pages/home";
import { useQuery } from "react-query";
import PageMeta from "../../components/page-meta";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import { useAuth0 } from "@auth0/auth0-react";
import GenerateBasket from "../../components/generate-basket/GenerateBasket";
import { GenerateBasketProvider } from "../../_contexts/basket-generator/GenerateBasketContext";
import useBasketGeneratorContext from "../../_contexts/basket-generator/basket-generator-context-hook";
import NewPotatoPlusMember from "./NewPotatoPlusMember";

export const BasketIndex: React.FC = () => {
  const { apiLoading, auth0ApiReady, ...api } = useApi();

  const { create_layout } = useBasketGeneratorContext();
  const getHome = api.get({ url: api_routes.pages.basketIndex });
  const _basketIndexResponse = useQuery("basketIndex", getHome, {
    enabled: !apiLoading,
    refetchInterval: 30000,
  });

  const basketIndex = _basketIndexResponse.data as BasketsPageResponse;

  if (basketIndex) {
    (window as any).prerenderReady = true;
  }
  return (
    <>
      <PageMeta
        data={basketIndex && basketIndex.meta_tags}
        defaultData={{
          title: "Potato",
          description: "Your home for investment ideas",
        }}
      />
      <NewPotatoPlusMember />
      <GenerateBasket />

      {!create_layout && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={!create_layout ? { opacity: 1 } : { opacity: 0 }}
        >
          <div
            className={clsx(
              _basketIndexResponse.isLoading ? "opacity-0" : "opacity-100",
              "transition-opacity relative z-10"
            )}
          >
            {basketIndex ? (
              basketIndex.sections.map((item) => {
                return <Section key={item.slug || item.title} item={item} />;
              })
            ) : (
              <Loading className="my-32" />
            )}
          </div>
        </motion.div>
      )}
    </>
  );
};

const BasketIndexWrapper: React.FC = () => {
  const { isLoading: userIsLoading } = usePotatoUser();
  const { isLoading } = useAuth0();
  const { apiLoading } = useApi();
  if (isLoading || apiLoading || userIsLoading) {
    return <Loading className="mt-32" />;
  }

  return (
    <GenerateBasketProvider>
      <BasketIndex />
    </GenerateBasketProvider>
  );
};

export default BasketIndexWrapper;
