import {
  ButtonVariant,
  Breakpoint,
  LineIcon,
  Countdown,
} from "@potato/components";
import clsx from "clsx";
import "../fantasy/pulse.scss";
import React from "react";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";

import { GainersLosersPageResponse } from "../../_api/pages/gainers-losers";

export const FixedBottomBar: React.FC<{
  //   ranking: RankingType;
  item: GainersLosersPageResponse;
  className?: string;
}> = ({ item, className }) => {
  const { setPageMode } = usePageMode();

  return (
    <Breakpoint medium down>
      <div className={clsx("h-16", className)} />
      <div className="fixed bottom-0  z-10 left-0 px-4 py-2 w-full bg-white top-shadow">
        <div className="flex items-center justify-between space-x-2">
          <Countdown
            endingLabel="Market closes"
            startingLabel="Market opens"
            previous_end_at={item.market_last_close}
            start_at={item.market_open}
            end_at={item.market_close}
          />
          <ButtonVariant.Outline
            iconLeft={
              !!item.comment_count ? (
                <span className="bg-black rounded-full px-2 py-1 -ml-2 text-white text-sm">
                  {item.comment_count}
                </span>
              ) : (
                <LineIcon icon="bubble" />
              )
            }
            label="Chat"
            onClick={() => {
              setPageMode("chat");
            }}
          />
        </div>
      </div>
    </Breakpoint>
  );
};
