import { useState } from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useQueryClient } from "react-query";
import { usePageStatus } from "@potato/components";
import { UpdateHoldingsArgs } from "../components/edit-holdings/EditHoldings";

export const useUpdateHoldings = () => {
  const {setSaving, setSaved } = usePageStatus()
  const api = useApi()
  const queryClient = useQueryClient()


  const updateHoldings = async({ id, holdings, allocation_method }: UpdateHoldingsArgs) => {
    setSaving()
     await api.post({
      url: api_routes.actions.add_holdings,
      data: {
        id,
        allocation_method,
        holdings: holdings.filter(item => item.allocation !== 0)
      }
    })()
    queryClient.invalidateQueries(id)
    setSaved()
  }

  return { updateHoldings };
}