import { PriceUpdate } from '../_contexts/price-update-store/types'
export enum WSEventEnum {
  PORTFOLIO_PRICE_UPDATE = 'basket-price-update',
  TICKER_PRICE_UPDATE = 'ticker-price-update',
  PONG = 'pong',
}

export enum WSActionEnum {
  CLIENT_PING = 'client:ping',
  CLIENT_DISCONNECT = 'client:disconnect',
  CLIENT_CONNECT = 'client:connect',
  SUBSCRIBE_TICKERS = 'subscribe:tickers',
  SUBSCRIBE_PORTFOLIOS = 'subscribe:baskets',
}

export type BasketPriceUpdateMessage = {
  event: WSEventEnum.PORTFOLIO_PRICE_UPDATE,
  body: PriceUpdate
}

export type TickerPriceUpdateMessage = {
  event: WSEventEnum.TICKER_PRICE_UPDATE,
  body: PriceUpdate
}
type PongMessage = {
  event: WSEventEnum.PONG,
  body: string
}


export type WSStreamResponseMessage = TickerPriceUpdateMessage | BasketPriceUpdateMessage | PongMessage

export type WSStreamResponse = WSStreamResponseMessage[]
// export type WSStreamResponse = {
//   messages: WSStreamResponseMessage[]
// }