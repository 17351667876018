import { useState } from "react";
import useApi from '../_contexts/api/useApi'
import { api_routes } from "../_api/api-routes";
import { useQueryClient } from "react-query";

export const useDeleteFantasy = () => {
  const [deleting, setDeleting] = useState<boolean>(false)
  const api = useApi()
  const queryClient = useQueryClient()

  const deleteFantasy = async (data: { id: string }) => {
    setDeleting(true)
    await queryClient.invalidateQueries(data.id, { refetchActive:false })
    await api.delete({
      url: api_routes.actions.delete_fantasy,
      data: { id: data.id }
    })()
    queryClient.invalidateQueries('me')
    queryClient.invalidateQueries('fantasy_index')
    setDeleting(false)
  }

  return { deleteFantasy, deleting: deleting };
}