import React from "react";
import {
  Loading,
  Container,
  Typography,
  useCurrentBreakpointName,
  PageMainLeft,
  PageMain,
  Prompt,
  PotatoPlus,
  GlobalUISizesEnum,
} from "@potato/components";
import { motion } from "framer-motion";
import clsx from "clsx";
import usePotatoUser from "../../_contexts/potato-user-store/potato-user-hook";
import useBasketGeneratorContext from "../../_contexts/basket-generator/basket-generator-context-hook";
import { Breakpoint } from "react-socks";

export const GenerateBasket: React.FC = () => {
  const {
    create_input,
    is_regenerating,
    dialog,
    create_layout,
    cancel_button,
    error_message_component,
    data_layout,
    completed_layout,
    ai_basket_layout,
    setPrompt,
  } = useBasketGeneratorContext();
  const { user } = usePotatoUser();


  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);

  return (
    <>
      <Container className="z-10 md:pt-16 relative">
      {dialog}
        <PageMain space="" className={clsx("justify-center",create_layout && !completed_layout && 'mb-32' )}>
          <PageMainLeft className="relative" size="lg:w-3/5">
          <Breakpoint medium down> <Typography className="text-purple text-md text-center bg-purple-transparent -mx-4 -mt-4 px-4 py-2">
                    Potato is powered by {` `}
                    <Typography
                      className="inline-block font-semibold underline"
                      href="https://telescope.co/"
                      target="_blank" externalLink={true} 
                    >
                      Telescope
                    </Typography><br/> our API service for brokers, funds and fintechs.
                  </Typography>
                  </Breakpoint>
              <div className={clsx("text-center mt-12 mb-8")}>
                {!create_layout && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={
                      !create_layout
                        ? { opacity: 1 }
                        : { opacity: 0, height: 0 }
                    }
                  >
                    <Typography element="h1" className="text-3xl md:text-5xl mt-2 font-bold text-center leading-[1.1em]">
                      Your investment ideas
                      <br />
                      built with AI
                    </Typography>
                    {!user && <Typography className="mt-4 text-lg text-secondary">
                      Create your own fantasy ETF, simply describe your theme or event
                    </Typography>}
                    {user && <div className="flex items-center justify-center space-x-2 pt-4 pb-1">
                     {user.is_potato_plus && <div className="rounded-full bg-yellow-light text-purple px-3 py-1.5 font-semibold flex items-center space-x-2"><PotatoPlus size={GlobalUISizesEnum.MEDIUM}/> <Typography>Potato Plus</Typography></div>}
                      <Typography className="text-purple">{user.ai_basket_count_remaining} credits available</Typography>
                      </div>}
                  </motion.div>
                )}

                <motion.div
                  transition={{ delay: !data_layout ? 1 : 0 }}
                  className={clsx(
                    data_layout ? "" : " mx-auto",
                    "md:flex items-center",
                    "md:pt-3 md:pb-1 max-w-lg"
                  )}
                >
                  <motion.div
                    initial="default"
                    animate={
                      create_layout && !completed_layout
                        ? "creating"
                        : "default"
                    }
                    variants={{
                      creating: isLarge
                        ? { marginRight: is_regenerating ?-72 : 0 }
                        : { marginBottom: -15 },
                      default: isLarge
                        ? { marginRight: -72 }
                        : { marginBottom: -56 },
                    }}
                    transition={{ delay: 1 }}
                    className="flex-1"
                  >
                    {create_input}
                  </motion.div>
                  <div className="flex-0">{!is_regenerating && cancel_button}</div>
                </motion.div>
                {error_message_component}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={
                    !create_layout ? { opacity: 1 } : { opacity: 0, height: 0 }
                  }
                >
                  <Typography className="text-secondary mt-3">
                    Try
                    <Prompt
                      onClick={() => {
                        setPrompt("The US will enter a long recession");
                      }}
                      className="ml-2 my-1"
                    >
                      "The US will enter a long recession"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("AI will replace actors");
                      }}
                      className="ml-2 my-1"
                    >
                      "AI will replace actors"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("Robotic Weapons");
                      }}
                      className="ml-2 my-1"
                    >
                      "Robotic Weapons"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("Ozempic will be a blockbuster drug");
                      }}
                      className="ml-2 my-1"
                    >
                      "Ozempic will be a blockbuster drug"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("Healthtech through your phone");
                      }}
                      className="ml-2 my-1"
                    >
                      "Healthtech through your phone"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("There will be a global food shortage");
                      }}
                      className="ml-2 my-1"
                    >
                      "There will be a global food shortage"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("Space tourism");
                      }}
                    >
                      "Space tourism"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("EVTOL will be the future of transport");
                      }}
                    >
                      "EVTOL will be the future of transport"
                    </Prompt>{" "}
                    <Prompt
                      onClick={() => {
                        setPrompt("We're at war with China");
                      }}
                    >
                      "We're at war with China"
                    </Prompt>
                  </Typography>
                
                </motion.div>
              </div>

            {create_layout && ai_basket_layout}
            {create_layout && !completed_layout && (
              <Loading className="ml-0 mt-6" />
            )}
          </PageMainLeft>
        </PageMain>
      </Container>
     
    </>
  );
};


export default GenerateBasket;
