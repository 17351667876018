import { HoldingType, TickerDetail } from "@potato/components"
import React, { ReactNode, useEffect } from "react"
import usePriceUpdateStore from "../../_contexts/price-update-store/usePriceUpdateStore"

const WatchHoldingPriceUpdate = ({
  item: propItem,
  children
}: { item: HoldingType | TickerDetail, children: ReactNode}) => {
  const { tickerPriceUpdates, addTickers } = usePriceUpdateStore()
  useEffect(() => {
    addTickers([propItem.ticker])
  }, [propItem.id])
  const priceData = tickerPriceUpdates[propItem.ticker]
  const item = {
    ...propItem,
    ...priceData,
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { item });
    }
    return child;
  });

  return <>{childrenWithProps}</>
}

export default WatchHoldingPriceUpdate