import React, { useState } from 'react'
import { HoldingsListEdit, LineIcon, usePageStatus } from '@potato/components'
import Scrollbars from "react-custom-scrollbars"
import AutoSizer from 'react-virtualized-auto-sizer'
import clsx from 'clsx'

export const EditHoldingAllocation = () => {
  const ref = React.createRef<HTMLDivElement>()
  const [top, setTop] = useState(0)
  const { scrollToTop } = usePageStatus()
  const [isBottom, setIsBottom] = useState(false)
  const _scrollToTop = () => {
    if (ref && ref.current) {
      (ref.current as any).view.scroll({
        top: 0,
      })
    }
    scrollToTop()
  }

  const handleScroll = ({ target }: any) => {    
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (ref) {
      setIsBottom(scrollTop + clientHeight === scrollHeight)
    }
    setTop(scrollTop);
  };

  return <div className="h-full w-full block">
    <AutoSizer>{({ height, width }) => {
      return <div style={{ height, width }} className="relative">
        <div className={clsx(isBottom && "hidden", "absolute w-full h-20 bg-gradient bg-gradient-to-t from-white bottom-0 z-10")} />
        <div 
        onClick={_scrollToTop} 
        className={clsx(
          isBottom && "hidden",
          top > 50 ? 'opacity-100' : 'opacity-0', 
          "absolute transition-opacity duration-200 rounded-full bg-black ring-2 ring-offset-1 ring-black text-white flex items-center justify-center shadow-md z-20"
          )} 
          style={{ bottom: 10, right: 10, height: 40, width: 40 }}>
          <LineIcon icon="chevron-up" className="text-xl" />
        </div>
        <Scrollbars className="scroll-smooth"  onScroll={handleScroll} ref={ref as any} autoHide={true} height={height} width={width}>
          <HoldingsListEdit {...{ width }} />
        </Scrollbars>
      </div>
    }}
    </AutoSizer>
  </div>
}