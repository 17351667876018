import React, {
  createContext, useState
} from 'react';
import type { FC, ReactNode } from 'react';
import { PriceUpdate } from './types';
import { TickerPriceUpdateMessage, BasketPriceUpdateMessage } from '../../_api/websocket'

function onlyUnique(value:any, index:number, self:Array<any>) {
  return self.indexOf(value) === index;
}

type PriceUpdatesMap = {[x:string]: Omit<PriceUpdate, 'id'> }
export interface PriceUpdateStoreContextValue {
  loaded: boolean

  basketIds: string[],
  addBasketIds: (ids:string[]) => void
  basketPriceUpdates: PriceUpdatesMap
  addBasketPriceUpdates: (priceUpdates: BasketPriceUpdateMessage[]) => void

  tickers: string[],
  addTickers: (ids:string[]) => void
  tickerPriceUpdates: PriceUpdatesMap
  addTickerPriceUpdates: (priceUpdates: TickerPriceUpdateMessage[]) => void

  clearPriceUpdateStore: () => void
}
interface PriceUpdateStoreProviderProps {
  children?: ReactNode
}
const PriceUpdateStoreContext = createContext<PriceUpdateStoreContextValue>({
  loaded: false,

  basketIds: [],
  addBasketIds: () => {},
  basketPriceUpdates: {},
  addBasketPriceUpdates: () => {},

  tickers: [],
  tickerPriceUpdates: {},
  addTickers: () => {},
  addTickerPriceUpdates: () => {},

  clearPriceUpdateStore: () => {}
});

export const PriceUpdateStoreProvider: FC<PriceUpdateStoreProviderProps> = ({
  children,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [basketIds, setBasketIds] = useState<string[]>([])
  const [tickers, setTickers] = useState<string[]>([])
  const [basketPriceUpdates, setBasketPriceUpdates] = useState<PriceUpdatesMap>({})
  const [tickerPriceUpdates, setTickerPriceUpdates] = useState<PriceUpdatesMap>({})
  const addBasketIds = (ids:string[]) => {
    setBasketIds((prev) => {
      return [...prev, ...ids].filter(onlyUnique)
    })
    setLoaded(true)
  }

  const addTickers = (tickers:string[]) => {
    setTickers((prev) => {
      return [...prev, ...tickers].filter(onlyUnique)
    })
    setLoaded(true)
  }

  const addTickerPriceUpdates = (_priceUpdates: TickerPriceUpdateMessage[]) => {
    setTickerPriceUpdates((prev) => {
      const updates = _priceUpdates.reduce((acc: PriceUpdatesMap, update: TickerPriceUpdateMessage) => {
        const { id, ...body } = update.body
        acc[id] = body
        return acc
      }, {})
      return {
        ...prev,
        ...updates
      }
    })
  }
  const addBasketPriceUpdates = (_priceUpdates: BasketPriceUpdateMessage[]) => {
    setBasketPriceUpdates((prev) => {
      const updates = _priceUpdates.reduce((acc: PriceUpdatesMap, update: BasketPriceUpdateMessage) => {
        const { id, ...body } = update.body 
        acc[id] = body
        return acc
      }, {})
      return {
        ...prev,
        ...updates
      }
    })
  }

  const clearPriceUpdateStore = () => {
    setBasketIds([])
    setTickers([])
    setTickerPriceUpdates({})
    setBasketPriceUpdates({})
  }

  (window as any)['basketIds'] = basketIds;
  (window as any)['tickers'] = tickers;
  (window as any)['addTickerPriceUpdates'] = addTickerPriceUpdates;
  (window as any)['addBasketPriceUpdates'] = addBasketPriceUpdates;
  (window as any)['tickerPriceUpdates'] = tickerPriceUpdates;
  return (
    <PriceUpdateStoreContext.Provider
      value={{
        loaded,
        basketIds,
        tickers,
        basketPriceUpdates,
        tickerPriceUpdates,
        addBasketIds,
        addTickers, 
        addBasketPriceUpdates,
        addTickerPriceUpdates,
        clearPriceUpdateStore
      }}
    >
      {children}
    </PriceUpdateStoreContext.Provider>
  );
};

export const PriceUpdateStoreConsumer = PriceUpdateStoreContext.Consumer;

export default PriceUpdateStoreContext;
