import React, { useEffect } from "react";
import { Typography } from "@potato/components";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query"
import { api_routes } from "../../_api/api-routes"
import useApi from "../../_contexts/api/useApi"

const Unsubscribe = () => {
  const params = useParams<{ list: 'newsletter' | 'notifications' , hash: string }>()
  const api = useApi()
  const updateNotificationSetting = async (data: { email_newsletter?:  1 | 0, email_notifications_daily_updates?: 1 | 0, hash: string }) => {
    return await api.patch({ url: api_routes.actions.update_notifications, data, waitForAuth: false })()
  }
  const { mutate } = useMutation(updateNotificationSetting)
  useEffect(() => {
    mutate({
      [params.list === 'notifications' ? 'email_notifications_daily_updates' : 'email_newsletter']: 0, 
      hash: params.hash
    })
  }, [])

  const list_lable = params.list === 'notifications' ? '"Email Notifications"' : '"Weekly Newsletter"'
  return (
    <div className="text-center mt-20">
      <div className="flex items-center space-x-4 mb-2">
        <div className="flex-1"/>
        <div className="flex-0 text-3xl">📭</div>
        <div className="flex-0  text-2xl font-bold">Unsubscribed</div>
        <div className="flex-1"/>
      </div>
      <Typography variant="p" className="text-lg">
        You have unsubscribed from {list_lable}
      </Typography>
    </div>
  );
};

export default Unsubscribe;