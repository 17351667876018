import {
  Container,
  EmojiTitle,
  PageMain,
  PageMainLeft,
  PageMainRight,
  BasketItemDetail,
  FormProvider,
  LineIcon,
  getPriceData,
  EditAllocationProvider,
  useThemeContext,
  LazyLoadBackground,
  GlobalUISizesEnum,
  AIGeneratedTag,
  HumanGeneratedTag,
  useCurrentBreakpointName,
  DialogVariant,
} from "@potato/components";
import React from "react";
import clsx from "clsx";
import Actions from "./actions";
import TagsSection from "./tags";
import News from "../../components/news";
import Segments from "../../components/segments";
import { KeyStats } from "@potato/components";
import { PriceMetrics } from "../../components/price-metrics/PriceMetrics";
import { Author } from "./author/Author";
import RichTextInput from "../../components/RichTextInput/FormContextRichTextInput";
import Meta from "./meta";
import { useClaim } from "../../_utils/use-claim";
import HoldingsTradesSection from "./holdings/HoldingsTradesSection";
import PageMode from "./page-mode/PageMode";
import Comments from "../../components/comments";
import { NoFooter } from "../../components/no-footer/NoFooter";
import usePageMode from "../../_contexts/page-mode/page-mode-hook";
import DropdownMenu from "./actions/dropdown-menu";
import ChartSection from "./ChartSection";
import BasketHistoryView from "./BasketHistory";
import { Disclaimer } from "./Disclaimer";
import { FixedBottomBar } from "./FixedBottomBar";
import { CommentsDialog } from "./CommentsDialog";
import AIUpgrade from "./AIUpgrade";

const BasketLayout: React.FC<{
  showEditHoldings: () => void;
  onFormChange?: (data: { [x: string]: any }) => Promise<void> | void;
  item: BasketItemDetail;
}> = ({ item, onFormChange, showEditHoldings }) => {
  const basket = {
    ...item,
    emoji: item.emoji.length ? item.emoji : "", // TODO - random emoji
  };
  const { element } = useClaim();
  const { theme } = useThemeContext();
  const name = useCurrentBreakpointName();
  const isLarge = ["large", "xlarge"].includes(name);
  const editable = item.is_mine && !item.is_ai_generated;
  const price_data = getPriceData(item);
  const emptyCommentText = (
    <>
      This is the very begining of this chat.
      <br /> Start the conversation, share your thoughts on these investment
      ideas.
    </>
  );

  return (
    <>
      <PageMode pageModes={["history"]}>
        <BasketHistoryView id={item.id} title={item.title} emoji={item.emoji} />
      </PageMode>
      {/* Only for mobile */}
      <PageMode pageModes={["chat"]}>
        <Container>
          <NoFooter>
            <DialogVariant.Boxed open={true} closeButton={false} spaced={false}>
              <CommentsDialog item={item} />
            </DialogVariant.Boxed>
          </NoFooter>
        </Container>
      </PageMode>
      <PageMode pageModes={["main"]}>
        {element}
        <Container>
          <PageMain space="" className="justify-center">
            <PageMainLeft size="lg:w-3/5">
              {!!item.is_ai_generated ? (
                !!item.is_ai_upgrade_available &&
                !!item.private_basket_id ? (
                  <AIUpgrade
                    id={item.private_basket_id}
                    prompt={item.ai_user_input!}
                    model={(item as any).current_ai_model}
                    className="mb-6"
                  />
                ) : (
                  <AIGeneratedTag
                    size={GlobalUISizesEnum.MEDIUM}
                    className="mb-6"
                  />
                )
              ) : (
                <HumanGeneratedTag
                  size={GlobalUISizesEnum.MEDIUM}
                  className="mb-6"
                />
              )}
              <FormProvider
                forceUpdate={basket.id}
                debounce={2000}
                onFormChange={onFormChange}
                initialData={basket}
                readonly={!editable}
              >
                <div className="flex items-center">
                  <div className="flex-1">
                    <EmojiTitle
                      element="h1"
                      variant="h1"
                      emoji={basket.emoji}
                      title={basket.title}
                      titleProps={{
                        editable: editable ? "title" : undefined,
                        placeholder: "Basket Title",
                        className: "md:leading-10 py-1",
                      }}
                      emojiProps={{ editable: editable ? "emoji" : undefined }}
                    />
                  </div>

                  <div className="flex-0">
                    <DropdownMenu item={item} className=" -mr-2" />
                  </div>
                </div>
                {!!item.holdings.length && (
                  <PriceMetrics
                    className="mt-4 pb-1"
                    {...price_data}
                    showPrice={false}
                  />
                )}
                {(isLarge || !item.is_published) && (
                  <Actions
                    className="mt-4  pb-2"
                    item={basket}
                    showEditHoldings={showEditHoldings}
                  />
                )}
                <Meta className="mt-4" item={basket} />
                {basket.picture && (
                  <div
                    className={clsx(
                      " mt-4 lg:mt-8 bg-cover rounded-md relative overflow-hidden "
                    )}
                    style={{ height: "53.3%", paddingTop: "53.3%" }}
                  >
                    {!!basket.is_queued_for_art && (
                      <div className="bg-white z-10 absolute right-3 top-3 rounded-md shadow-lg px-4 py-2">
                        <div className="flex items-center space-x-2">
                          <div className="flex-initial">
                            <LineIcon
                              icon="hourglass"
                              className="block text-lg"
                            />
                          </div>
                          <div className=""> Queued for Art</div>
                        </div>
                      </div>
                    )}
                    <LazyLoadBackground
                      src={
                        theme === "cyberpunk"
                          ? item.picture_cyberpunk || item.picture
                          : item.picture
                      }
                      className={clsx(
                        "bg-cover absolute  inset-x-0 inset-y-0 bg-no-repeat bg-center bg-pill "
                      )}
                      style={{ backgroundImage: `url('${item.picture}')` }}
                    >
                      <img
                        className={clsx("block opacity-0")}
                        src={item.picture}
                        alt={item.title}
                        loading="lazy"
                      />
                    </LazyLoadBackground>
                  </div>
                )}
                <TagsSection className="mt-4 lg:mt-4" item={basket} />
                {!!basket.is_ai_generated && <Disclaimer className="mt-4" />}
                <EditAllocationProvider items={basket.holdings}>
                  {!(!item.description && !item.is_mine) && (
                    <RichTextInput
                      className="mt-6"
                      editable="description"
                      placeholder={
                        editable
                          ? "Describe your investment idea. Type $TSLA to lookup stocks or crypto."
                          : undefined
                      }
                    />
                  )}
                </EditAllocationProvider>
              </FormProvider>

              {
                <HoldingsTradesSection
                  item={basket}
                  showEditHoldings={showEditHoldings}
                />
              }

              {!!item.holdings.length && (
                <>
                  <ChartSection item={item} />

                  <div className="md:flex  mt-16 md:mt-24 w-full md:space-x-4">
                    <div className="flex-1">
                      <Segments title="Sectors" items={basket.sectors} />
                    </div>
                    <div className="flex-1">
                      <Segments title="Countries" items={basket.countries} />
                    </div>
                  </div>
                </>
              )}
              {!!item.is_published && !item.is_ai_generated && !!item.user && (
                <Author
                  is_mine={!!item.is_mine}
                  className="my-12 md:my-24"
                  item={item.user}
                />
              )}
              <News showImages={true} news={item.news} title="Relevant News" />
              {!!item.is_published && <FixedBottomBar item={item} />}
            </PageMainLeft>
            {isLarge && !!item.is_published && (
              <PageMainRight size="lg:w-2/5" className="md:pl-8 2xl:pl-16">
                <div className="flex flex-col h-full">
                  <Comments
                    item={item}
                    type="basket"
                    empty_comment={{ comment: emptyCommentText }}
                  />
                </div>
              </PageMainRight>
            )}
          </PageMain>
        </Container>
      </PageMode>
    </>
  );
};

export default BasketLayout;
