import React, { useEffect } from "react";
import { Container, Markdown, Loading, PageMainLeft, Logo } from "@potato/components";
import useApi from "../../_contexts/api/useApi";
import {
    useQuery,
} from 'react-query'
import { api_routes } from "../../_api/api-routes";
import { MarkdownContentResponse } from "../../_api/markdown-content";
import PageMeta from "../../components/page-meta";

const TermsOfService = () => {
    const { get } = useApi()
    const getTermsOfService = get({ url: api_routes.stealth.pages.terms, waitForAuth: false })
    const _data = useQuery('terms', getTermsOfService)

    const data = _data.data as MarkdownContentResponse
    if (!data) {
        return <Loading className="mt-32" />
    }
    (window as any).prerenderReady = true;
    return (
        <Container className="mb-20" >
            <PageMeta data={data.meta_tags} defaultData={{title:"Terms of Service | Potato"}}/>
            <PageMainLeft className="mx-auto mt-8">
                <Markdown>
                    {data.body}
                </Markdown>
            </PageMainLeft>
        </Container>
    );
};

export const LaunchTermsOfService = () => {
    return <div className="py-12 md:py-32 min-h-screen">
        <Container >
        <div className="lg:w-2/3 mx-auto" ><Logo showName={true}/></div>
        </Container>
        <TermsOfService/>
    </div>
}

export default TermsOfService;