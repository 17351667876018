import { useCallback, useEffect, useRef, useState } from "react";

export const useSequence = (sequence: number[]) => {
  const timeouts: Array<NodeJS.Timeout | null> = sequence.map((s) => null);
  const [sequence_states, setSequenceStates] = useState(
    sequence.map((s) => false)
  );
  const [start, setStart] = useState(false);

  useEffect(() => {
    setSequenceStates(sequence.map((s, i) => i === 0));
  }, [start]);

  useEffect(() => {
    const active_index = sequence_states.findIndex((s) => !s);
    if (active_index > -1 && start) {
      timeouts[active_index] = setTimeout(() => {
        setSequenceStates(
          sequence_states.map((s, i) => {
            if (i === active_index) {
              return true;
            }
            return s;
          })
        );
      }, sequence[active_index]);
    }
  }, [sequence_states]);

  const reset = useCallback(() => {
    timeouts.forEach((t) => t && clearTimeout(t));
    setSequenceStates(sequence.map((s) => false));
  }, []);

  return {
    sequence_states,
    start: () => {
      setStart(true);
    },
    reset,
  };
};
