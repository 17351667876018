import React, { useEffect, useState } from "react";
import {
  Container, EmojiTitle,
  useCurrentBreakpointName,
  ButtonVariant,
  GlobalUISizesEnum,
  useForm,
  Typography,
  ButtonRadioGroup,
  RoundStartIndicator,
  BenchmarkType,
  Duration,
  Option,
  PageMain,
  PageMainLeft,
  StackOptionsEnum
} from "@potato/components";
import { useAnimation, motion } from "framer-motion";
import clsx from "clsx";
import { renderBenchmarkOption } from "./BenchmarkOption";
import './styles.scss'

export interface CreateLayoutProps {
  benchmarks: BenchmarkType[]
  durations: Duration[],
  market_caps: { title: string, value: string }[]
};


const CreateLayout = ({
  benchmarks,
  durations,
  market_caps
}: CreateLayoutProps) => {

  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const { data, onFieldChange } = useForm()
  const disabled = !data.title.length
  const activeDuration = durations.find(item => item.value === data.duration)
  const [show_market_cap, set_show_market_cap] = useState(false)
  const clearBenchmark = () => {
    onFieldChange('benchmark_id', undefined)
  }
  const resetMarketCap = () => {
    onFieldChange('market_cap', "Any")
  }
  useEffect(() => {
    set_show_market_cap(data.stack === StackOptionsEnum.STOCKS)
  }, [data])

  useEffect(() => {
    if (!show_market_cap) {
      resetMarketCap()
    }
  }, [show_market_cap])
  return (
    <>
      <Container className="lg:mt-8 mt-4">
        <PageMain space="" className="justify-center">
          <PageMainLeft size="lg:w-3/5">
            <EmojiTitle
              variant="h1" element="h1"
              emoji={data.emoji}
              title={data.title || ''}
              titleProps={{ editable: 'title', placeholder: 'League Title', className: "md:leading-10 py-1" }}
              emojiProps={{ editable: 'emoji', className:"" }}
            />
            <Typography variant="p" className={clsx('mt-6 mb-4 w-full px-2 py-0.5')} rows={2} editable="description" placeholder={'Description'} />

            <div className={
              clsx('',
                disabled && 'opacity-30'
              )
            }>
              <Typography variant="h3" className="mt-12 mb-1">Duration</Typography>
              <Typography className="text-placeholder">Select a duration for your competition</Typography>
              <div className="md:flex items-center md:space-x-4 mt-4">
                <ButtonRadioGroup options={durations.map((d => {
                  return { label: d.title, value: d.value }
                }))}
                  editable="duration"
                  disabled={disabled}
                />
                {/* <ButtonRadioGroup className="mt-3 md:mt-0" options={[
                { label: 'Repeat', value: 'true'},
                { label: 'Once Off', value:'false' },
              ]}
                editable="repeat"
                disabled={disabled}
              /> */}
                {/* <CheckboxInput disabled={disabled} className="mt-3 md:mt-0" editable="start_immediately" value="Start Immediately" /> */}
              </div>
              <RoundStartIndicator className="mt-4" duration={activeDuration} start_immediately={eval(data.start_immediately)} repeat={eval(data.repeat)} />

              <Typography variant="h3" className="mt-12 mb-1">Stock options</Typography>
              <Typography className="text-placeholder">Limit your competition to certain investments </Typography>
              <div className="md:flex items-center md:space-x-4 mt-4">
                <ButtonRadioGroup options={[
                  { label: 'Stocks', value: 'stocks' },
                  { label: 'Crypto', value: 'crypto' },
                  { label: 'ETFs', value: 'etfs' },
                  { label: 'Any', value: 'any' },
                ]}
                  disabled={disabled}
                  editable="stack"
                /></div>
              <motion.div
                variants={{ visible: { opacity: 1, height: 'auto' }, hidden: { opacity: 0, height: 0, marginBottom: 0 } }}
                className={clsx("overflow-hidden rounded-md")}
                initial="hidden"
                exit="hidden"
                animate={show_market_cap ? "visible" : "hidden"}
                transition={{ duration: 0.2, ease: "easeInOut" }}
                key={`market-cap-options`}
              >
                <Typography variant="h3" className="mt-12 mb-1">Market Cap</Typography>
                <Typography className="text-placeholder">Limit your competition to certain market caps </Typography>
                <div className="md:flex items-center md:space-x-4 mt-4">
                  <ButtonRadioGroup options={market_caps.map((d => {
                    return { label: d.title, value: d.value }
                  }))}
                    disabled={disabled}
                    editable="market_cap"
                  /></div>
              </motion.div>
              <Typography variant="h3" className="mt-12 mb-1">Benchmarks</Typography>
              <Typography className="text-placeholder">Battle against celebrity investors or an index</Typography>
              <div className="px-2 md:px-0">
                <ButtonRadioGroup
                  showFocus={false}
                  className="mb-4 mt-4 benchmark-group w-full -mx-2"
                  listClassName="md:grid md:grid-cols-2"
                  options={
                    benchmarks.map((benchmark) => {
                      return { ...benchmark, label: benchmark.title, value: benchmark.id };
                    })
                  }
                  renderOption={renderBenchmarkOption(clearBenchmark)}
                  disabled={disabled}
                  editable="benchmark_id"
                />
              </div>
            </div>

            <ButtonVariant.Cta className="md:mt-12 mt-4 mb-4" disabled={disabled} size={GlobalUISizesEnum.LARGE} label="Create Fantasy" submit={true} />

          </PageMainLeft>
        </PageMain>
      </Container>
    </>
  );
};

export default CreateLayout;