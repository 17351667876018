import {
  Breakpoint,
  Typography,
  UserDetailType,
  UserType,
} from "@potato/components"
import React, { useEffect, useState } from "react"
import { useQueryClient, useMutation } from "react-query"
import { api_routes } from "../../_api/api-routes"
import useApi from "../../_contexts/api/useApi"

export const Notifications = ({user}: {
user?: UserDetailType
}) => {

  const [email_newsletter, set_email_newsletter] = useState<boolean>(user ? !!user.email_newsletter : false)
  const [email_notifications_basket_published, set_email_notifications_basket_published] = useState<boolean>(user ? !!user.email_notifications_basket_published : false)
  const [email_notifications_daily_updates, set_email_notifications_daily_updates] = useState<boolean>(user ? !!user.email_notifications_daily_updates : false)
  const queryClient = useQueryClient()
  const api = useApi()
  const updateNotificationSetting = async (data: {
    email_newsletter?: number,
    email_notifications_basket_published?: number,
    email_notifications_daily_updates?: number,
  }) => {
    return await api.patch({ url: api_routes.actions.update_notifications, data })()
  }
  const { mutate, isSuccess } = useMutation(updateNotificationSetting)

  useEffect(() => {
    if (user && email_newsletter !== user.email_newsletter) {
      mutate({ email_newsletter: Number(!!email_newsletter) })
    }
  }, [email_newsletter]);
  useEffect(() => {
    if (user && email_notifications_basket_published !== user.email_notifications_basket_published) {
      mutate({ email_notifications_basket_published: Number(!!email_notifications_basket_published) })
    }
  }, [email_notifications_basket_published]);
  useEffect(() => {
    if (user && email_notifications_daily_updates !== user.email_notifications_daily_updates) {
      mutate({ email_notifications_daily_updates: Number(!!email_notifications_daily_updates) })
    }
  }, [email_notifications_daily_updates])

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries('me')
    }
  }, [isSuccess])


  const onChange: (action: React.Dispatch<React.SetStateAction<boolean>>) => React.ChangeEventHandler<HTMLInputElement> = (action) => (event) => {
    action(event.target.checked)
  }


  return (<>
    <Typography variant="h3" className="mb-8">Email Notifications</Typography>
    <div className="flex flex-col">

    
      <div className="flex space-x-4 items-center">
        <div>
          <input type="checkbox" className="" checked={email_newsletter} onChange={onChange(set_email_newsletter)} />
        </div>
        <div>
          <Typography variant="p" className="font-semibold">Weekly newsletter</Typography>
          <Typography className="text-secondary">Receive a weekly summary of the markets including your baskets and baskets you watch.</Typography>
        </div>
        <div className="flex-1" />
      </div>

    
      <div className="flex space-x-4 items-center mt-8">
        <div>
          <input type="checkbox" className="" checked={email_notifications_basket_published} onChange={onChange(set_email_notifications_basket_published)} />
        </div>
        <div>
          <Typography variant="p" className="font-semibold">Basket published</Typography>
          <Typography className="text-secondary">Receive an email when your basket is published with AI generated artwork.</Typography>
        </div>
        <div className="flex-1" />
      </div>
      <div className="flex space-x-4 items-center mt-8">
        <div>
          <input type="checkbox" className="" checked={email_notifications_daily_updates} onChange={onChange(set_email_notifications_daily_updates)} />
        </div>
        <div>
          <Typography variant="p" className="font-semibold">Daily updates</Typography>
          <Typography className="text-secondary">Receive email update on interactions, comments and digests for baskets you watch and leagues you have entered.</Typography>
        </div>
        <div className="flex-1" />
      </div>
    </div>
  </>
  )
}