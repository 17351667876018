import {
  Loading,
  BasketItemDetail,
  useToggle,
} from "@potato/components"
import useApi from '../../_contexts/api/useApi'
import { useEmojiFavicon } from '../../_utils/use-emoji-favicon'
import React, { useCallback, useEffect } from "react"
import {
  useQuery,
} from 'react-query'

import { useLocation, useHistory, useParams } from 'react-router-dom';
import { api_routes } from "../../_api/api-routes"
import { BasketPageResponse } from "../../_api/pages/basket";
import BasketLayout from './BasketLayout'
import WatchBasketUpdate from '../../hoc/watch-basket-updates'
import { useUpdateBasket } from "../../_utils/use-update-basket"
import { useCreateBasket } from "../../_utils/use-create-basket"
import { useCopyBasket } from "../../_utils/use-copy-basket"
import EditHoldings from '../../components/edit-holdings/EditHoldings'
import PageMeta from "../../components/page-meta";
import { useUpdateHoldings } from "../../_utils/use-update-holdings"
import { PageModeProvider } from "../../_contexts/page-mode/PageModeContext"

type BasketProps = {}
const BasketWrapper: React.FC<BasketProps> = () => {

  const { apiLoading, ...api } = useApi()
  const history = useHistory()
  const location = useLocation()
  const params = useParams<{ basket: string }>()
  const { selected: isOpen, onChange: toggleDialog, setSelected: setIsDialogOpen } = useToggle(false)
  const uriParam = encodeURI(location.pathname)
  const getBasket = api.get({ url: api_routes.pages.basket + uriParam, redirect404: true })
  const basketParam = params.basket ? params.basket.split('-') : ''
  const basketParamId = basketParam[basketParam.length - 1]
  const _basketResponse = useQuery(basketParamId, getBasket, {
    enabled: !apiLoading,
    refetchInterval: isOpen ? undefined : 100000
  })
  let basket = _basketResponse.data as BasketPageResponse
  useEmojiFavicon(basket && basket.emoji)
  if (!_basketResponse.isLoading && basket && basket.slug && (basket.slug !== location.pathname)) {
    history.replace(basket.slug)
  }
  const { createBasket } = useCreateBasket()
  const { copyBasket } = useCopyBasket()
  const { updateBasket } = useUpdateBasket()
  const { updateHoldings } = useUpdateHoldings()

  useEffect(() => {
    setIsDialogOpen(false)
  }, [basketParamId])
  
  useEffect(() => {
  }, [_basketResponse.isLoading])

  const initBasket = useCallback(async () => {
    if (uriParam === '/basket/create' || uriParam === '/basket/create') {
      await createBasket()
    }
    if (uriParam.includes('/basket/copy') && params.basket) {
      await copyBasket(params.basket)
    }
  }, [uriParam, params.basket])

  useEffect(() => {
    initBasket()
  }, [])

  const onFormChange = async (data: Partial<BasketItemDetail>) => {
    if (basket.is_mine) {
      await updateBasket({
        id: basket.id,
        title: data.title,
        description: data.description,
        emoji: data.emoji
      })
    }
  }

  const isLoading = _basketResponse.isLoading

  if (isLoading) {
    return <div className="relative z-10"><Loading className="mt-32" /></div>
  }
  if (!basket) {
    return <div />
  }

  (window as any).prerenderReady = true;

  return <>
    <PageMeta data={basket.meta_tags} defaultData={{ title: basket.title || "Create Basket | Potato" }} />
    <PageModeProvider initialMode="main"> 
    <WatchBasketUpdate item={basket}>
      {isOpen ? <EditHoldings id={basket.id} holdings={basket.holdings} emoji={basket.emoji} updateHoldings={updateHoldings} title={basket.title} onClose={toggleDialog} />
        : <BasketLayout item={basket} onFormChange={onFormChange} showEditHoldings={toggleDialog} />}
    </WatchBasketUpdate>
    </PageModeProvider>
  </>
}

export default BasketWrapper