import { Card, Typography, RecentReturnsType, RecentReturns as RecentReturnsTable, useCurrentBreakpointName } from "@potato/components"
import { Fragment } from "react"

type Props = {
  recent_returns?: RecentReturnsType | null
}
const RecentReturns = ({recent_returns}: Props) => {
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  return recent_returns ?  (
    <div className="mb-12">
    <Typography variant="h3" className="mt-12 lg:mt-0 mb-6">
      Recent Returns
    </Typography>

    <RecentReturnsTable data={recent_returns} />
  </div>
  ) : <></>
}

export default RecentReturns