import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { PageMetaData } from "../../_api/page-meta";
const { gtag } = require("ga-gtag");

export interface PageMetaProps {
  data?: Partial<PageMetaData>
  defaultData?: Partial<PageMetaData>
  status?: number
};

const PageMeta = ({
  data: propsData = {},
  defaultData = {},
}: PageMetaProps) => {
  const data = {
    ...defaultData,
    ...propsData
  }

  useEffect(() => {
    if ((window as any).ttq) {
      (window as any).ttq.track('ViewContent', {
        description: 'Page view',
        content_id: window.location.pathname,
        value: 1
      })
    }
    gtag('event', 'page_view', {
      page_title: data.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_to: 'UA-180939132-1'
    })
  }, [])

  return data ? (<Helmet title={data.title}>
    <meta name="twitter:card" content="summary_large_image"></meta>
    <meta name="twitter:site" content="@potato_com"></meta>
    {data && data.description && <meta name="description" content={data.description} />}
    {data && data.description && <meta name="og:description" content={data.description} />}
    {data && data.title && <meta property="og:title" content={data.title} />}
    {data && data.type && <meta property="og:type" content={data.type} />}
    <meta property="og:url" content={window.location.href} />
    {data && data.image && <meta property="og:image" content={data.image} />}
    {data && data.article_published_time && <meta property="article:published_time" content={new Date(data.article_published_time*1000).toISOString()} />}
    {data && data.article_modified_time && <meta property="article:modified_time" content={new Date(data.article_modified_time*1000).toISOString()} />}
    {data && data.article_section && <meta property="article:section" content={data.article_section} />}
    {data && data.article_author && <><meta name="author" content={data.article_author} /><meta property="article:author" content={data.article_author} /></>}
    {data && data.article_tags && !!data.article_tags.length && data.article_tags.map(tag => <meta property="article:tag" content={tag} />)}
    {data && data.profile_username && <meta property="profile:username" content={data.profile_username} />}

    {data && data.canonical && <link rel="canonical" href={data.canonical} />}
    {data && data.oembed && <link rel="alternate" type="application/json+oembed" href={data.oembed} title={data.title || ''} />}
  </Helmet>) : <></>;
}

export default PageMeta;

export const StatusTag = ({ status, value }: { status?: number, value?: string }) => {
  let content: React.ReactNode = null;
  if (!status) {
    content = <></>
  }
  if ([400, 404].includes(status!)) {
    content = <Helmet>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="prerender-status-code" content="404" />
    </Helmet>
  }

  if ([301, 308].includes(status!)) {
    content = <Helmet>
      <meta name="prerender-status-code" content="301" />
      <meta name="prerender-header" content={`Location: ${value}`} />
    </Helmet>
  }
  return React.isValidElement(content) ? content : <></>
}