import React from "react";
import { Countdown, LineIcon, Typography } from "@potato/components";
import clsx from "clsx";
import { DateTime } from "luxon";
import { format } from "path";

export interface RoundCountdownProps {
  end_at: string
  start_at: string
  className?: string
};

const RoundCountdown = ({
  end_at,
  start_at,
  className
}: RoundCountdownProps) => {
  const now = DateTime.now()
  const start = DateTime.fromISO(start_at)
  const end = DateTime.fromISO(end_at).plus({ seconds: 30 })
  const is_current = now > start && now <= end
  const is_future = now < start 
  return (
    <div className={clsx(className, "md:flex items-center md:space-x-2 space-y-1 md:space-y-0 text-left")}>
      {is_current || is_future
        ? <Countdown start_at={start_at} end_at={end_at} />
        : <div className="flex items-center space-x-2" style={{ height:32 }}>
          <div className="flex-0"><LineIcon icon="check" className="text-green inline-block mr-2" /></div>
          <div className="flex-1">
            <Typography className="inline-block text-secondary mr-2">Finished on </Typography>
            <Typography className="inline-block font-semibold">{DateTime.fromISO(end_at).toFormat('dd LLLL')}</Typography>
            </div>
        </div>
      }
    </div>
  );
};

export default RoundCountdown;
