import { LineIcon, UserAvatar, GlobalUISizesEnum, FormatEnum, format, LabelValueDisplay } from "@potato/components"
import React from "react"
import { MetaProps } from "./types"
import clsx from 'clsx'
import Stack from "../../../components/stack"


const Meta: React.FC<MetaProps> = ({ item, className }) => {

  if (!item.holding_count) {
    return <></>
  }
  return <div className={clsx("space-y-2", className)}>
    <LabelValueDisplay label="Founder" value={<UserAvatar bio={false} size={GlobalUISizesEnum.SMALL} item={item.user!!} />} icon="feather" />
    {!!item.is_ai_generated && (
      <LabelValueDisplay label="Prompt"
        value={<><span className="text-secondary mr-0.5">"</span>{item.ai_user_input}<span className="text-secondary ml-0.5">"</span></>}
        icon="bubble" />
    )
    }

    {!!item.is_published && !!item.created_at && (
      <LabelValueDisplay label="Inception" value={format(item.created_at, FormatEnum.Date)} icon="calendar-check" />
      )}

    {/* {item.is_mine && !item.is_published && (
      <LabelValueDisplay label="Visibility"
        value={<div className="flex space-x-1">
          <div className={clsx("bg-yellow-transparent text-yellow", "px-2 py-1 text-base rounded-full")}>
            <LineIcon icon={"eye-crossed"} className={clsx("inline-block mr-1")} />
            Private
          </div>
        </div>
        }
        icon="eye-crossed" />
    )
    } */}


    <LabelValueDisplay label="Stack" icon="layers" value={<div className="flex divide-x divide-divider">
      <Stack {...item} />
    </div>} />
   {item.is_ai_generated  && <LabelValueDisplay label="AI Model" value={item.ai_model_version} icon="joystick" />}

  </div>
}
export default Meta