import clsx from "clsx";
import React from "react";
import usePageMode from "../../../_contexts/page-mode/page-mode-hook";

export interface PageModeProps {
  pageModes: string[],
  children: React.ReactNode
};

const PageMode = ({
 children,
 pageModes,
 ...props
}: PageModeProps) => {
  const { pageMode } = usePageMode()
  const show = pageModes.includes(pageMode)

  return show ? (
   <div className={clsx(show ? 'opacity-100': 'opacity-0 pointer-events-none', 'transition-opacity duration-300')}  {...props}>{
    children
    }</div>
  ) : <></>;
};

export default PageMode;