import { GlobalUISizesEnum, HoldingListItem, Logo, MovementChip, PriceChip, useCurrentBreakpointName } from "@potato/components";
import { HoldingType, Loading } from "@potato/components";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api_routes } from "../../_api/api-routes";
import { BasketPageResponse } from "../../_api/pages/basket";
import useApi from "../../_contexts/api/useApi";

export interface BasketEmbedProps {

};

const BasketEmbed = () => {
  const { apiLoading, ...api } = useApi()
  const name = useCurrentBreakpointName()
  const isLarge = ['large', 'xlarge'].includes(name)
  const { basket } = useParams<{ basket: string }>()
  const uriParam = encodeURI(basket)
  const getBasket = api.get({ url: api_routes.pages.basket_embed + uriParam, redirect404: true })
  const { data, isLoading } = useQuery<BasketPageResponse & { holding_truncate_text:string}>(basket, getBasket, {
    enabled: !apiLoading,
    refetchInterval: 5000
  })
  if (isLoading) {
    return <Loading className="mt-32" />
  }
  if (!data) {
    return <div></div>
  }
  if(data.status !== "success") {
    return   <div className="border border-divider rounded-md bg-white p-2 relative text-center" style={{
      paddingBottom: "28px",
      maxHeight: `100vh`
    }}>
      <p className="text-lg text-primary py-4">🙈  This basket is no longer available.</p>

      <div className="rounded-b-md absolute bottom-0 inset-x-0 w-full py-1 border-t border-divider">
        <div className="text-sm flex items-center">
          <div className="flex-1"/>
          <div className="px-1 pl-2"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0351 3.97618L9.2323 1.77279C8.56461 2.24017 7.89692 2.77433 6.16091 2.50725C4.77211 2.29359 3.80172 1.34991 3.49014 0.904785L1.35352 2.70756C2.28829 3.77587 3.89075 5.17803 6.6283 5.37833C8.82286 5.53891 10.4787 4.53259 11.0351 3.97618Z" fill="#01CC7A" />
            <path d="M6.49316 9.25077L7.4947 6.64676C8.09563 6.91384 9.56456 7.25639 10.9667 6.64676C12.013 6.19183 12.8585 5.2001 12.9698 4.71045L15.5738 5.71199C15.3735 6.35742 14.4921 7.98215 12.5692 9.05046C10.2189 10.279 7.4947 9.78492 6.49316 9.25077Z" fill="#F43B3A" />
            <path d="M8.49793 11.1878C5.22623 12.0558 3.7573 10.0527 3.22315 8.71729L0.619141 9.85236C0.819449 10.3198 1.44708 11.4682 2.35514 12.3228C4.9725 14.8334 8.29762 14.3482 9.63301 13.7918L8.49793 11.1878Z" fill="#FEB80F" />
          </svg>

          </div>
          <div><a href={`https://potato.trade`} target="_blank" className="text-secondary ">Explore other baskets on <span className="font-semibold text-primary">potato</span></a></div>
       
          <div className="flex-1"/>
        </div>
      </div>
    </div>
  }
  return (
    <div className="border border-divider rounded-md bg-white p-2 relative" style={{
      paddingBottom: "28px",
      maxHeight: `100vh`
    }}>
      <div className="md:flex md:items-center md:space-x-3">
        <a href={`https://potato.trade${data.slug}`} target="_blank">
        <h1 className="flex items-center space-x-3 md:mb-0 mb-1"> 
          <span className="md:text-xl text-lg leading-8" style={{ width: "1.1em;" }}>{data.emoji}</span>
          <span className="md:text-xl md:leading-10 text-lg font-bold leading-8 line-clamp-1" style={{ width: "100%" }}>{data.title}</span>
        </h1>
        </a>
        <div className="flex-1"></div>
        <div className="flex-0">

          <div className="flex items-start space-x-2">
            <MovementChip className="mr-2" movement={data.movement_7d}  arrow={true} size={GlobalUISizesEnum.LARGE} timeperiod="7d"/>
            <MovementChip movement={data.movement_1y}  arrow={true} size={GlobalUISizesEnum.LARGE} timeperiod="1y"/>
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-x-4 border-divider border-t mt-2 -mx-2 pb-2 px-2 overflow-auto" style={{
             maxHeight: `calc(100vh - ${isLarge ? 104 : 123}px)`
      }} >
        {data.holdings.map((item: HoldingType) => {
          return (
           <HoldingListItem item={item} />
          )
        })}


        <div className="flex-initial text-right col-span-2">
          {data.holding_truncate_text && <a href={`https://potato.trade${data.slug}`} target="_blank" className="text-secondary text-base font-light">{data.holding_truncate_text}</a>}
        </div>

      </div>
      <div className="rounded-b-md absolute bottom-0 inset-x-0 w-full py-1 border-t border-divider">
        <div className="text-sm flex items-center">
          <div className="px-1 pl-2"> <Logo className="scale-50"/>

          </div>
          <div><a href={`https://potato.trade${data.slug}`} target="_blank" className="text-secondary">View live on <span className="font-semibold text-primary">potato</span></a></div>
        </div>
      </div>

    </div>
  );
};

export default BasketEmbed;