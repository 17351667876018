import { ButtonVariant, Container, FantasyWinners, LineIcon, Typography } from '@potato/components'
import { FantasiesSectionItem } from "../../../_api/pages/fantasies";

export interface NewsProps {
  item: FantasiesSectionItem
};

const LatestWinners = ({
  item
}: NewsProps) => {
  if (!item.fantasies) {
    return <></>
  }
  return (

    <Container className="lg:py-12 py-6">
      <div className="flex items-center">
        <div className="flex-auto">
          <Typography  variant="h2" element="h2" className="my-8 mb-12">{item.title}</Typography>
        </div>
        {item.slug && (
          <div className="flex-initial">
            <ButtonVariant.Pill iconRight={<LineIcon className="block" icon="chevron-right" />} label="See all" href={item.slug} />
          </div>
        )}
      </div>
        <FantasyWinners items={item.fantasies} />
    </Container >
  );
};

export default LatestWinners;