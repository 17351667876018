import useApi from '../../_contexts/api/useApi'
import { api_routes } from "../../_api/api-routes";

export const useLike = () => {
  const api = useApi()
  const likeFn = async (id: string, isLiked: boolean) => {
    await api.post({
      url: api_routes.actions.like, data: {
        id: id,
        is_liked: isLiked
      }
    })()
  }

  const onLikeToggleClick = (id: string, isLiked: boolean) => () => {
    likeFn(id, !isLiked)
  }
  const onLikeClick = async (id: string, isLiked: boolean) => {
    await likeFn(id, isLiked)
  }

  return { 
    onLikeToggleClick, onLikeClick };
}