import React from 'react'
import { MovementChip, FormatEnum, GlobalUISizesEnum, PriceChip } from '@potato/components'
import clsx from 'clsx'

type PriceMetricsProps = {
  className?: string
  movement_7d:number,
  movement_1y?:number,
  price: number,
  showPrice?:boolean
}

export const PriceMetrics = ({ className, price, movement_7d, movement_1y, showPrice }: PriceMetricsProps) => {
  return <div className={clsx("flex items-center space-x-4", className)}>
    <div className="flex-initial">
    {showPrice && <PriceChip  value={price} className="pb-2"  size={GlobalUISizesEnum.XLARGE} />}
    </div>
      {movement_7d !== undefined && (
        <div className="flex flex-initial">
          <MovementChip  movement={movement_7d} timeperiod="7d" arrow={true} format={FormatEnum.PriceMovement} size={GlobalUISizesEnum.XLARGE} />
        </div>
      )}
       {movement_1y !== undefined && (
        <div className="flex flex-initial">
          <MovementChip   movement={movement_1y} timeperiod="1y" arrow={true} format={FormatEnum.PriceMovement} size={GlobalUISizesEnum.XLARGE} />
        </div>
      )}

    </div>
}